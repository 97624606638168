import "./App.css";
import "./Responsive.css";
import React, { useState, useEffect, lazy, Suspense } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Route, Switch, Redirect } from "react-router-dom";
import "antd/dist/antd.css";
import { openNotificationTabSidenav } from "./StateManagement/Reducers/PatientState";
import TopNav from "./components/top_navbar/TopNavbar";
import moment from "moment";
import Loader from "./components/popups/Loading";
import useAutoLogout from "./useAutoLogout";
import "./components/login/Login.css";
import "./components/schedule/styles.css";
import "./components/schedule/Schedule.css";
import "./components/patient/Patient.css";
import "./components/billing/Billing.css";
import "./components/popups/Popups.css";
import "./components/report/report.css";
import "./components/setting/setting.css";
import ProductivityReport from "./components/report/ProductivityReport";
import Problems from "./components/patient/Problems";
import axiosInstance from "./axios/axios";
import { Decrypt_Value } from "./MiddleWare/EncryptDecrypt";
import PatientEducation from "./components/patient/PatientEducation";

const Login = lazy(() => import("./components/login/Login"));

const VerifyUser = lazy(() => import("./components/login/VerifyUser"));

const ForgotPassword = lazy(() => import("./components/login/ForgotPassword"));

const SetNewPassword = lazy(() => import("./components/login/SetNewPassword"));

const PatientList = lazy(() => import("./components/patient/PatientList"));
const DocumentTemplates = lazy(() =>
  import("./components/patient/DocumentTemplates")
);

const FormBuilder = lazy(() =>
  import("./components/patient/FormBuilder/FormBuilder")
);
const FormArchive = lazy(() => import("./components/patient/FormArchive"));
const SessionNotes = lazy(() => import("./components/patient/SessionNotes"));

const PatientDashboard = lazy(() =>
  import("./components/patient/PatientDashboard")
);

const Demographics = lazy(() => import("./components/patient/Demographics"));
const Appointment = lazy(() => import("./components/patient/Appointment"));
const PatientReport = lazy(() => import("./components/report/PatientReport"));
const CardTransactions = lazy(() =>
  import("./components/billing/CardTransactions")
);
const Appointments = lazy(() =>
  import("./components/report/AppointmentReport")
);
const User = lazy(() => import("./components/report/User"));
const ClientPortalAccess = lazy(() =>
  import("./components/patient/ClientAccess")
);
const ResetSuccessfull = lazy(() =>
  import("./components/login/ResetSuccessfull")
);

const CheckYourEmail = lazy(() => import("./components/login/CheckYourEmail"));

const Calender = lazy(() => import("./components/schedule/Calender"));
const SettingsMyProfile = lazy(() =>
  import("./components/setting/SettingsMyProfile")
);
const Users = lazy(() => import("./components/setting/Users"));
const SettingPracticeInfo = lazy(() =>
  import("./components/setting/SettingPracticeInfo")
);
const CalendarIntergration = lazy(() =>
  import("./components/setting/CalendarIntegration")
);
const ActivityTable = lazy(() =>
  import("./components/setting/PatientPortalSettings/ActivityTable")
);

const AppTemplate = lazy(() => import("./components/schedule/AppTemplate"));

const Documents = lazy(() => import("./components/patient/Documents"));

const EditNotes = lazy(() => import("./components/patient/EditNotes"));

const Previewnote = lazy(() => import("./components/patient/Previewnote"));

const PatientBillInsurance = lazy(() =>
  import("./components/patient/BillandInsurance")
);

const PatientPayment = lazy(() =>
  import("./components/billing/PatientPayment")
);

const SettingBilling = lazy(() =>
  import("./components/setting/SettingBilling")
);

const App = () => {
  const token = localStorage.getItem("token");
  const uid =
    localStorage.getItem("user_id") &&
    Decrypt_Value(localStorage.getItem("user_id"), "westside");
  const dispatch = useDispatch();
  const idletime = 3600;
  const idleinMin = parseInt(idletime) / 60;
  const timer = useAutoLogout(parseInt(idleinMin));
  const [sendToContinue, setSendToContinue] = useState("");
  const [iframesrc, setIframeSrc] = useState("");
  const [noteLock, setNoteLock] = useState("");
  const [notedate, setNoteDate] = useState("");
  const [noteid, setNoteid] = useState("");
  const [nottime, setNoteTime] = useState("");
  const [noteinfo, setNoteInfo] = useState("");
  const [reqDrop, setReqDrop] = useState(false);
  const [refreshList, setRefreshList] = useState(true);
  const [refreshnoteList, setRefreshnoteList] = useState(true);
  const openNotificationTab = useSelector(
    (state) => state.Revision.openNotificationTab
  );

  const handleDropReq = () => {
    setReqDrop(!reqDrop);
  };
  useEffect(() => {
    if (openNotificationTab === true) {
      handleDropReq();
      dispatch(openNotificationTabSidenav(false));
    }
  }, [openNotificationTab]);

  if (timer == 0) {
    axiosInstance
      .post("/westside/updatelogout?uid=" + uid)
      .then((response) => {
        localStorage.clear();
        localStorage.setItem("logout", true);
        window.location.href = "/";
      })
      .catch((err) => {
        console.log(err);
        localStorage.clear();
        localStorage.setItem("logout", true);
        window.location.href = "/";
      });
  } else {
    ////////////////////session check code starts here//////////////////////////////////////////////////
    const CurrTime = moment();

    const token_exp_time = moment(localStorage.getItem("login_time"));
    if (token_exp_time.isBefore(CurrTime)) {
      axiosInstance
        .post("/westside/updatelogout?uid=" + uid)
        .then((response) => {
          localStorage.clear();
          localStorage.setItem("logout", true);
          window.location.href = "/";
        })
        .catch((err) => {
          console.log(err);
          localStorage.clear();
          localStorage.setItem("logout", true);
          window.location.href = "/";
        });
    }

    const handleframesrc = (a) => {
      setIframeSrc(a);
    };

    const handleNotesLock = (x) => {
      setNoteLock(x);
    };

    const handleNotesData = (b, e, f, g) => {
      setNoteDate(b);
      setNoteid(e);
      setNoteTime(f);
      setNoteInfo(g);
    };

    const refreshPatient = () => {
      setRefreshList(!refreshList);
    };
    const refreshnote = () => {
      setRefreshnoteList(!refreshnoteList);
    };
    ////////////////////session check code ends here//////////////////////////////////////////////////

    if (!token) {
      return (
        <Suspense fallback={<Loader />}>
          <Switch>
            <Route exact path="/" render={() => <Login />} />
            <Route
              exact
              path="/login/ForgotPassword"
              render={() => <ForgotPassword />}
            />
            <Route
              exact
              path="/login/SetNewPassword/:code/:id"
              render={() => (
                <SetNewPassword sendDetails={(x) => setSendToContinue(x)} />
              )}
            />
            <Route
              exact
              path="/login/ResetSuccessfull"
              render={() => <ResetSuccessfull sendData={sendToContinue} />}
            />
            <Route
              exact
              path="/login/checkyouremail"
              render={() => <CheckYourEmail />}
            />

            <Route
              exact
              path="/login/verify_user"
              render={() => <VerifyUser />}
            />

            <Route render={() => <Redirect to="/" />} />
          </Switch>
        </Suspense>
      );
    } else
      return (
        <div className="App">
          <TopNav
            DropReq={handleDropReq}
            reqDrop={reqDrop}
            refreshPatient={refreshPatient}
            refreshnote={refreshnote}
          />
          <div className="app-body">
            <Suspense fallback={<Loader />}>
              <Switch>
                <Route exact path="/" render={() => <Calender />} />
                <Route
                  exact
                  path="/schedule/calender"
                  render={() => <Calender />}
                />
                <Route
                  exact
                  path="/schedule/nav/:nav"
                  render={() => <Calender />}
                />

                <Route
                  exact
                  path="/report/nav/:nav"
                  render={() => <PatientReport />}
                />

                <Route
                  exact
                  path="/settings/nav/:nav"
                  render={() => <SettingsMyProfile />}
                />
                <Route
                  exact
                  path="/settings/settingsmyprofile"
                  render={() => <SettingsMyProfile />}
                />
                <Route exact path="/settings/Users" render={() => <Users />} />
                <Route
                  exact
                  path="/settings/settingpracticeinfo"
                  render={() => <SettingPracticeInfo />}
                />
                <Route
                  exact
                  path="/settings/CalendarIntegration"
                  render={() => <CalendarIntergration />}
                />
                <Route
                  exact
                  path="/patient/activityTable"
                  render={() => <ActivityTable />}
                />
                <Route
                  exact
                  path="/schedule/apptemplate"
                  render={() => <AppTemplate />}
                />
                <Route
                  exact
                  path="/patient/patientlist"
                  render={() => <PatientList refreshList={refreshList} />}
                />
                <Route
                  exact
                  path="/patient/nav/:nav"
                  render={() => <PatientList refreshList={refreshList} />}
                />
                <Route
                  exact
                  path="/patient/DocumentTemplates"
                  render={() => <DocumentTemplates />}
                />
                <Route
                  exact
                  path="/patient/patientdashboard/:pid"
                  render={() => <PatientDashboard />}
                />
                <Route
                  exact
                  path="/patient/demographics/:pid"
                  render={() => <Demographics />}
                />
                <Route
                  exact
                  path="/patient/appointment/:pid"
                  render={() => <Appointment />}
                />
                <Route
                  exact
                  path="/patient/clientportalaccess/:pid"
                  render={() => <ClientPortalAccess />}
                />
                <Route
                  exact
                  path="/patient/documents/:pid"
                  render={() => <Documents />}
                />
                <Route
                  exact
                  path="/patient/problems/:pid"
                  render={() => <Problems />}
                />

                <Route
                  exact
                  path="/clinicalnotes"
                  render={() => (
                    <EditNotes
                      setSrc={(a) => {
                        handleframesrc(a);
                      }}
                      notelock={noteLock}
                      handledata={(b, e, f, g) => {
                        handleNotesData(b, e, f, g);
                      }}
                      source={iframesrc}
                      lock={noteLock}
                      setLock={(x) => {
                        handleNotesLock(x);
                      }}
                      rows={noteinfo}
                      encounter={noteid}
                      st={nottime}
                      date={notedate}
                      refreshnote={refreshnoteList}
                    />
                  )}
                />

                <Route
                  exact
                  path="/clinicalnotes/previewnote"
                  render={() => (
                    <Previewnote
                      source={iframesrc}
                      lock={noteLock}
                      setLock={(x) => {
                        handleNotesLock(x);
                      }}
                      setSrc={(x) => {
                        handleframesrc(x);
                      }}
                      rows={noteinfo}
                      encounter={noteid}
                      st={nottime}
                      date={notedate}
                    />
                  )}
                />
                <Route
                  exact
                  path="/patient/formarchive"
                  render={() => <FormArchive />}
                />

                <Route
                  exact
                  path="/patient/formbuilder/formbuilder"
                  render={() => <FormBuilder />}
                />

                <Route
                  exact
                  path="/patient/session/notes/:pid"
                  render={() => <SessionNotes />}
                />

                <Route
                  exact
                  path="/patient/patienteducation"
                  render={() => <PatientEducation />}
                />
                <Route
                  exact
                  path="/report/ProductivityReport"
                  render={() => <ProductivityReport />}
                />
                <Route
                  exact
                  path="/report/PatientReport"
                  render={() => <PatientReport />}
                />
                <Route
                  exact
                  path="/report/appointments"
                  render={() => <Appointments />}
                />
                <Route
                  exact
                  path="/report/card_transactions"
                  render={() => <CardTransactions />}
                />
                <Route
                  exact
                  path="/patient/billandInsurance/:pid"
                  render={() => <PatientBillInsurance />}
                />
                <Route
                  exact
                  path="/patient/PatientPayment"
                  render={() => <PatientPayment />}
                />
                <Route
                  exact
                  path="/setting/BillingSettings"
                  render={() => <SettingBilling />}
                />
                <Route exact path="/report/User" render={() => <User />} />
                <Route render={() => <Redirect to="/" />} />
              </Switch>
            </Suspense>
          </div>
        </div>
      );
  }
};

export default App;

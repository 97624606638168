import { all } from "redux-saga/effects";
import { GetUserOtpSaga, SendUserOtpSaga } from "./Prelogin/PreloginWatchers";

import {
  PreviewDownloadSaga,
  SecureMessageListSaga,
  SecureMessageSearchSaga,
  UnreadCountUpdateSaga,
} from "./Message/MessageSagaWatcher";
import { patientImportCsv } from "./Patient/PatientSagaWatchers";

export default function* rootSaga() {
  return yield all([
    GetUserOtpSaga(),
    SendUserOtpSaga(),
    PreviewDownloadSaga(),
    SecureMessageListSaga(),
    SecureMessageSearchSaga(),
    UnreadCountUpdateSaga(),
    //patient
    patientImportCsv(),
    //patient
  ]);
}

import React, { useState, useEffect, memo } from "react";
import { Spinner, Modal, Form, Col } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import axiosInstance from "../../axios/axios";
import { SpinnerDiv } from "../../StyledComponents";
import { AiOutlineSearch } from "react-icons/ai";
import { AutoComplete, Checkbox, DatePicker, Select } from "antd";
import moment from "moment";
import CustomSuffixIcon from "./CustomSuffixIcon";

var CryptoJS = require("crypto-js");

const Encode_Decode_JSON = {
  stringify: function (DecodingVal) {
    var Data_Val = {
      Encode: DecodingVal.ciphertext.toString(CryptoJS.enc.Base64),
    };
    if (DecodingVal.iv) Data_Val.Decentral = DecodingVal.iv.toString();
    if (DecodingVal.salt) Data_Val.EndCode = DecodingVal.salt.toString();
    return JSON.stringify(Data_Val);
  },
  parse: function (StrVal) {
    var Data_Val = JSON.parse(StrVal);
    var DecodingVal = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(Data_Val.Encode),
    });
    if (Data_Val.Decentral)
      DecodingVal.Decentral = CryptoJS.enc.Hex.parse(Data_Val.Decentral);
    if (Data_Val.EndCode)
      DecodingVal.salt = CryptoJS.enc.Hex.parse(Data_Val.EndCode);

    return DecodingVal;
  },
};

const Decrypt_Value = (data, key) => {
  return JSON.parse(
    CryptoJS.AES.decrypt(data, key, {
      format: Encode_Decode_JSON,
    }).toString(CryptoJS.enc.Utf8)
  );
};

const Encrypt_Value = (Val, key) => {
  return CryptoJS.AES.encrypt(JSON.stringify(Val), key, {
    format: Encode_Decode_JSON,
  }).toString();
};

const AddProblem = ({ clientShow, dataFetch, id, row, close, data }) => {
  //------------------------------------------State and Variables Start------------------------------------------//
  const isLoggedIn = id;
  var params = useParams();
  const { Option } = Select;

  if (id === 0) {
    row.title = "";
    var fields = ["", ""];
    row.activity = "";
    row.begdate = "";
    row.enddate = "";
    row.snoomed_code = "";
    row.comments = "";
  } else {
    fields = row.diagnosis.split(":");
  }

  const [SpinnerVal, setSpinnerVal] = useState(true);

  const [icd_version, seticd_version] = useState(fields[0].trim());
  const [code, setcode] = useState(fields[1].trim());
  const [status, setstatus] = useState("");
  const [showerror, setShowerror] = useState(false);
  const [start_diagnosis_date, setstart_diagnosis_date] = useState("");
  const [end_diagnosis_date, setend_diagnosis_date] = useState("");
  const [snoomed, setsnoomed] = useState("");
  const [comments, setcomments] = useState("");
  const [ProblemStatus, setProblemStatus] = useState([]);
  const [MedicalProblems, setMedicalProblems] = useState([]);
  const [ProblemVal, setProblemVal] = useState("");
  const [fornoresults, setnoresult] = useState("2");
  const [scode, setscode] = useState(code);
  const [title, settitle] = useState([]);
  const [dis, setdis] = useState();
  const [repsave, setRepsave] = useState(false);
  const [showdateErr, setshowdateErr] = useState(false);
  const [verErr, setverErr] = useState(false);
  const [enddateErr, setenddateErr] = useState(false);
  const [statusErr, setStatusErr] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [identifyErr, setIdentifyErr] = useState(false);
  const [selectedDrug, setSelectedDrug] = useState("");
  let medical_problem = [];
  var txt = [];
  var user_id = Decrypt_Value(localStorage.getItem("user_id"), "westside");
  //------------------------------------------State and Variables Start------------------------------------------------ //

  //------------------------------------------Function Start----------------------------------------------------------- //
  const mouseClick = () => setShowOptions(!showOptions);

  const handleMouseLeave = () => setShowOptions(false);

  const OnSubmit = async (e) => {
    e.preventDefault();

    let error = 0;

    if (scode !== selectedDrug) {
      setIdentifyErr(true);
      error = 1;
    }
    if (icd_version === "") {
      setverErr(true);
      error = 1;
    }

    if (scode === "") {
      setShowerror(true);
      error = 1;
    }

    if (start_diagnosis_date === "") {
      setshowdateErr(true);
      error = 1;
    }
    if (end_diagnosis_date === "") {
      setenddateErr(true);
      error = 1;
    }
    if (status === "") {
      setStatusErr(true);
      error = 1;
    }

    if (error > 0) {
      return false;
    }
    setRepsave(true);
    if (id === 0) {
      const article = {
        title: ProblemVal,
        diagnosis: icd_version + " : " + code,
        activity: status,
        begdate: moment(start_diagnosis_date).format("YYYY-MM-DD"),
        enddate: moment(end_diagnosis_date).format("YYYY-MM-DD"),
        snoomed_code: snoomed,
        comments: comments,
        uid: user_id,
      };
      let encryptedData = Encrypt_Value(article, "westside");
      axiosInstance
        .post(
          "/westside/patient/medical_problem/Insert?pid=" + params.pid,
          encryptedData
        )
        .then(() => {
          setProblemVal("");
          seticd_version("");
          setcode("");
          setstatus("");
          setstart_diagnosis_date("");
          setend_diagnosis_date("");
          setsnoomed("");
          setcomments("");
          dataFetch();
          clientShow(false);
          setRepsave(false);
        });
    } else {
      const article = {
        id: id,
        title: ProblemVal,
        diagnosis: icd_version + " : " + code,
        activity: status,
        begdate: moment(start_diagnosis_date).format("YYYY-MM-DD"),
        enddate: moment(end_diagnosis_date).format("YYYY-MM-DD"),
        snoomed_code: snoomed,
        comments: comments,
      };

      let encryptedData = Encrypt_Value(article, "westside");
      axiosInstance
        .put(
          "/westside/patient/medical_problem/Update?pid=" +
            params.pid +
            "&id=" +
            id,
          encryptedData
        )
        .then(() => {
          clientShow(false);
          dataFetch();
          setRepsave(false);
        });
    }
  };

  const OnSaveSubmit = async (e) => {
    e.preventDefault();
    setnoresult("2");

    let err = 0;
    if (scode !== selectedDrug) {
      setIdentifyErr(true);
      err = 1;
    }
    if (icd_version === "") {
      setverErr(true);
      err = 1;
    }

    if (scode === "") {
      setShowerror(true);
      err = 1;
    }

    if (start_diagnosis_date === "") {
      setshowdateErr(true);
      err = 1;
    }
    if (end_diagnosis_date === "") {
      setenddateErr(true);
      err = 1;
    }
    if (status === "") {
      setStatusErr(true);
      err = 1;
    }
    if (err > 0) {
      return false;
    }
    setRepsave(true);
    const article = {
      title: ProblemVal,
      diagnosis: icd_version + " : " + code,
      activity: status,
      begdate: moment(start_diagnosis_date).format("YYYY-MM-DD"),
      enddate: moment(end_diagnosis_date).format("YYYY-MM-DD"),
      snoomed_code: snoomed,
      comments: comments,
      uid: user_id,
    };
    let encryptedData = Encrypt_Value(article, "westside");
    axiosInstance
      .post(
        "/westside/patient/medical_problem/Insert?pid=" + params.pid,
        encryptedData
      )
      .then(() => {
        setProblemVal("");
        seticd_version("");
        setcode("");
        setscode("");
        setstatus("");
        setstart_diagnosis_date("");
        setend_diagnosis_date("");
        setsnoomed("");
        setcomments("");
        dataFetch();
        setRepsave(false);
      });
  };

  const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const icdChange = (e, data) => {
    seticd_version(data.value);
    setscode("");
    setdis(false);
    setverErr(false);
  };

  const statusChange = (e, data) => {
    setstatus(data.value);
    setStatusErr(false);
    setnoresult("1");
  };

  const codeResult = (e, data) => {
    setscode(data["label"]);
    setcode(data["label"]);
    setProblemVal(data["label"].split("--")[1]);
    setSelectedDrug(data.value);
  };

  const codeChange = (data) => {
    setscode(data);
    setShowerror(false);
    setnoresult("1");
    if (data === "") {
      setnoresult("2");
    }
    settitle([]);
    setIdentifyErr(false);
  };

  const startDateChange = (e) => {
    setstart_diagnosis_date(moment(e).format("DD-MMM-YYYY"));
    setshowdateErr(false);
  };

  const endDateChange = (e) => {
    setend_diagnosis_date(moment(e).format("DD-MMM-YYYY"));
    setenddateErr(false);
  };

  const snomedChange = (e) => setsnoomed(e.target.value);

  const commentChange = (e) => setcomments(e.target.value);

  //------------------------------------------Function End----------------------------------------------------------------- //

  //------------------------------------------UseEffect Start-------------------------------------------------------------- //

  useEffect(() => {
    let isCancelled = false;
    const handlechange = async () => {
      await timeout(1000);
      if (!isCancelled) {
        if (scode !== "") {
          if (icd_version === "ICD10") {
            axiosInstance
              .get(
                "https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=code,name&terms=" +
                  scode
              )
              .then((response) => {
                if (response.data[3].length > 0) {
                  setnoresult("2");
                } else {
                  setnoresult("");
                }
                // for (let i = 0; i < response.data[3].length; i++) {
                //   for (let j = 0; j < 1; j++) {
                //     txt.push({
                //       title:
                //         response.data[3][i][0] + "--" + response.data[3][i][1],
                //     });
                //   }
                // }
                const getValue = response.data[3].map((item) => ({
                  title: `${item[0]} -- ${item[1]}`,
                }));
                settitle(getValue);
              })
              .catch((err) => {});
          } else if (icd_version === "ICD9") {
            axiosInstance
              .get(
                "https://clinicaltables.nlm.nih.gov/api/icd9cm_dx/v3/search?terms=" +
                  scode
              )
              .then((response) => {
                if (response.data[3].length > 0) {
                  setnoresult("2");
                } else {
                  setnoresult("");
                }
                // for (let i = 0; i < response.data[3].length; i++) {
                //   for (let j = 0; j < 1; j++) {
                //     txt.push({
                //       title:
                //         response.data[3][i][0] + "--" + response.data[3][i][1],
                //     });
                //   }
                // }
                const getValue = response.data[3].map((item) => ({
                  title: `${item[0]} -- ${item[1]}`,
                }));
                settitle(getValue);
              })
              .catch(() => {});
          } else {
          }
        }
      }
    };

    handlechange();
    return () => {
      isCancelled = true;
    };
  }, [scode, icd_version]);

  useEffect(() => {
    let isCancelled = false;
    const handlechange = async () => {
      await timeout(1000);
      if (!isCancelled) {
        if (ProblemVal !== "") {
          axiosInstance
            .get(
              "https://clinicaltables.nlm.nih.gov/api/conditions/v3/search?terms=" +
                ProblemVal +
                "&df=term_icd9_code,primary_name"
            )
            .then((response) => {
              if (response.data[3].length > 0) {
                setnoresult("2");
              } else {
                setnoresult("");
              }
              for (let i = 0; i < response.data[3].length; i++) {
                for (let j = 0; j < 1; j++) {
                  medical_problem.push({
                    title:
                      response.data[3][i][0] + "--" + response.data[3][i][1],
                    problem: response.data[3][i][1],
                  });
                }
              }
              setMedicalProblems(medical_problem);
            })
            .catch((e) => {
              console.log(e);
              setnoresult("");
              setMedicalProblems([]);
            });
        }
      }
    };
    handlechange();
    return () => {
      isCancelled = true;
    };
  }, [ProblemVal]);

  useEffect(() => {
    setstatus(
      row.activity !== "" && row.activity !== undefined && row.activity !== null
        ? String(row.activity)
        : ""
    );
    row.begdate === ""
      ? setstart_diagnosis_date(row.begdate)
      : setstart_diagnosis_date(moment(row.begdate).format("DD-MMM-YYYY"));
    row.enddate === ""
      ? setend_diagnosis_date(row.enddate)
      : setend_diagnosis_date(moment(row.enddate).format("DD-MMM-YYYY"));
    setsnoomed(row.snoomed_code);
    setcomments(row.comments);
    setProblemVal(row.title);
    setdis(data);
    if (row.diagnosis) {
      let val = row.diagnosis;
      let result = val.split(":")[1].trim();
      setSelectedDrug(result);
    }
    axiosInstance
      .get("/westside/client/problemStatus")
      .then((response) => {
        let decodingResponse = Decrypt_Value(response.data, "westside");
        setProblemStatus(decodingResponse);
        setSpinnerVal(false);
      })
      .catch(() => setProblemStatus([]));
  }, []);

  //------------------------------------------UseEffect End--------------------------------------

  return (
    <div id="add-prob">
      <Form onSubmit={OnSubmit}>
        <Modal.Header closeButton>
          {isLoggedIn ? (
            <>
              <Modal.Title className="pfl-demo">Edit Problem</Modal.Title>
            </>
          ) : (
            <>
              <Modal.Title className="pfl-demo">Add Problem</Modal.Title>
            </>
          )}
        </Modal.Header>
        <Modal.Body className="modal_body modal_body_widthalt">
          <>
            {SpinnerVal ? (
              <SpinnerDiv height="380px">
                <Spinner animation="border" variant="primary" />
              </SpinnerDiv>
            ) : (
              <>
                <Form.Row>
                  <input name="id" type="hidden" value={id}></input>

                  <Form.Group
                    as={Col}
                    className="addProblemMarginTop ph-adj-mgtop"
                    id="icd version"
                  >
                    <Form.Label>ICD Version</Form.Label>
                    <Select
                      placeholder="Select"
                      className={
                        verErr
                          ? "w-100 ant-sel-hei34 ant-sel-err-border"
                          : "w-100 ant-sel-hei34"
                      }
                      suffixIcon={<CustomSuffixIcon />}
                      id="adPro_opt-icdVers"
                      as="select"
                      name="icd_version"
                      value={icd_version}
                      onChange={(e, data) => icdChange(e, data)}
                    >
                      <Option disabled value="">
                        Select
                      </Option>
                      <Option value="ICD9">ICD9</Option>
                      <Option value="ICD10">ICD10</Option>
                    </Select>
                  </Form.Group>
                  {verErr && (
                    <p className="errormessage add_prblm_err_msg">
                      Please Select any version
                    </p>
                  )}
                </Form.Row>

                <Form.Row>
                  <Form.Group
                    as={Col}
                    className="addProblemMarginTop"
                    id="addProblemInput"
                  >
                    <Form.Label>Code</Form.Label>
                    <div className="for_autoComplete_bi pl">
                      <AiOutlineSearch />
                      <AutoComplete
                        onClick={mouseClick}
                        onMouseLeave={handleMouseLeave}
                        id="adPro_inp-cde"
                        disabled={dis}
                        placeholder="Find Codes"
                        dropdownClassName={scode !== "" ? "" : "for_no_loading"}
                        onSelect={codeResult}
                        onChange={codeChange}
                        notFoundContent={
                          showOptions
                            ? fornoresults.length !== 0
                              ? "loading.."
                              : "No Result Found"
                            : ""
                        }
                        options={
                          showOptions &&
                          title.map((x) => {
                            return {
                              label: x.title,
                              value: x.title,
                            };
                          })
                        }
                        value={scode}
                        className="addproblemSearch"
                        style={
                          showerror || identifyErr
                            ? {
                                border: "1px solid red",
                                borderRadius: "5px",
                                width: "348PX",
                              }
                            : { width: "348PX" }
                        }
                      />
                    </div>
                  </Form.Group>
                  {showerror ? (
                    <p className="errormessage err_problem_type_sel">
                      Please Select any type
                    </p>
                  ) : identifyErr ? (
                    <p className="errormessage err_problem_type_sel">
                      Please Select any from the Given List
                    </p>
                  ) : (
                    ""
                  )}
                </Form.Row>

                <Form.Row>
                  <Form.Group
                    as={Col}
                    className="addProblemMarginTop"
                    id="status"
                  >
                    <Form.Label>Status</Form.Label>
                    <Select
                      placeholder="Select"
                      className={
                        statusErr
                          ? "w-100 ant-sel-hei34 ant-sel-err-border"
                          : "w-100 ant-sel-hei34"
                      }
                      suffixIcon={<CustomSuffixIcon />}
                      id="adPro_sel-stat"
                      name="status"
                      value={status}
                      onChange={(e, data) => statusChange(e, data)}
                    >
                      <Option disabled value="">
                        Select
                      </Option>
                      {ProblemStatus.map((row, index) => (
                        <Option key={index} value={row.value}>
                          {row.label}{" "}
                        </Option>
                      ))}
                    </Select>
                    {statusErr ? (
                      <p className="errormessage">Please Select the Status</p>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group
                    as={Col}
                    className="addProblemMarginTop"
                    id="ant-pic-hei34"
                  >
                    <Form.Label>Start Date Diagnosis</Form.Label>
                    <DatePicker
                      disabledDate={(current) => {
                        return end_diagnosis_date
                          ? current &&
                              current > moment(end_diagnosis_date).endOf("day")
                          : "";
                      }}
                      onChange={(e) => startDateChange(e)}
                      format="MMM DD, YYYY"
                      value={
                        start_diagnosis_date
                          ? moment(start_diagnosis_date, "DD-MMM-YYYY")
                          : ""
                      }
                      allowClear={false}
                      placeholder="dd-----yyyy"
                      id="adPro_dt-str"
                      name="start_diagnosis_date"
                      className={
                        showdateErr
                          ? "w-100 addproblemStart ant-pic-err-border"
                          : "w-100 addproblemStart"
                      }
                    />
                    {showdateErr && (
                      <p className="errormessage">Please Select the Date</p>
                    )}
                  </Form.Group>
                  <Form.Group
                    className="addProblemMarginTop"
                    as={Col}
                    id="ant-pic-hei34"
                  >
                    <Form.Label className="addProblemMargin">
                      End Date Diagnosis{" "}
                    </Form.Label>
                    <DatePicker
                      disabledDate={(current) => {
                        return current.isBefore(start_diagnosis_date);
                      }}
                      onChange={(e) => endDateChange(e)}
                      format="MMM DD, YYYY"
                      value={
                        end_diagnosis_date
                          ? moment(end_diagnosis_date, "DD-MMM-YYYY")
                          : ""
                      }
                      allowClear={false}
                      placeholder="dd-----yyyy"
                      id="adPro_dt-end"
                      name="end_diagnosis_date"
                      className={
                        enddateErr
                          ? "w-100 addproblemStart ant-pic-err-border"
                          : "w-100 addproblemStart"
                      }
                    />
                    {enddateErr && (
                      <p className="errormessage">Please Select the Date</p>
                    )}
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group
                    as={Col}
                    className="addProblemMargTop prblm_snomed_"
                  >
                    <Form.Label>Snomed CT Code</Form.Label>
                    <Form.Control
                      name="snoomed"
                      value={snoomed}
                      onChange={snomedChange}
                      id="adPro_inp-snome"
                      autoComplete="off"
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group
                    as={Col}
                    id=""
                    className="addProblemMarginTop addProblemFormgrouop"
                  >
                    <Form.Label>Comments</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      className="allergy-note addProblemTextArea"
                      name="comments"
                      value={comments}
                      onChange={commentChange}
                      id="adPro_inp-cmnt"
                    />
                  </Form.Group>
                </Form.Row>
              </>
            )}
          </>
        </Modal.Body>
        <hr className="addProblemFormHr"></hr>
        <Modal.Footer id="addProblemFooter" className="add_prb_fot">
          {isLoggedIn ? (
            <>
              <Button
                className="close_active1 close_active"
                variant="light"
                onClick={close}
              >
                Close
              </Button>

              <Button
                className="save_active save-btn"
                type="submit"
                variant="primary"
                disabled={repsave}
              >
                Save
              </Button>
            </>
          ) : (
            <>
              <Button
                className="close_active close_active1"
                variant="light"
                onClick={close}
              >
                Close
              </Button>
              <Button
                className="save_active save-btn"
                type="submit"
                variant="primary"
                disabled={repsave}
              >
                Save
              </Button>
              <Button
                className="close_active close_active2 fill-btn m-l-au white-color"
                variant="outline-primary"
                onClick={OnSaveSubmit}
                disabled={repsave}
              >
                Save and Add Another
              </Button>
            </>
          )}
        </Modal.Footer>
      </Form>
    </div>
  );
};

export default memo(AddProblem);

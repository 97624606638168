/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-redeclare */
import React, { useState, useEffect, memo } from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { Image, Button, Form, Spinner } from "react-bootstrap";
import { DatePicker, Checkbox, Select } from "antd";
import "./report.css";
import axiosInstance from "../../axios/axios";
import { FiCalendar } from "react-icons/fi";
import { BsClock } from "react-icons/bs";
import { repeatArray } from "../utils/RepeatFunctions/Schedule";
import Emp_data_pic from "../../assets/images/Emp_data_pic.png";
import moment from "moment";
import { CSVLink } from "react-csv";
import { SpinnerDiv } from "../../StyledComponents";
import Loader_img from "../popups/Loading";

import {
  Table,
  TableHeadCell,
  TableBody,
  TableBodyCell,
  TableHead,
} from "../setting/PatientPortalSettings/StyledComponents";
import { Tablerow } from "../patient/StyledComponent";
import { useRef } from "react";
import CustomSuffixIcon from "../popups/CustomSuffixIcon";
import { Decrypt_Value, Encrypt_Value } from "../../MiddleWare/EncryptDecrypt";

const useStyles = makeStyles({
  container: {
    borderRadius: "4px !important",
    border: "1px solid #cbd5e0",
  },
  row: {
    "& tr:last-child": {
      borderBottom: "none !important",
    },
  },
});

function ProductivityReportTable() {
  // ------------------------------ State and Variables Start---------------------------------------------------//
  const csvLinkRef = useRef(null);
  const [spinner, setspinner] = useState(false);
  const [loader, setLoader] = useState(true);
  const [office, setOffice] = useState("");
  const [header, setheader] = useState([]);
  const [fromdt, setfromdt] = useState("");
  const [todt, settodt] = useState("");
  const [include, setinclude] = useState("0");
  const [result, setresult] = useState([]);
  const [head, sethead] = useState("Provider");
  const [appt, setappt] = useState("");
  const [time, settime] = useState("");
  const [min, setmin] = useState("");
  const [showerror1, setShowerror1] = useState(false);
  const [showerror2, setShowerror2] = useState(false);
  const [mainrows, setmainfutureId] = useState([]);
  const [csvData, setcsvData] = useState([]);
  const [showLoading, setShowLoading] = useState("block");
  const [load_flag, setload_flag] = useState(0);

  const classes = useStyles();
  const { RangePicker } = DatePicker;

  var userID =
    localStorage.getItem("user_id") &&
    Decrypt_Value(localStorage.getItem("user_id"), "westside");
  var userRole =
    localStorage.getItem("roles") &&
    Decrypt_Value(localStorage.getItem("roles"), "westside");
  const { Option } = Select;
  // ------------------------------ State and Variables End---------------------------------------------------//

  // ------------------------------ Functions Start-----------------------------------------------------------//

  const apptFilter = () => {
    setspinner(true);
    var items1 = [];
    let tenPokemon = [];
    var temp = 0;
    var selectOffice = office;
    if (fromdt === "" || todt === "") {
      setShowerror1(true);
      setspinner(false);
    } else if (office === "") {
      setShowerror2(true);
      setspinner(false);
    } else {
      var filter = {
        office: office,
        from: fromdt,
        to: todt,
        include: include,
        pid: userID,
        limit: 40,
        offset: 0,
        userID: userID,
        userRole: userRole,
      };

      let encrypt = Encrypt_Value(filter, "westside");

      axiosInstance
        .put("/westside/productivity/report", encrypt)
        .then((res) => {
          let data = Decrypt_Value(res.data, "westside");

          // For Total Appointment and time Code START//
          let RepeatValues = data[1].filter((pat) => pat.pc_recurrtype !== "0");
          let NotRepeatValues = data[1].filter(
            (pat) => pat.pc_recurrtype === "0"
          );

          var repeatValues = repeatArray(RepeatValues, "ProductivityReport");

          let repeatValuesWithDate = repeatValues[1].filter(
            (pat) =>
              moment(pat.FromdateValidation, "YYYY/MM/DD") >= moment(fromdt)
          );

          let FinalResult = repeatValuesWithDate.concat(NotRepeatValues);

          var totalApptTime = 0;
          for (let i = 0; i < FinalResult.length; i += 1) {
            totalApptTime += parseInt(FinalResult[i].pc_duration);
          }

          var hrs = totalApptTime / 3600;
          var min = totalApptTime / 60;

          var tostringhrs = hrs.toString();
          var tostringmin = min.toString();

          if (tostringhrs.includes(".")) {
            var indexofhrs = tostringhrs.indexOf(".");
            var hoursFinal = tostringhrs.substr(0, indexofhrs);
          } else {
            var hoursFinal = tostringhrs;
          }

          if (tostringmin.includes(".")) {
            var indexofmin = tostringmin.indexOf(".");
            var minFinal = tostringmin.substr(0, indexofmin);
            var minsFinal = minFinal - hoursFinal * 60;
          } else {
            var minsFinal = tostringmin - hoursFinal * 60;
          }

          setappt(FinalResult.length.toString());
          settime(hoursFinal);
          setmin(minsFinal);

          // For Total Appointment and time Code END//

          // For Table  Appointment and time Code START//

          let TableRepeatValues = data[0].filter(
            (pat) => pat.pc_recurrtype !== "0"
          );
          let TableNotRepeatValues = data[0].filter(
            (pat) => pat.pc_recurrtype === "0"
          );

          var TablerepeatValues = repeatArray(
            TableRepeatValues,
            "ProductivityReport"
          );

          let TablerepeatValuesWithDate = TablerepeatValues[1].filter(
            (pat) =>
              moment(pat.FromdateValidation, "YYYY/MM/DD") >= moment(fromdt)
          );

          let TableFinalResult =
            TablerepeatValuesWithDate.concat(TableNotRepeatValues);

          const groupByFinRes = TableFinalResult.reduce((group, product) => {
            const { office } = product;
            group[office] = group[office] ?? [];
            group[office].push(product);
            return group;
          }, {});
          var GroupByRows = Object.entries(groupByFinRes);

          var ProdRepArray = [];
          for (let i = 0; i < GroupByRows.length; i += 1) {
            var TabletotalApptTime = 0;
            var head = GroupByRows[i][1][0].head;
            var office = GroupByRows[i][1][0].office;
            for (let j = 0; j < GroupByRows[i][1].length; j += 1) {
              TabletotalApptTime += parseInt(GroupByRows[i][1][j].pc_duration);
            }

            var Tablehrs = TabletotalApptTime / 3600;
            var Tablemin = TabletotalApptTime / 60;

            var Tabletostringhrs = Tablehrs.toString();
            var Tabletostringmin = Tablemin.toString();

            if (Tabletostringhrs.includes(".")) {
              var Tableindexofhrs = Tabletostringhrs.indexOf(".");
              var TablehoursFinal = Tabletostringhrs.substr(0, Tableindexofhrs);
            } else {
              var TablehoursFinal = Tabletostringhrs;
            }

            if (Tabletostringmin.includes(".")) {
              var Tableindexofmin = Tabletostringmin.indexOf(".");
              var TableminFinal = Tabletostringmin.substr(0, Tableindexofmin);
              var TableminsFinal = TableminFinal - TablehoursFinal * 60;
            } else {
              var TableminsFinal = Tabletostringmin - TablehoursFinal * 60;
            }

            const ProdRepArrayRow = {
              head: head,
              office: office,
              minute: TableminsFinal,
              time: TablehoursFinal,
              duration: Tablehrs,
              appt: GroupByRows[i][1].length,
            };
            ProdRepArray.push(ProdRepArrayRow);
          }

          // For Table  Appointment and time Code END//
          sethead(ProdRepArray[0].head);
          ProdRepArray.forEach((p) => tenPokemon.push(p));
          if (ProdRepArray.length > 15) {
            setShowLoading("block");
          } else {
            setShowLoading("none");
          }

          setmainfutureId(tenPokemon);
          for (temp = 0; temp < 15; temp++) {
            if (tenPokemon[temp] !== undefined) {
              var hours;
              var minutes;
              if (tenPokemon[temp]["time"] !== "0") {
                hours = tenPokemon[temp]["time"] + "Hrs";
              } else {
                hours = tenPokemon[temp]["time"];
              }

              if (tenPokemon[temp]["minute"] !== "0") {
                minutes = tenPokemon[temp]["minute"] + "Mins";
              } else {
                minutes = tenPokemon[temp]["minute"];
              }

              tenPokemon[temp]["duration"] = hours + ":" + minutes;

              items1.push(tenPokemon[temp]);
            }
          }
          setcsvData(items1);
          setresult(items1);
          setspinner(false);
        })
        .catch((err) => {
          setspinner(false);
          setShowLoading("none");
          setcsvData([]);
          setresult([]);
          sethead([]);
          setappt([]);
          settime([]);
          setmin([]);
        });
    }
  };

  const changeinclude = (e) => {
    if (e.target.checked === true) {
      setinclude("1");
    } else {
      setinclude("0");
    }
  };

  const loadTenPokemon = (e) => {
    setShowLoading("block");

    var data = mainrows.length;
    var final = mainrows;
    var rowscount = result.length;

    var res = [];

    for (var i in final) {
      res.push(final[i]);
    }

    if (mainrows.length === rowscount) {
      setShowLoading("none");
    }

    const tenPokemon1 = [];

    if (rowscount >= data - 30 && load_flag === 0) {
      setload_flag(1);
      var selectOffice = office;
      let filter = {
        office: office,
        from: fromdt,
        to: todt,
        include: include,
        limit: 40,
        offset: data,
        userID: userID,
        userRole: userRole,
      };

      let encrypt = Encrypt_Value(filter, "westside");

      axiosInstance
        .put("/westside/productivity/report", encrypt)
        .then((res) => {
          let data = Decrypt_Value(res.data, "westside");
          // For Total Appointment and time Code START//
          let RepeatValues = data[1].filter((pat) => pat.pc_recurrtype !== "0");
          let NotRepeatValues = data[1].filter(
            (pat) => pat.pc_recurrtype === "0"
          );

          var repeatValues = repeatArray(RepeatValues, "ProductivityReport");

          let repeatValuesWithDate = repeatValues[1].filter(
            (pat) => pat.FromdateValidation >= fromdt
          );

          let FinalResult = repeatValuesWithDate.concat(NotRepeatValues);

          var totalApptTime = 0;
          for (let i = 0; i < FinalResult.length; i += 1) {
            totalApptTime += parseInt(FinalResult[i].pc_duration);
          }

          var hrs = totalApptTime / 3600;
          var min = totalApptTime / 60;

          var tostringhrs = hrs.toString();
          var tostringmin = min.toString();

          if (tostringhrs.includes(".")) {
            var indexofhrs = tostringhrs.indexOf(".");
            var hoursFinal = tostringhrs.substr(0, indexofhrs);
          } else {
            var hoursFinal = tostringhrs;
          }

          if (tostringmin.includes(".")) {
            var indexofmin = tostringmin.indexOf(".");
            var minFinal = tostringmin.substr(0, indexofmin);
            var minsFinal = minFinal - hoursFinal * 60;
          } else {
            var minsFinal = tostringmin - hoursFinal * 60;
          }

          setappt(FinalResult.length.toString());
          settime(hoursFinal);
          setmin(minsFinal);

          // For Total Appointment and time Code END//

          // For Table  Appointment and time Code START//

          let TableRepeatValues = data[0].filter(
            (pat) => pat.pc_recurrtype !== "0"
          );
          let TableNotRepeatValues = data[0].filter(
            (pat) => pat.pc_recurrtype === "0"
          );

          var TablerepeatValues = repeatArray(
            TableRepeatValues,
            "ProductivityReport"
          );

          let TablerepeatValuesWithDate = TablerepeatValues[1].filter(
            (pat) => pat.FromdateValidation >= fromdt
          );

          let TableFinalResult =
            TablerepeatValuesWithDate.concat(TableNotRepeatValues);

          const groupByFinRes = TableFinalResult.reduce((group, product) => {
            const { office } = product;
            group[office] = group[office] ?? [];
            group[office].push(product);
            return group;
          }, {});
          var GroupByRows = Object.entries(groupByFinRes);

          var ProdRepArray = [];
          for (let i = 0; i < GroupByRows.length; i += 1) {
            var TabletotalApptTime = 0;
            var head = GroupByRows[i][1][0].head;
            var office = GroupByRows[i][1][0].office;
            for (let j = 0; j < GroupByRows[i][1].length; j += 1) {
              TabletotalApptTime += parseInt(GroupByRows[i][1][j].pc_duration);
            }

            var Tablehrs = TabletotalApptTime / 3600;
            var Tablemin = TabletotalApptTime / 60;

            var Tabletostringhrs = Tablehrs.toString();
            var Tabletostringmin = Tablemin.toString();

            if (Tabletostringhrs.includes(".")) {
              var Tableindexofhrs = Tabletostringhrs.indexOf(".");
              var TablehoursFinal = Tabletostringhrs.substr(0, Tableindexofhrs);
            } else {
              var TablehoursFinal = Tabletostringhrs;
            }

            if (Tabletostringmin.includes(".")) {
              var Tableindexofmin = Tabletostringmin.indexOf(".");
              var TableminFinal = Tabletostringmin.substr(0, Tableindexofmin);
              var TableminsFinal = TableminFinal - TablehoursFinal * 60;
            } else {
              var TableminsFinal = Tabletostringmin - TablehoursFinal * 60;
            }

            const ProdRepArrayRow = {
              head: head,
              office: office,
              minute: TableminsFinal,
              time: TablehoursFinal,
              duration: Tablehrs,
              appt: GroupByRows[i][1].length,
            };
            ProdRepArray.push(ProdRepArrayRow);
          }

          // For Table  Appointment and time Code END//
          setload_flag(0);
          ProdRepArray.forEach((p) => tenPokemon1.push(p));
          setmainfutureId((pokemon) => [...pokemon, ...tenPokemon1]);
          sethead(ProdRepArray[0].head);
        })
        .catch((error) => {
          let tenPokemon1 = [];
          setmainfutureId((pokemon) => [...pokemon, ...tenPokemon1]);
        });
    }

    if (result.length <= mainrows.length) {
      var items = [];
      let s = parseInt(rowscount) + 10;
      for (i = rowscount; i <= s; i++) {
        if (res[i] !== undefined) {
          items.push(res[i]);
        }
      }
      setcsvData((mitems) => [...mitems, ...items]);
      setresult((mitems) => [...mitems, ...items]);
    }
  };

  const DateRangePick = (e) => {
    setShowerror1(false);
    if (e != null) {
      if (e[0] && e[1]) {
        setfromdt(moment(e[0], "YYYY/MM/DD").format("YYYY-MM-DD"));
        settodt(moment(e[1], "YYYY/MM/DD").format("YYYY-MM-DD"));
      }
    } else {
      setfromdt("");
      settodt("");
    }
  };
  const handleExportClick = (event) => {
    event.preventDefault();
    csvLinkRef.current.link.click();
  };
  // ------------------------------ Functions End-------------------------------------------------------------//

  // ------------------------------ UseEffect Start-----------------------------------------------------------//

  useEffect(() => {
    setheader([
      { label: head, key: "office" },
      { label: "Appointments", key: "appt" },
      { label: "Appointment Duration", key: "duration" },
    ]);
  }, [head]);

  useEffect(() => {
    setTimeout(() => setLoader(false), 700);
  }, []);

  // ------------------------------ UseEffect End-----------------------------------------------------------//

  return (
    <div>
      {loader ? (
        <Loader_img />
      ) : (
        <>
          <div className="tab_title_main">
            <div className="tab_title_name">Productivity Report</div>
          </div>
          <div className="pro_rep_sec_row tk-cd-indctr">
            <div
              id="rpt_prd-inp_dt"
              className="prod-rep-srch-box-1-from-date position-relative"
            >
              <RangePicker
                style={
                  showerror1
                    ? {
                        border: "1px solid red",
                        borderRadius: "5px",
                        width: "95%",
                        marginLeft: 4,
                      }
                    : { marginLeft: 4 }
                }
                onChange={(e) => DateRangePick(e)}
                format={"MM/DD/YYYY"}
              />
              {showerror1 && (
                <p className="errormessage dt-err-mess">Please select date</p>
              )}
            </div>
            <div className="service--007">By</div>
            <div className="pro-rep-drop" id="rpt_prd-sel_stDet">
              <Select
                id="b-l-34"
                placeholder="Select"
                className={
                  showerror2
                    ? "phar_tablehead w-100 antd-dd-pro_repo t-a-l ant-sel-err-border"
                    : "phar_tablehead w-100 antd-dd-pro_repo t-a-l"
                }
                suffixIcon={<CustomSuffixIcon />}
                onChange={(e, data) => {
                  setOffice(data.value);
                  setShowerror2(false);
                }}
                value={office}
              >
                <Option value="" disabled>
                  Select
                </Option>
                <Option value="1">Provider</Option>
                {userRole !== "0" && <Option value="2">Office</Option>}
              </Select>
              {showerror2 && (
                <p className="errormessage dt-err-mess">Please select one</p>
              )}
            </div>
            <div className="c_r_check pr-rep-mt-5">
              <span className="">
                <Checkbox
                  className="for-check"
                  defaultChecked={false}
                  onChange={(e) => changeinclude(e)}
                  id="rpt_prdc-inp_canApt"
                />
              </span>
              <span className="w-100">
                Include Cancelled and No Show Appointments
              </span>
            </div>
            <div>
              <Button
                variant="primary"
                className="edit-note pr-rep-mar-h button_Click_event_alt marginTop0"
                onClick={() => {
                  apptFilter();
                }}
                id="rpt_prdc-btn_upt"
              >
                Update
              </Button>
              <br></br>
            </div>
          </div>
          <div className="d-flex prop_rep_thi_row">
            <div className="appt-dis appt-dis-for-top flex-center">
              <div className="icon-appt1">
                <FiCalendar className="pr-r-calender-style" align="right" />
              </div>
              <div className="appt-content">
                <div className="dollor--2">Appointments</div>

                {appt.length > 0 ? (
                  <div className="dollor">{appt}</div>
                ) : (
                  <div className="dollor">{0}</div>
                )}
              </div>
            </div>

            <div className="appt-dis appt-dis-for-top-1 flex-center">
              <div className="m-r-10">
                <BsClock className="prd-rep-clock-style" align="right" />
              </div>
              <div className="appt-content1">
                <div className="dollor--2">Duration</div>
                <div className="dollor">
                  {time.length > 0 ? `${time} Hours ` : "0 Hours"}
                  {min.toString().length > 0 ? `   ${min} Mins` : "   0 Mins"}
                </div>
              </div>
            </div>
          </div>
          <div className="prodtable_alt prod_rep_table_alt">
            <div className={`${classes.container} wholetab`}>
              <div className="prodtab_top ">
                <div className="Prodtab_lef">Productivity Report List</div>
                <div className="Prodtab_rig">
                  <CSVLink
                    data={csvData}
                    ref={csvLinkRef}
                    headers={header}
                    filename={
                      "productivity_report_" +
                      moment(fromdt).format("DD-MM-YYYY") +
                      " To " +
                      moment(todt).format("DD-MM-YYYY")
                    }
                  >
                    <Button
                      className="pr-rep-btn-ecp button_Click_event_alt"
                      onClick={handleExportClick}
                    >
                      Export File to csv
                    </Button>
                  </CSVLink>
                </div>
              </div>
              {spinner ? (
                <SpinnerDiv height="125px">
                  <Spinner animation="border" variant="primary" />
                </SpinnerDiv>
              ) : (
                <>
                  <Table
                    className="product_inn_tab"
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead
                      className="pat-list-thead appt-head elect_data_clr"
                      display="table-header-group"
                      height="56px"
                    >
                      <Tablerow className="schedule-head-row tr-no elect_data_clr bot_border">
                        <TableHeadCell className="elect_data_clr" align="left">
                          {head}
                        </TableHeadCell>
                        <TableHeadCell className="elect_data_clr" align="left">
                          Appointments
                        </TableHeadCell>
                        <TableHeadCell className="elect_data_clr" align="left">
                          Appointment Duration
                        </TableHeadCell>
                      </Tablerow>
                    </TableHead>
                    <TableBody className={classes.body}>
                      {result.length !== 0 ? (
                        <>
                          {result.map((row, ind) => (
                            <Tablerow
                              key={ind}
                              className="table-data-row schedule-body-row"
                              color="#636d73"
                              lineHeight="20.01px"
                              fontFamily=" Roboto, Helvetica,Arial, sans-serif "
                              padding="6px 0px 6px 18px"
                              height="56px"
                            >
                              <TableBodyCell
                                align="left"
                                className="all-tbl-text-main"
                                bodered
                              >
                                <div className="inv_tab_date">{row.office}</div>
                              </TableBodyCell>
                              <TableBodyCell align="left" bodered>
                                {row.appt}
                              </TableBodyCell>
                              <TableBodyCell align="left" bodered>
                                {row.time !== "0" && `${row.time} Hrs `}
                                <span>
                                  {(row.minute !== "0" || row.minute !== "0") &&
                                    `${row.minute} Mins`}
                                </span>
                              </TableBodyCell>
                            </Tablerow>
                          ))}
                        </>
                      ) : (
                        <>
                          <Tablerow className="bot_border">
                            <TableBodyCell
                              className="phar_img_div "
                              colSpan={9}
                            >
                              <div className="img_top1">
                                <Image
                                  height="133px"
                                  width="133px"
                                  src={Emp_data_pic}
                                ></Image>
                              </div>
                            </TableBodyCell>
                          </Tablerow>
                        </>
                      )}
                    </TableBody>
                  </Table>
                </>
              )}
            </div>

            {result.length !== 0 && (
              <div className="loading-more" style={{ display: showLoading }}>
                <Button
                  id="load-data"
                  style={{ display: showLoading }}
                  variant="primary"
                  className="loading-more-div"
                  onClick={loadTenPokemon}
                >
                  Load more...
                </Button>
              </div>
            )}
          </div>{" "}
        </>
      )}
    </div>
  );
}
export default memo(ProductivityReportTable);

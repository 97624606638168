import React from "react";
// import PatientDashboardNav from "./PatientDashboardNav";
import DemographicsPatientDetail from "./DemographicsPatientDetail";
import ProblemsTable from "./ProblemsTable";
import "antd/dist/antd.css";
import SideNav from "../Sidenav";

function Problems() {
  return (
    <>
      <>
        <SideNav />
      </>
      <div className="nav_right problems_pad_mar  neg-marg-btm m-l-250 s-e-t-2">
        <div className="w-99">
          <DemographicsPatientDetail />
          <div id="demographics" className="appt-block-main schedule-comp1">
            <ProblemsTable />
          </div>
        </div>
      </div>
    </>
  );
}

export default Problems;

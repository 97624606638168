/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import { FrameContainer, IFrame } from "./style";

import { memo } from "react";
import { Modal } from "react-bootstrap";

const DocumentView = ({ url, docTitle, setMyState, myState }) => {
  return (
    myState.showDoc && (
      <>
        <Modal.Header
          closeButton
          style={{
            padding: "20px",
            flexDirection: "row-reverse",
            justifyContent: "flex-end",
          }}
        >
          <Modal.Title style={{ marginTop: "0px", marginLeft: "20px" }}>
            {docTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FrameContainer>
            <IFrame src={url} loading="eager" />
          </FrameContainer>
        </Modal.Body>
      </>
    )
  );
};

export default memo(DocumentView);

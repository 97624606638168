import { Image } from "react-bootstrap";
import Loader from "../../assets/images/logo.png";

const Loading = (props) => {
  return (
    <>
      <div className="bg-clr-spin">
        {props.spin === "secure_data" && (
          <h1 className="vozo-load-head">
            Please wait, as exporting data may take a few minutes.
          </h1>
        )}

        <div className="loader_ spin">
          <div className="spin__blocker"></div>
          <div className="spin__bottom-left"></div>
          <div className="spin__bottom-right"></div>
          <div className="spin__top-left"></div>
        </div>
        <Image height="25px" width="25px" src={Loader} className="spin-img" />
      </div>
    </>
  );
};

export default Loading;

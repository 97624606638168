import React from "react";
import PatientEducationTable from "./PatientEducationTable";
import "antd/dist/antd.css";
import SideNav from "../Sidenav";

function PatientEducation(props) {
  return (
    <>
      <>
        <SideNav />
      </>
      <div className="  nav_right ">
        <div
          id="demographics"
          className="appt-block-main-bill mr-14 "
          style={{ paddingBottom: 1 }}
        >
          <div
            className="appt-block-main schedule-comp1 m-b-15"
            id="patientEduTab"
            style={{ marginTop: 4 }}
          >
            <PatientEducationTable />
          </div>
        </div>
      </div>
    </>
  );
}
export default PatientEducation;

import { createSlice } from "@reduxjs/toolkit";

export const RevisionSlice = createSlice({
  name: "Revision",
  initialState: {
    openTemplate: "",
    sessionForm: {},
    askProvider: false,
    LoadImport: false,
    Newnote:false,
    patientImport: "",
    openNotificationTab: false,
  },
  reducers: {
    openDocumentTemplate: (state, action) => {
      state.openTemplate = action.payload;
    },
    openNotificationTabSidenav: (state, action) => {
      state.openNotificationTab = action.payload;
    },
    setSessionForm: (state, action) => {
      state.sessionForm = action.payload;
    },
    setAskProvider: (state, action) => {
      state.askProvider = action.payload;
    },
    getPatientImport: (user) => {
      user.LoadImport = true;
      return user;
    },
    setPatientImport: (state, action) => {
      state.patientImport = action.payload;
      state.LoadImport = false;
    },
    getNewnote: (state, action) => {
      state.Newnote = action.payload;
    },
  },
});

export const {
  openDocumentTemplate,
  openNotificationTabSidenav,
  setSessionForm,
  setAskProvider,
  getPatientImport,
  setPatientImport,
  getNewnote,
} = RevisionSlice.actions;

export default RevisionSlice.reducer;

import React, { useState } from "react";
import { SubNavItem, ListItem, UL, Div } from "./styles";
import { Button, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Messenger from "../Messages";
import CreateClient from "../popups/CreateClient";
import axiosInstance from "../../axios/axios";
import { useDispatch } from "react-redux";
import { openNotificationTabSidenav } from "../../StateManagement/Reducers/PatientState";
import { Decrypt_Value } from "../../MiddleWare/EncryptDecrypt";
// import UnLoadScripts from "../popups/unLoadScript";

const ScheduleNav = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  let finalurl = "";
  let firstDot = window.location.hostname.split(".");
  if (firstDot[0] !== "localhost") {
    finalurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    finalurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }

  var testurl = finalurl + "/sites/default/documentimg/";
  const user_id =
    localStorage.getItem("user_id") &&
    Decrypt_Value(localStorage.getItem("user_id"), "westside");

  const [show, setShow] = useState(false);
  const [clientAddIs, setClientAddIs] = useState(false);
  const [clientFail, setClientFail] = useState(false);
  const [show2, setShow2] = useState(false);
  const [id, setid] = useState("0");
  const [proChat, setProChat] = useState(0);
  const [username, setusername] = useState("");
  const [patimg, setpatimg] = useState("");
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const ClientAdd = (result) => setClientAddIs(result);
  const ClientFailed = (result) => setClientFail(result);
  const refreshData = () => {
    const url = window.location.href;
    const arr = url.split("/");
    const item = arr.pop();
    if (item === "patientlist" || (arr[3] === "patient" && arr[4] === "nav")) {
      // props.refreshPatient();
    } else {
      history.push("/patient/patientlist");
    }
  };
  const handlemsgOpen = () => {
    setShow2(true);
    setid("0");
  };
  const handleclose2 = () => {
    setShow2(false);
  };
  const handleShowMesg = () => {
    axiosInstance
      .get("/westside/appointment/notification/alert?uid=" + user_id)
      .then((response) => {
        let DecodeVal = Decrypt_Value(response.data, "westside");
        if (DecodeVal.total !== "0") {
          axiosInstance
            .get(
              "/westside/appointment/notification/alert/update?uid=" + user_id
            )
            .then((response) => {})
            .catch((err) => {});
        }
      })
      .catch((err) => {
        console.log(err);
      });
    dispatch(openNotificationTabSidenav(true));
  };
  return (
    <Div width="100%" paddingLeft="0.86em">
      <UL
        textAlign="left"
        width="100%"
        listStyleType="none"
        padding="0"
        margin="0"
        position="relative"
      >
        <ListItem padding="8px" margin="8px 0 0 0">
          <SubNavItem to="/schedule/calender" exact>
            Calendar
          </SubNavItem>
        </ListItem>
        <ListItem padding="8px" margin="0">
          <SubNavItem to="/schedule/apptemplate" exact>
            Therapist Availability
          </SubNavItem>
        </ListItem>
        <ListItem padding="8px" margin="0">
          <SubNavItem
            activecolor="#2e2e2e80"
            to="#"
            exact
            onClick={handleShowMesg}
          >
            Notifications
          </SubNavItem>
        </ListItem>
        <ListItem padding="8px" margin="0">
          <SubNavItem
            activecolor={show2 ? "2c7be5" : "#2e2e2e80"}
            to="#"
            exact
            onClick={handlemsgOpen}
          >
            Practice Chat
          </SubNavItem>
        </ListItem>
        <ListItem padding="8px" margin="0">
          <SubNavItem activecolor="#2e2e2e80" to="#" exact onClick={handleShow}>
            Create New Client
          </SubNavItem>
        </ListItem>
      </UL>
      <Modal
        className="client-popup create-client create_modaltop"
        centered
        show={show}
        onHide={handleClose}
      >
        <CreateClient
          clientShow={handleClose}
          dataFetch={refreshData}
          ClientAdd={ClientAdd}
          ClientFailed={ClientFailed}
        />
      </Modal>
      {show2 && (
        <Messenger
          show2={show2}
          close={handleclose2}
          proChatCount={proChat != "noData" ? proChat : 0}
          id={id}
          providerName={username}
          profileimage={patimg === null ? "" : testurl + patimg}
        />
      )}
    </Div>
  );
};

export default ScheduleNav;

import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import PreloginReducer from "./Reducers/PreloginState";
import TopNavReducer from "./Reducers/TopNavState";
import ScheduleReducer from "./Reducers/ScheduleState";
import RevisionReducer from "./Reducers/PatientState";
import MessageReducer from "./Reducers/MessageState";
import RootSaga from "../MiddleWare";

const Saga = createSagaMiddleware();
const store = configureStore({
  reducer: {
    Revision: RevisionReducer,
    Prelogin: PreloginReducer,
    TopNav: TopNavReducer,
    Schedule: ScheduleReducer,
    Message: MessageReducer,
  },
  devTools: process.env.NODE_ENV === "development",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: false,
    }).concat(Saga),
});

Saga.run(RootSaga);

export default store;

import React from "react";
// import { NavLink, useLocation } from "react-router-dom";
// import { Nav } from "react-bootstrap";
import { SubNavItem, ListItem, UL, Div, Span } from "./styles";
import { useDispatch } from "react-redux";
import { getNewnote } from "../../StateManagement/Reducers/PatientState";

export default function PatientNav() {
  // ------------------------------ State and Variables Start-----------------------------------------//

  // const location = useLocation();
  // const { pathname } = location;
  // const splitLocation = pathname.split("/");

  // const locationresult = pathname.includes("formbuilder");
  const dispatch = useDispatch();

  const handleNew = () => {
    dispatch(getNewnote(true));
  };

  // ------------------------------ State and Variables End-----------------------------------------//

  return (
    <>
      {/* <Nav className="flex-column" defaultActiveKey="/patientlist">
        <NavLink
          to="/patient/patientlist"
          exact
          className={
            (splitLocation[1] === "patient" && splitLocation[2] === "nav") ||
            splitLocation[2] === "patientlist"
              ? "active schedule-menu"
              : " schedule-menu"
          }
        >
          Patients
        </NavLink>
     
        <NavLink to="/patient/formlibrary" exact className="schedule-menu">
          Form Library
        </NavLink>

        <NavLink to="/patient/notetaking" exact className="schedule-menu">
          Note Taking
        </NavLink>
        <NavLink
          to="/patient/managemacrobutton"
          exact
          className="schedule-menu"
        >
          Macro Buttons
        </NavLink>

        <NavLink
          to="/patient/formbuilder"
          exact
          className={locationresult ? "schedule-menu active" : "schedule-menu"}
        >
          Form Builder
        </NavLink>           
        <NavLink to="/patient/consentforms" exact className="schedule-menu">
          Consent Forms
        </NavLink>
        <NavLink to="/patient/formarchive" exact className="schedule-menu">
          Form Archive
        </NavLink>
         <div className={`explore-vozo-widget rnav-trans explore_widget`}>
          <img
            src={vozoExploreIcon}
            className="explore-vozo-img"
            alt="icon"
          ></img>
          <h1 className="explore-vozo-heading">Explore Vozo</h1>
          <p className="explore-vozo-para">
            Follow these steps to get running.
          </p>
          <button className="explore-vozo-button" onClick={usrGuideTru}>
            Get Started
          </button>
        </div> 
      </Nav> */}

      <Div width="100%" paddingLeft="0.86em">
        <UL
          textAlign="left"
          width="100%"
          listStyleType="none"
          padding="0"
          margin="0"
          position="relative"
        >
          <ListItem padding="8px" margin="8px 0 0 0">
            <SubNavItem to="/patient/patientlist" exact>
              Patients
            </SubNavItem>
          </ListItem>
          <ListItem padding="8px" margin="0">
            <Span
              color="#2e2e2e80"
              cursor="pointer"
              textDecoration="none"
              className="newnote"
              onClick={handleNew}
            >
              Add Note
            </Span>
          </ListItem>
          <ListItem padding="8px" margin="0">
            <SubNavItem to="/patient/DocumentTemplates" exact>
              Document Templates
            </SubNavItem>
          </ListItem>
          <ListItem padding="8px" margin="0">
            <SubNavItem
              to="/patient/activityTable"
              exact
              // id="setting_nav_facility"
            >
              Portal Activities
            </SubNavItem>
          </ListItem>
          <ListItem padding="8px" margin="0">
            <SubNavItem to="/patient/PatientPayment" exact>
              Patient Payment
            </SubNavItem>
          </ListItem>
        </UL>
        <UL
          textAlign="left"
          width="100%"
          listStyleType="none"
          padding="0"
          margin="0"
          position="relative"
        >
          <ListItem padding="8px" margin="0">
            <SubNavItem to="/patient/formbuilder/formbuilder" exact>
              Form Builder
            </SubNavItem>
          </ListItem>

          <UL
            textAlign="left"
            width="100%"
            listStyleType="none"
            padding="0"
            margin="0"
            position="relative"
          >
            <ListItem padding="8px" margin="0">
              <SubNavItem to="/patient/formarchive" exact>
                Form Archive
              </SubNavItem>
            </ListItem>
            <ListItem padding="8px" margin="0">
              <SubNavItem to="/patient/patienteducation" exact>
                Patient Education
              </SubNavItem>
            </ListItem>
          </UL>
        </UL>
      </Div>
      {/* <Modal
        className="user-guide-popup-box"
        show={showuserguide}
        onHide={handleCloseApp}
      >
        <UserGuide close={handleCloseApp} ProviderName={provname} />
      </Modal> */}
    </>
  );
}

import { takeLatest } from "redux-saga/effects";
import {
  getPreviewDownload,
  getSecureMessageList,
  getSecureSearch,
  getUnreadCount,
} from "../../StateManagement/Reducers/MessageState";
import {
  PreviewDownloadApi,
  SecureMessageList,
  SecureMessagePatientSearch,
  UnreadCountUpdate,
} from "./MessageSagaWorker";

export function* PreviewDownloadSaga() {
  yield takeLatest(getPreviewDownload.type, PreviewDownloadApi);
}

export function* UnreadCountUpdateSaga() {
  yield takeLatest(getUnreadCount.type, UnreadCountUpdate);
}

export function* SecureMessageListSaga() {
  yield takeLatest(getSecureMessageList.type, SecureMessageList);
}

export function* SecureMessageSearchSaga() {
  yield takeLatest(getSecureSearch.type, SecureMessagePatientSearch);
}

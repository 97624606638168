import React, { useState, useEffect, memo } from "react";
import { Modal, Form, Col, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../axios/axios";
import { Decrypt_Value, Encrypt_Value } from "../../MiddleWare/EncryptDecrypt";
import { DatePicker, Select } from "antd";
import CustomSuffixIcon from "./CustomSuffixIcon";
import { useDispatch } from "react-redux";
import { getNewnote } from "../../StateManagement/Reducers/PatientState";
import axios from "axios";
import moment from "moment";

const CreateClient = ({ clientShow, dataFetch, ClientAdd, ClientFailed }) => {
  //-------------------------------------------State and Variables Start-------------------------------------------------------- //
  const dispatch = useDispatch();

  const { Option } = Select;
  let history = useHistory();
  const handleClose = () => clientShow(false);
  const [first_name, setfirstname] = useState("");
  const [last_name, setlastname] = useState("");
  const [email_id, setemailid] = useState("");
  const [phone_number, setphonenumber] = useState("");
  const [fnError, setFnError] = useState(false);
  const [lnError, setLnError] = useState(false);
  const [mailError, setMailError] = useState(false);
  const [mailError1, setMailError1] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [mailErrMsg, setMailErrMsg] = useState("");
  const [disable, setDisable] = useState(false);
  const [sameNumber, setSameNumber] = useState(false);
  const [phoneErrMsg, setPhoneErrMsg] = useState(false);
  const [realNumber, setRealNumber] = useState("");
  const [providerlist, getproviderlist] = useState([]);
  const user_id =
    localStorage.getItem("user_id") &&
    Decrypt_Value(localStorage.getItem("user_id"), "westside");

  const unique_id =
    localStorage.getItem("unique_uid") &&
    Decrypt_Value(localStorage.getItem("unique_uid"), "westside");
  const [providerID, setproviderID] = useState(parseInt(user_id));
  const [providerUniqueID, setProviderUniqueID] = useState(unique_id);
  const [provErr, setProvErr] = useState(false);
  const [udob, setudob] = useState("");
  const [dobErr, setdobErr] = useState(false);

  //-------------------------------------------State and Variables End -------------------------------------------------------- //

  // ------------------------------- Functions Start--------------------------------------------------------- //
  const checkPhone = (e, mode) => {
    e.preventDefault();
    const checkPhone = new Promise((resolve, reject) => {
      handlephonechange(resolve, reject);
    });

    checkPhone
      .then((res) => {
        if (res === "break") {
          throw new Error("number already used");
        }
      })
      .then(() => {
        if (mode === "more") {
          createSaveAddMore();
        } else if (mode === "newnote") {
          handleNewnote();
        } else {
          createSave();
        }
      })
      .catch((err) => {
        console.error("number already used");
      });
  };

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };
  const handleNewnote = (items, type) => {
    if (mailError === true) {
      return;
    }
    if (mailError1 === true) {
      return;
    }
    if (sameNumber === true) {
      return;
    }

    const article = {
      fname: first_name,
      lname: last_name,
      email: email_id,
      phone: phone_number,
      uid: user_id,
      provider: providerID,
      dob: moment(udob).format("YYYY-MM-DD"),
      providerUniqueID,
    };

    if (first_name === "" || first_name === null) {
      setFnError(true);
      return;
    } else if (last_name === "" || last_name === null) {
      setLnError(true);
      return;
    } else if (email_id === "") {
      setMailError(true);
      setMailErrMsg("Enter Email address");
      return;
    } else if (phone_number === "" || phone_number.length !== 12) {
      setPhoneError(true);
      return;
    } else if (providerID === "" || providerID === null) {
      setProvErr(true);
      return;
    } else if (udob === "" || udob === null) {
      setdobErr(true);
      return;
    } else {
      setDisable(true);
      let Data_enc = Encrypt_Value(article, "westside");
      axiosInstance
        .post("/westside/patient/insert", Data_enc)
        .then((response) => {
          let decrypt_response = Decrypt_Value(response.data, "westside");
          let patientId = decrypt_response;
          let enc = 0;
          if (decrypt_response.message) {
            OnChangeClientFail();
            return false;
          }
          if (typeof decrypt_response === "number") {
            axiosInstance
              .put(
                "/westside/appoinment/noteupdate?id=" +
                  enc +
                  "&user_id=" +
                  user_id +
                  "&pid=" +
                  patientId
              )
              .then((res) => {
                const data = Decrypt_Value(res.data, "westside");
                clientShow();
                localStorage.setItem("NewEid", data.pc_eid);
                localStorage.setItem("NewEncounter", data.encounter);
                setTimeout(() => {
                  history.push("/clinicalnotes");
                }, 0);
              })
              .catch((err) => {
                console.log(err.message);
              });

            dispatch(getNewnote(false));
            localStorage.setItem("NewPid", patientId);
            localStorage.setItem("notesback", true);
            localStorage.setItem("place", "patientlist");

            OnChangeClientAdd();
            setfirstname("");
            setlastname("");
            setemailid("");
            setphonenumber("");
            clientShow();
            dataFetch();
            setDisable(false);
          } else {
            OnChangeClientFail();
            setfirstname("");
            setlastname("");
            setemailid("");
            setphonenumber("");
            setDisable(false);
          }
        })
        .catch((err) => {
          console.error(err.message);
          setDisable(false);
        });
    }
  };

  const dobChange = (e) => {
    setudob(e);
    setdobErr(false);
  };

  const createSave = async () => {
    if (mailError === true) {
      return;
    }
    if (mailError1 === true) {
      return;
    }
    if (sameNumber === true) {
      return;
    }

    if (first_name === "" || first_name === null) {
      setFnError(true);
      return;
    } else if (last_name === "" || last_name === null) {
      setLnError(true);
      return;
    } else if (email_id === "") {
      setMailError(true);
      setMailErrMsg("Enter Email address");
      return;
    } else if (phone_number === "" || phone_number.length !== 12) {
      setPhoneError(true);
      return;
    } else if (providerID === "" || providerID === null) {
      setProvErr(true);
      return;
    } else if (udob === "" || udob === null) {
      setdobErr(true);
      return;
    } else {
      setDisable(true);

      // let axiosData = {
      //   strLName: last_name,
      //   strFName: first_name,
      //   dblHomePhone: phone_number,
      //   strEmail: email_id,
      //   dtmBirth: moment(udob).format("YYYY-MM-DD"),
      // };

      const article = {
        fname: first_name,
        lname: last_name,
        email: email_id,
        phone: phone_number,
        uid: user_id,
        provider: providerID,
        dob: moment(udob).format("YYYY-MM-DD"),
        providerUniqueID,
      };

      let Data_enc = Encrypt_Value(article, "westside");

      axiosInstance
        .post("/westside/patient/insert", Data_enc)
        .then((response) => {
          let decrypt_response = Decrypt_Value(response.data, "westside");

          if (decrypt_response.message) {
            OnChangeClientFail();
            return false;
          }
          if (typeof decrypt_response === "number") {
            OnChangeClientAdd();
            setfirstname("");
            setlastname("");
            setemailid("");
            setphonenumber("");
            clientShow();
            dataFetch();
            setDisable(false);
          } else {
            OnChangeClientFail();
            setfirstname("");
            setlastname("");
            setemailid("");
            setphonenumber("");
            setDisable(false);
          }
        })
        .catch((err) => {
          console.error(err.message);
          setDisable(false);
        });
      // })
      // .catch((err) => {
      //   console.error(err.message);
      //   console.error("Failed to insert patient in westside behavioural");
      // });
    }
  };

  const handlephonechange = (resolve, reject) => {
    if (phone_number !== "") {
      let a = {
        value: phone_number,
        pid: "none",
      };
      let data1 = Encrypt_Value(a, "westside");
      axiosInstance
        .post("/westside/check/patient/phonenumber?type=patient", data1)
        .then((res) => {
          let data = Decrypt_Value(res.data, "westside");

          if (data === "number not found") {
            setSameNumber(false);
            resolve("continue");
          } else if (
            data.pid &&
            (data.pid !== undefined || data.pid !== "" || data.pid !== null)
          ) {
            setSameNumber(true);
            setPhoneErrMsg("This phone number Already exist");
            resolve("break");
          } else {
            setSameNumber(false);
            resolve("continue");
          }
        })
        .catch((err) => {
          console.error(err);
          resolve("continue");
        });
    } else {
      resolve("continue");
    }
  };

  const createSaveAddMore = async () => {
    if (mailError === true) {
      return;
    }
    if (mailError1 === true) {
      return;
    }
    if (sameNumber === true) {
      return;
    }

    const article = {
      fname: first_name,
      lname: last_name,
      email: email_id,
      phone: phone_number,
      uid: user_id,
      provider: providerID,
      dob: moment(udob).format("YYYY-MM-DD"),
      providerUniqueID,
    };

    if (first_name === "" || first_name === null) {
      setFnError(true);
      return;
    } else if (last_name === "" || last_name === null) {
      setLnError(true);
      return;
    } else if (email_id === "") {
      setMailError(true);
      setMailErrMsg("Enter Email address");
      return;
    } else if (phone_number === "" || phone_number.length !== 12) {
      setPhoneError(true);
      return;
    } else if (providerID === "" || providerID === null) {
      setProvErr(true);
      return;
    } else {
      let Data_enc = Encrypt_Value(article, "westside");
      setDisable(true);
      axiosInstance
        .post("/westside/patient/insert", Data_enc)
        .then((response) => {
          let decrypt_response = Decrypt_Value(response.data, "westside");
          if (decrypt_response.message) {
            OnChangeClientFail();
            return false;
          }
          if (typeof decrypt_response === "number") {
            setfirstname("");
            setlastname("");
            setemailid("");
            setphonenumber("");
            setDisable(false);
            clientShow();
            history.push("/patient/demographics/" + decrypt_response);
          } else {
            OnChangeClientFail();
            setfirstname("");
            setlastname("");
            setemailid("");
            setphonenumber("");
            setDisable(false);
          }
        })
        .catch((err) => {
          setDisable(false);
          console.error(err.message);
        });
    }
  };

  const OnChangeClientAdd = () => {
    ClientAdd(true);
    setTimeout(() => {
      ClientAdd(false);
    }, 1000);
  };

  const OnChangeClientFail = () => {
    ClientFailed(true);
    setTimeout(() => {
      ClientFailed(false);
    }, 1000);
  };

  const formatPhoneNumber = (input) => {
    const cleaned = ("" + input).replace(/\D/g, "");

    const digits = cleaned.slice(0, 10);

    let formattedNumber = "";
    for (let i = 0; i < digits.length; i++) {
      if (i === 3 || i === 6) {
        formattedNumber += "-";
      }
      formattedNumber += digits[i];
    }
    return formattedNumber;
  };

  const phoneChange = (event) => {
    const input = event.target.value;
    const formatted = formatPhoneNumber(input);
    setphonenumber(formatted);
    setPhoneError(false);
    setSameNumber(false);
  };

  const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const EmailCheck = (e) => {
    setemailid(e.target.value);

    const regex = /^[a-zA-Z0-9.]+@[a-zA-Z0-9]+\.[a-zA-Z]{1,4}$/;
    if (regex.test(e.target.value)) {
      setMailError(false);
    } else {
      setMailError(true);
      setMailErrMsg("Enter valid e-mail address");
    }
  };

  const fnameSetVal = (e) => {
    if (/^[a-zA-Z\s]+$/.test(e.target.value) || e.target.value === "") {
      setfirstname(e.target.value);
      setFnError(false);
    }
  };

  const lnameSetVal = (e) => {
    if (/^[a-zA-Z\s]+$/.test(e.target.value) || e.target.value === "") {
      setlastname(e.target.value);
      setLnError(false);
    }
  };

  const provIdSetVal = (e, data) => {
    setproviderID(e);
    setProviderUniqueID(data.id);
    setProvErr(false);
  };
  // ------------------------------- Functions End----------------------------------------------------------- //

  // ------------------------------- UseEffect Start ------------------------------------------------------- //

  useEffect(() => {
    let isCancelled = false;
    const handlechange = async () => {
      await timeout(1000);
      if (!isCancelled) {
        if (email_id !== "") {
          console.log(email_id, "email_id");
          let data1 = Encrypt_Value({ email: email_id }, "westside");
          axiosInstance
            .post("/westside/patient/mail/check", data1)
            .then((res) => {
              let DecodeVal = Decrypt_Value(res.data, "westside");
              let id = DecodeVal.id;
              if (DecodeVal === "Email not found") {
                setMailError1(false);
              } else if (id !== undefined || id !== "" || id !== null) {
                setMailError1(true);
                setMailErrMsg("This E-mail Already Exists");
              } else {
                setMailError1(false);
              }
            });
        }
      }
    };
    handlechange();
    return () => {
      isCancelled = true;
    };
  }, [email_id]);

  useEffect(() => {
    let article = Encrypt_Value("limit=40&offset=0", "westside");

    axiosInstance
      .get(
        "/westside/getuserlist?search=" +
          article +
          "&userID=" +
          user_id +
          "&active=" +
          1
      )
      .then((response) => {
        let DecodeVal = Decrypt_Value(response.data, "westside");
        getproviderlist(DecodeVal);
      })
      .catch((err) => {
        getproviderlist([]);
      });
  }, []);

  // ------------------------------- UseEffect End ------------------------------------------------------- //

  return (
    <div id="client-create">
      <Form>
        <Modal.Header closeButton>
          <Modal.Title className="table-top">Create Client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Row>
            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                name="first_name"
                style={
                  fnError
                    ? { border: "1px solid red", borderRadius: "5px" }
                    : {}
                }
                value={first_name}
                onChange={(e) => fnameSetVal(e)}
                autoComplete="off"
              />
              {fnError ? (
                <p className="errormessage">Invalid first name</p>
              ) : (
                ""
              )}
            </Form.Group>
            <Form.Group as={Col} controlId="formGridPassword">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                name="last_name"
                value={last_name}
                style={
                  lnError
                    ? { border: "1px solid red", borderRadius: "5px" }
                    : {}
                }
                onChange={(e) => lnameSetVal(e)}
                autoComplete="off"
              />
              {lnError ? <p className="errormessage">Invalid last name</p> : ""}
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} className="client-form-control">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                name="email_id"
                value={email_id}
                style={
                  mailError || mailError1
                    ? { border: "1px solid red", borderRadius: "5px" }
                    : {}
                }
                onChange={EmailCheck}
                autoComplete="off"
              />

              {mailError ? (
                <p className="errormessage">{mailErrMsg}</p>
              ) : mailError1 ? (
                <p className="errormessage">{mailErrMsg}</p>
              ) : (
                ""
              )}
              <span className="disable-text">
                To grant Client Portal access, enter an email address
              </span>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                id="pat_crt-inp_pho"
                name="phone"
                value={phone_number}
                style={
                  phoneError || sameNumber
                    ? { border: "1px solid red", borderRadius: "5px" }
                    : {}
                }
                onKeyDown={handleKeyDown}
                onChange={phoneChange}
                autoComplete="off"
              />
              {phoneError ? (
                <p className="errormessage">Invalid Phone number</p>
              ) : (
                ""
              )}
              {sameNumber ? <p className="errormessage">{phoneErrMsg}</p> : ""}
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} className="dis-grid">
              <Form.Label>DOB</Form.Label>
              <DatePicker
                disabledDate={(current) => {
                  return current > moment().endOf("day");
                }}
                onChange={(e) => dobChange(e)}
                format="MM-DD-YYYY"
                value={udob ? moment(udob, "MM-DD-YYYY") : ""}
                allowClear={false}
                placeholder="dd-mmm-yyyy"
                id="patientinfodobval"
                className={
                  dobErr ? "product_Error ant-pic-hei34" : "ant-pic-hei34"
                }
              />
              {dobErr && <p className="errormessage">Please select a date</p>}
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Provider</Form.Label>

              <Select
                placeholder="Select Provider"
                className="w-100 creat_provider ant-sel-hei34"
                dropdownAlign={
                  providerlist.length != 0
                    ? providerlist.length >= 8
                      ? {
                          offset: [0, -300],
                        }
                      : providerlist.length == 1
                      ? { offset: [0, -80] }
                      : { offset: [0, -80 - (providerlist.length - 1) * 33] }
                    : ""
                }
                id="new-app-office"
                value={providerID ? providerID : undefined}
                // disabled={!storedNames.pat_g}
                onChange={(e, data) => provIdSetVal(e, data)}
                suffixIcon={<CustomSuffixIcon />}
              >
                {providerlist.map((s, ind) => (
                  <Option
                    key={`29${ind}`}
                    value={s.id}
                    id={s.unique_uid}
                    className={`crt_cln-pro_li${ind}`}
                  >
                    {`${s.fname} ${s.lname}`}
                  </Option>
                ))}
              </Select>
              {provErr ? (
                <p className="errormessage">Please Select Provider</p>
              ) : (
                ""
              )}
            </Form.Group>
          </Form.Row>
        </Modal.Body>

        <Modal.Footer className="client-button">
          <Button
            className="close_active close-btn"
            variant="light"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className="close_active fill-btn"
            variant="outline-secondary"
            onClick={(e) => checkPhone(e, "more")}
          >
            Save and fill more details
          </Button>
          <Button
            className="close_active fill-btn"
            variant="outline-secondary"
            onClick={(e) => checkPhone(e, "newnote")}
          >
            Save and add Note
          </Button>
          <Button
            className="save_active save-btn"
            variant="primary"
            disabled={disable}
            onClick={(e) => checkPhone(e, "save")}
          >
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </div>
  );
};

export default memo(CreateClient);

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Nav, NavItem, TopIcon, Span, Div } from "./styles";
import { BsCalendar4 } from "react-icons/bs";
import {
  FiChevronRight,
  FiChevronDown,
  FiSettings,
  FiFilePlus,
} from "react-icons/fi";
import ScheduleNav from "./ScheduleNav";
import Menu from "../../assets/images/menu_icon .png";
import Close from "../../assets/images/CloseDoc.png";
import SettingsNav from "./SettingsNav";
import PatientNav from "./PatientNav";
import PatientSubNav from "./PatientSubNav";
import ReportNav from "./ReportNav";
import { FaUsers } from "react-icons/fa";

const SideNav = () => {
  // ------------------------------ State and Variables start--------------------------------------------------- //
  const [isOpen, setIsOpen] = useState(false);
  const [showPatientSubMenu, setShowPatientSubMenu] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState("");
  const [sideNav, setSideNav] = useState(false);
  const Patient_subroutes = [
    "/patient/patientdashboard/",
    "/patient/demographics/",
    "/patient/appointment/",
    "/patient/billandinsurance/",
    "/patient/documents/",
    "/patient/problems/",
    "/patient/allergies/",
    "/patient/medication/",
    "/patient/immunization/",
    "/patient/familyhistory/",
    "/patient/procedureorder/",
    "/patient/senderx/",
    "/patient/clientportalaccess/",
    "/patient/session/notes/",
    "/patient/billandInsurance/",
  ];

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const isOnPatientDashboard = Patient_subroutes.some((path) =>
    location.pathname.includes(path)
  );

  const sidenav_open = localStorage.getItem("sidenav_open");
  const events = ["click"];
  // ------------------------------ State and Variables end---------------------------------------------------//

  // ------------------------------ Functions----start------------------------------------------------------//

  const handleMouseEnter = () => {
    setIsOpen(true);
    isOnPatientDashboard
      ? setShowPatientSubMenu(true)
      : setShowPatientSubMenu(false);

    if (
      (splitLocation[1] === "schedule" && splitLocation[2] === "nav") ||
      splitLocation[2] === "calender" ||
      location.pathname === "/"
    ) {
      setOpenSubMenu("schedule");
    } else {
      setOpenSubMenu(splitLocation[1]);
    }
  };

  const handleMouseLeave = () => {
    showPatientSubMenu ? setIsOpen(true) : setIsOpen(false);
    setOpenSubMenu("");
  };
  const handleClose = () => {
    isOnPatientDashboard && setShowPatientSubMenu(false);
    setIsOpen(false);
    setOpenSubMenu("");
  };

  const handleSubMenuClick = (menuName) => {
    setOpenSubMenu(openSubMenu === menuName ? "" : menuName);
  };

  const handleMouseEnterPatient = () => {
    setShowPatientSubMenu(true);
    setIsOpen(true);
    setOpenSubMenu("patient");
  };

  const handleMouseLeavePatient = () => {
    setShowPatientSubMenu(false);
    setIsOpen(false);
    setOpenSubMenu("");
  };

  // ------------------------------ Functions----end---------------------------------------------------//

  // ------------------------------ useEffect----start---------------------------------------------------//

  useEffect(() => {
    if (isOnPatientDashboard && isOpen) {
      handleMouseEnterPatient();
      setShowPatientSubMenu(true);
    }
  }, [isOnPatientDashboard]);

  useEffect(() => {
    setSideNav(sidenav_open);

    const handleEvents = (event) => {
      localStorage.removeItem("sidenav_open");
      setSideNav(false);
    };

    for (let i in events) {
      window.addEventListener(events[i], handleEvents);
    }

    return () => {
      for (let i in events) {
        window.removeEventListener(events[i], handleEvents);
      }
    };
  }, []);

  // ------------------------------ useEffect----end---------------------------------------------------//

  return (
    <>
      <Nav
        onMouseLeave={handleMouseLeave}
        isopen={isOpen || sideNav ? "true" : "false"}
        isOnPatientDashboard={isOnPatientDashboard}
        zindex={isOnPatientDashboard && showPatientSubMenu ? "1052" : "11"}
        onMouseEnter={handleMouseEnter}
      >
        <TopIcon>
          {isOpen || sideNav ? (
            <img src={Close} alt="Close-icon" onClick={handleClose}></img>
          ) : (
            <img src={Menu} alt="menu-icon" onClick={handleMouseEnter}></img>
          )}
        </TopIcon>
        <Div display="flex" width="100%" marginLeft="40px">
          <NavItem
            isopen={isOpen || sideNav ? "true" : "false"}
            to={isOpen || sideNav ? "/schedule" : "/schedule/nav/1"}
            color={
              splitLocation[1] === "schedule" || location.pathname === "/"
                ? "#2c7be5"
                : "#2E2E2E80"
            }
            onClick={(e) => {
              if (isOpen || sideNav) {
                e.preventDefault();
                handleSubMenuClick("schedule");
              }
            }}
          >
            <BsCalendar4 strokeWidth="0.5" title="Schedule" />
            <Span>Schedule</Span>
          </NavItem>
          {(isOpen || sideNav) && (
            <Div
              onClick={() => handleSubMenuClick("schedule")}
              cursor="pointer"
              margin="24px 36px 0px auto"
              color={
                splitLocation[1] === "schedule" || location.pathname === "/"
                  ? "#2c7be5"
                  : "#2E2E2E80"
              }
            >
              {openSubMenu === "schedule" ? (
                <FiChevronDown className="svgSize" />
              ) : (
                <FiChevronRight className="svgSize" />
              )}
            </Div>
          )}
        </Div>
        {openSubMenu === "schedule" && <ScheduleNav />}

        <Div display="flex" width="100%" marginLeft="40px">
          <NavItem
            isopen={isOpen || sideNav ? "true" : "false"}
            to={isOpen || sideNav ? "/patient" : "/patient/nav/1"}
            color={splitLocation[1] === "patient" ? "#2c7be5" : "#2E2E2E80"}
            onClick={(e) => {
              if (isOpen || sideNav) {
                e.preventDefault();
                handleSubMenuClick("patient");
              }
            }}
          >
            <FaUsers strokeWidth="0.5" title="Patient" />
            <Span>Patient</Span>
          </NavItem>
          {(isOpen || sideNav) && (
            <Div
              onClick={() => handleSubMenuClick("patient")}
              cursor="pointer"
              margin="24px 36px 0px auto"
              color={splitLocation[1] === "patient" ? "#2c7be5" : "#2E2E2E80"}
            >
              {openSubMenu === "patient" ? (
                <FiChevronDown className="svgSize" />
              ) : (
                <FiChevronRight className="svgSize" />
              )}
            </Div>
          )}
        </Div>
        {openSubMenu === "patient" && <PatientNav />}
        <Div display="flex" width="100%" marginLeft="40px">
          <NavItem
            isopen={isOpen || sideNav ? "true" : "false"}
            id="rpt_nav-lnk_anch"
            to={isOpen || sideNav ? "/report" : "/report/nav/1"}
            onClick={(e) => {
              if (isOpen || sideNav) {
                e.preventDefault();
                handleSubMenuClick("report");
              }
            }}
            color={splitLocation[1] === "report" ? "#2c7be5" : "#2E2E2E80"}
          >
            <FiFilePlus strokeWidth="3" title="Reports" />
            <Span>Reports</Span>
          </NavItem>
          {(isOpen || sideNav) && (
            <Div
              onClick={() => handleSubMenuClick("report")}
              cursor="pointer"
              margin="24px 36px 0px auto"
              color={splitLocation[1] === "report" ? "#2c7be5" : "#2E2E2E80"}
            >
              {openSubMenu === "report" ? (
                <FiChevronDown className="svgSize" />
              ) : (
                <FiChevronRight className="svgSize" />
              )}
            </Div>
          )}
        </Div>
        {openSubMenu === "report" && <ReportNav />}
        <Div display="flex" width="100%" marginLeft="40px">
          <NavItem
            isopen={isOpen || sideNav ? "true" : "false"}
            to={isOpen || sideNav ? "/settings" : "/settings/nav/1"}
            onClick={(e) => {
              if (isOpen || sideNav) {
                e.preventDefault();
                handleSubMenuClick("settings");
              }
            }}
            color={splitLocation[1] === "settings" ? "#2c7be5" : "#2E2E2E80"}
          >
            <FiSettings strokeWidth="3" title="Settings" />
            <Span>Settings</Span>
          </NavItem>
          {(isOpen || sideNav) && (
            <Div
              onClick={() => handleSubMenuClick("settings")}
              cursor="pointer"
              margin="24px 36px 0px auto"
              color={splitLocation[1] === "settings" ? "#2c7be5" : "#2E2E2E80"}
            >
              {openSubMenu === "settings" ? (
                <FiChevronDown className="svgSize" />
              ) : (
                <FiChevronRight className="svgSize" />
              )}
            </Div>
          )}
        </Div>
        {openSubMenu === "settings" && <SettingsNav />}
      </Nav>
      {isOnPatientDashboard && showPatientSubMenu && (
        <PatientSubNav
          onMouseEnter={handleMouseEnterPatient}
          onMouseLeave={handleMouseLeavePatient}
        />
      )}
    </>
  );
};

export default SideNav;

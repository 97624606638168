import React, { useState, useEffect } from "react";
import { SubNavItem, ListItem, UL, Div } from "./styles";
import { useLocation } from "react-router-dom";
import { FiChevronUp, FiChevronDown } from "react-icons/fi";

const SettingsNav = () => {
  // const [openMenu, setOpenMenu] = useState(null);
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const accountRoutes = [
    "settingsmyprofile",
    "settingpracticeinfo",
    "CalendarIntegration",
    "activityTable",
    "Users",
  ];

  // const toggleItem = (title) => {
  //   setOpenMenu(openMenu === title ? null : title);
  // };
  const currentItem = splitLocation[2];

  // useEffect(() => {
  //   if (currentItem) {
  //     if (accountRoutes.includes(currentItem)) {
  //       setOpenMenu("account");
  //     }
  //   }
  // }, []);

  return (
    <Div width="100%" paddingLeft="0.86em" margin="24px 0 0 16px">
      {/* <Div
        cursor="pointer"
        display="flex"
        fontWeight="600"
        fontSize="14px"
        color={openMenu === "account" ? "#2E2E2E" : "rgba(46, 46, 46, 0.50)"}
        fontFamily="Open Sans,sans-serif"
        svgFontSize="16px"
        svgMarginLeft="8px"
        svgMarginTop="4px"
      >
        <Div onClick={() => toggleItem("account")}>Account settings</Div>
        {openMenu === "account" ? <FiChevronUp /> : <FiChevronDown />}
      </Div> */}
      {/* {openMenu === "account" && ( */}
      <UL
        textAlign="left"
        width="100%"
        listStyleType="none"
        padding="0"
        margin="0"
      >
        <ListItem marginTop="-8px">
          <SubNavItem
            to="/settings/settingsmyprofile"
            id="setting_nav_profile"
            exact
          >
            My Profile
          </SubNavItem>
        </ListItem>
        <ListItem marginTop="16px">
          <SubNavItem
            to="/settings/settingpracticeinfo"
            exact
            id="setting_nav_facility"
          >
            Location Information
          </SubNavItem>
        </ListItem>
        <ListItem marginTop="16px">
          <SubNavItem
            to="/settings/CalendarIntegration"
            exact
            id="setting_nav_facility"
          >
            Calendar Integration
          </SubNavItem>
        </ListItem>
        <ListItem marginTop="16px">
          <SubNavItem to="/setting/BillingSettings" exact>
            Stripe Integration
          </SubNavItem>
        </ListItem>
        {/* <ListItem marginTop="16px">
            <SubNavItem
              to="/settings/activityTable"
              exact
              id="setting_nav_facility"
            >
              Portal Activites
            </SubNavItem>
          </ListItem> */}
        <ListItem marginTop="16px">
          <SubNavItem to="/settings/Users" exact id="setting_nav_users">
            User
          </SubNavItem>
        </ListItem>
      </UL>
      {/* )} */}
    </Div>
  );
};

export default SettingsNav;

import React, { useState, useEffect, memo } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../axios/axios";
import { Decrypt_Value, Encrypt_Value } from "../../MiddleWare/EncryptDecrypt";
import { AutoComplete } from "antd";
import { AiOutlineSearch } from "react-icons/ai";
import { useDispatch } from "react-redux";
import {
  getNewnote,
  setAskProvider,
} from "../../StateManagement/Reducers/PatientState";

const CreateNote = ({ clientShow, dataFetch }) => {
  //-------------------------------------------State and Variables Start-------------------------------------------------------- //
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const [fornoresults, setnoresult] = useState("");
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [patientId, setPatientId] = useState("");
  const [disable, setdisable] = useState(true);

  let history = useHistory();

  const user_id =
    localStorage.getItem("user_id") &&
    Decrypt_Value(localStorage.getItem("user_id"), "westside");

  //-------------------------------------------State and Variables End -------------------------------------------------------- //

  // ------------------------------- Functions Start--------------------------------------------------------- //
  const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };
  const mouseClick = () => {
    setShowOptions(!showOptions);
  };
  const handleClose = () => {
    clientShow();
    setdisable(true);
  };
  const handleSearchChange = (data) => {
    setdisable(true);
    setSearch(data);
    setnoresult("1");
    setFilteredPatients([]);
  };

  const patientSelect = (props) => {
    let value = props.split("-");
    setSearch(value[0]);
    setPatientId(value[1]);
    setdisable(false);
  };

  const handleSave = (items, type) => {
    let enc = 0;

    if (disable) return false;
    if (type === "new") {
      axiosInstance
        .put(
          "/westside/appoinment/noteupdate?id=" +
            enc +
            "&user_id=" +
            user_id +
            "&pid=" +
            patientId
        )
        .then((res) => {
          const data = Decrypt_Value(res.data, "westside");
          clientShow();
          dataFetch();
          localStorage.setItem("NewEid", data.pc_eid);
          localStorage.setItem("NewEncounter", data.encounter);
          setTimeout(() => {
            history.push("/clinicalnotes");
          }, 0);

          dispatch(setAskProvider(true));
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
    dispatch(getNewnote(false));
    localStorage.setItem("NewPid", patientId);
    localStorage.setItem("notesback", true);
    localStorage.setItem("place", "patientlist");
  };

  // ------------------------------- Functions End----------------------------------------------------------- //

  // ------------------------------- UseEffect Start ------------------------------------------------------- //
  useEffect(() => {
    let isCancelled = false;
    const handlechange = async () => {
      if (search !== "") {
        await timeout(1000);
      }
      if (!isCancelled) {
        var Data_frsh;
        if (search.length === 0) {
          Data_frsh = "limit=40&offset=0&uid=" + user_id;
        } else {
          Data_frsh =
            "fname=" +
            search.toLowerCase() +
            "&limit=40&offset=0&uid=" +
            user_id;
        }
        let Data_enc = Encrypt_Value(Data_frsh, "westside");

        axiosInstance
          .get("/westside/get/patient/list?search=" + Data_enc)
          .then((response) => {
            let DecodeVal = Decrypt_Value(response.data, "westside");
            setFilteredPatients(DecodeVal);
            setnoresult(DecodeVal);
          })
          .catch((err) => {
            setFilteredPatients([]);
            setnoresult("");
          });
      }
    };
    handlechange();
    return () => {
      isCancelled = true;
    };
  }, [search]);

  useEffect(() => {
    dispatch(getNewnote(false));
  }, []);

  // ------------------------------- UseEffect End ------------------------------------------------------- //

  return (
    <div id="client-create">
      <Form>
        <Modal.Header>
          <Modal.Title className="table-top">Select a Patient</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="bgset-search">
            <div className="s-e-t-head">
              <div className="for_autoComplete_bi_Patient pat-search-autocomplete p-t-16">
                <AiOutlineSearch />
                <AutoComplete
                  onClick={mouseClick}
                  placeholder="Search a Patient"
                  onSelect={patientSelect}
                  onSearch={handleSearchChange}
                  dropdownClassName={search !== "" ? "" : "for_no_loading"}
                  notFoundContent={
                    showOptions
                      ? fornoresults.length !== 0
                        ? "loading..."
                        : "No Results Found"
                      : ""
                  }
                  className="top-nav-pat-search"
                  id="top-nav-pat-search"
                  options={
                    showOptions &&
                    filteredPatients.map((x) => {
                      return {
                        label: x.name,
                        value: x.name + "-" + x.pid,
                      };
                    })
                  }
                  value={search}
                />
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="client-button">
          <Button
            className="close_active close-btn"
            variant="light"
            onClick={handleClose}
          >
            Cancel
          </Button>

          <Button
            disabled={disable}
            className="save_active save-btn"
            variant="primary"
            onClick={(e) => handleSave(e, "new")}
          >
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </div>
  );
};

export default memo(CreateNote);

import { createSlice } from "@reduxjs/toolkit";

export const TopNavSlice = createSlice({
  name: "TopNav",
  initialState: {
    profilePicture: "", // Initial profile picture state
    refreshInsurance: false,
    activeTab: "1",
    ActiveRefreshPage: false,
  },
  reducers: {
    updateProfilePicture: (state, action) => {
      state.profilePicture = action.payload;
    },
    changeRefreshInsurance: (state, action) => {
      state.refreshInsurance = action.payload;
    },
    changeActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },

    changeActiveNotificationLoad: (state, action) => {
      state.ActiveRefreshPage = !state.ActiveRefreshPage;
    },
  },
});

export const {
  updateProfilePicture,
  changeRefreshInsurance,
  changeActiveTab,
  changeActiveNotificationLoad,
} = TopNavSlice.actions;

export default TopNavSlice.reducer;

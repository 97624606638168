import React, { memo, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FaTimes } from "react-icons/fa";
import { Encrypt_Value, Decrypt_Value } from "../../MiddleWare/EncryptDecrypt";
import HeadNav from "./HeadNav";
import { PageTitle, Text, ModalDiv } from "../../StyledComponents";
import { Button } from "./StyledComponents";
import { Div } from "./Styles";
import { ChatTabTitle, MessageContiner, Modal, ModalContainer } from "./Styles";
import { ModalHeader } from "../setting/PatientPortalSettings/StyledComponents";

import {
  getSecureMessageList,
  setPreviewDownload,
} from "../../StateManagement/Reducers/MessageState";
import {
  DataDiv,
  Image,
  Tooltip,
  TooltipText,
} from "../top_navbar/Notifications/styles";
import DocType from "../../assets/images/docType.png";
import SendIcon from "../../assets/images/SendButton.png";
import Microphone_Start from "../../assets/images/Microphone_Start.png";
import Microphone_Pause from "../../assets/images/MicroPhone_Pause.png";
import Microphone_End from "../../assets/images/MicroPhone_End.png";
import audioPreviewButton from "../../assets/images/Circled Play.png";
import PatientChatLists from "./Patient Chat/patientChatLists";
import axiosInstance from "../../axios/axios";
import Chat from "./Patient Chat/chats";
import {
  Close,
  DowloadBtn,
  Row,
  SearchInputText,
  Attach,
} from "./Patient Chat/Message/styles";
import AudioPreview from "./Patient Chat/chats/audioPreview";
import { FiTrash2 } from "react-icons/fi";
import { BsInfoCircle } from "react-icons/bs";
import webmFixDuration from "webm-duration-fix";

const Messenger = (props) => {
  // ------------------------------ State And Variables Start------------------------ //
  const dispatch = useDispatch();
  const [value1, setValue1] = useState("");

  const [msg, setMsg] = useState([]);
  const secureMsgList = useSelector((state) => state.Message.SecureMessageList);
  const NewMsgs = useSelector((state) => state.Message.NewMsgs);
  const [MsgCount, setMsgCount] = useState(0);
  const [viewType, setViewType] = useState("");
  const [patientChatMsgs, setPatientchatMsgs] = useState({
    session: "",
    headData: "",
    loading: true,
    selectedFile: "",
    sessioVal: "",
    patPic: "",
    fileName: "",
    preview: "",
    imgPre: "",
    ImgUrl: "",
  });
  const [PreviewDownloads, setPreviewDownloads] = useState("");
  const user_id =
    localStorage.getItem("user_id") &&
    Decrypt_Value(localStorage.getItem("user_id"), "westside");

  const [showAlert, setShowAlert] = useState(false);
  const [Refresh, setRefresh] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [audioURLs, setAudioURLs] = useState([]);
  const audioContext = useRef(null);
  const audioContext1 = useRef(null);
  const mediaRecorder = useRef(null);
  const mediaRecorder1 = useRef(null);
  const audioChunks = useRef([]);
  const hiddenFileInput = useRef(null);
  const audioChunks1 = useRef([]);
  const audioStream = useRef(null);
  const audioStream1 = useRef(null);
  const [isRecordingPaused, setIsRecordingPaused] = useState(false);
  const [audioURLs1, setAudioURLs1] = useState([]);
  const [recordingKey, setRecordingKey] = useState(0);
  const [one, setOne] = useState(false);
  const [two, setTwo] = useState(true);
  const [three, setThree] = useState(false);
  const [record, compRecord] = useState(false);
  const [delClick, setDelClick] = useState(false);
  const startRecording = async () => {
    try {
      compRecord(false);
      if (!audioStream.current) {
        audioStream.current = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
      }
      audioChunks.current = [];
      audioContext.current = new (window.AudioContext ||
        window.webkitAudioContext)();
      mediaRecorder.current = new MediaRecorder(audioStream.current);
      setRecordingKey((prevKey) => prevKey + 1);
      mediaRecorder.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunks.current.push(event.data);
        }
      };
      const audioBlobs = [];
      mediaRecorder.current.onstop = async () => {
        const duration = Date.now();
        const audioBlob = new Blob(audioChunks.current, { type: "audio/wav" });
        audioBlobs.push(audioBlob);
        const fixedBlob = await webmFixDuration(audioBlob, duration);
        setAudioURLs1(URL.createObjectURL(audioBlob));

        audioChunks.current = [];
        handleOnSend(fixedBlob);
      };

      mediaRecorder.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error("Error starting recording:", error);
    }
  };

  const startRecording1 = async () => {
    try {
      if (!audioStream1.current) {
        audioStream1.current = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
      }

      audioContext1.current = new (window.AudioContext ||
        window.webkitAudioContext)();
      mediaRecorder1.current = new MediaRecorder(audioStream1.current);

      mediaRecorder1.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunks1.current.push(event.data);
        }
      };

      mediaRecorder1.current.onstop = () => {
        const audioBlob = new Blob(audioChunks1.current, { type: "audio/wav" });

        const audioURL = URL.createObjectURL(audioBlob);
        setAudioURLs((prevAudioURLs) => [...prevAudioURLs, audioURL]);

        audioChunks1.current = [];
      };

      mediaRecorder1.current.start();

      setThree(false);
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };

  const pauseRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
      mediaRecorder.current.pause();
      setTwo(true);
      setIsRecording(false);
      setIsRecordingPaused(true);
    }
  };

  const pauseRecording1 = () => {
    if (
      mediaRecorder1.current &&
      mediaRecorder1.current.state === "recording"
    ) {
      mediaRecorder1.current.pause();
    }
  };

  const resumeRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === "paused") {
      mediaRecorder.current.resume();
      setIsRecording(true);
      setIsRecordingPaused(false);
    }
  };

  const resumeRecording1 = () => {
    if (mediaRecorder1.current && mediaRecorder1.current.state === "paused") {
      mediaRecorder1.current.resume();
    }
  };

  const stopRecording1 = () => {
    setThree(true);
    setOne(true);
    compRecord(true);
    if (
      mediaRecorder1.current &&
      (mediaRecorder1.current.state === "recording" ||
        mediaRecorder1.current.state === "paused")
    ) {
      mediaRecorder1.current.stop();
      audioStream1.current.getTracks().forEach((track) => {
        track.stop();
      });
      audioStream1.current = null;
      audioContext1.current.close();
    }
  };

  const stopRecording = () => {
    compRecord(true);
    if (three && audioURLs.length === 0) {
      return;
    }

    if (delClick) {
      audioStream.current = null;
    }
    if (
      mediaRecorder.current &&
      (mediaRecorder.current.state === "recording" ||
        mediaRecorder.current.state === "paused")
    ) {
      mediaRecorder.current.stop();
      audioStream.current !== null &&
        audioStream.current.getTracks().forEach((track) => {
          track.stop();
        });
      audioStream.current = null;
      audioContext.current.close();
      setIsRecording(false);
      setIsRecordingPaused(false);
    }
    setTimeout(() => {
      // setAudioURLs([]);
      setOne(false);
    }, 1500);
  };

  function getAudioBlobDuration(audioBlob) {
    return new Promise((resolve, reject) => {
      const audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
      const fileReader = new FileReader();
      fileReader.onload = function (event) {
        const arrayBuffer = event.target.result;
        audioContext.decodeAudioData(arrayBuffer, function (buffer) {
          const duration = Math.floor(buffer.duration);
          resolve(duration);
        });
      };
      if (audioBlob instanceof Blob) {
        fileReader.readAsArrayBuffer(audioBlob);
      } else {
        reject("Not a Blob");
      }
    });
  }

  const handleOnSend = async (audioBlob) => {
    try {
      let duration1 = null;

      if (audioBlob !== undefined && audioBlob.size > 0) {
        duration1 = await getAudioBlobDuration(audioBlob);
      }

      if (!record) {
        if (three && audioURLs.length === 0) {
          return;
        }

        if (delClick) {
          audioStream.current = null;
        }
        if (
          mediaRecorder.current &&
          (mediaRecorder.current.state === "recording" ||
            mediaRecorder.current.state === "paused")
        ) {
          mediaRecorder.current.stop();
          audioStream.current !== null &&
            audioStream.current.getTracks().forEach((track) => {
              track.stop();
            });
          audioStream.current = null;
          audioContext.current.close();
          setIsRecording(false);
          setIsRecordingPaused(false);
        }
      }

      let article = {
        message: value1,
        ipAddress: "127.0.0.1",
        sender_id: user_id,
        sender: "Provider",
        recip_id: props.providerName,
        receive_id: patientChatMsgs.sessioVal.toString(),
        status: 1,
        message_type: "text",
        timerVal: duration1,
      };

      let enc = Encrypt_Value(article, "westside_portal");

      const filedata = new FormData();
      filedata.append("message", enc);

      if (audioBlob !== undefined && audioBlob.size > 0) {
        filedata.append("audio", audioBlob, "recorded_audio.wav");
      } else if (patientChatMsgs.selectedFile) {
        filedata.append("fileupload", patientChatMsgs.selectedFile);
      } else if (value1.trim() === "") {
        return false;
      }

      axiosInstance
        .post("/westside/patient/sendMessage", filedata)
        .then((res) => {
          setRefresh(!Refresh);
        })
        .catch((e) => console.log(e));

      setValue1("");
      setPatientchatMsgs({ ...patientChatMsgs, selectedFile: null });
      setAudioURLs([]);
      setAudioURLs1([]);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  // ------------------------------State And Variables End------------------------ //

  // ------------------------------Functions Start------------------------------- //

  const selFileNull = () => {
    setPatientchatMsgs({ ...patientChatMsgs, selectedFile: null });
    if (hiddenFileInput.current !== null) {
      hiddenFileInput.current.value = null;
    }
  };
  const imgPreFls = () => {
    setPatientchatMsgs({ ...patientChatMsgs, imgPre: false });
  };
  const valSetfunc = (e) => {
    setValue1(e.target.value);
  };
  const toDelete = () => {
    if (window.confirm("Are you sure you want to delete?")) {
      setAudioURLs([]);
      setAudioURLs1([]);
      setOne(false);
      setTwo(true);
      setIsRecording(false);
      setIsRecordingPaused(false);
      setDelClick(true);
    }
  };
  const toggleImg = () => {
    setTwo(false);
  };

  const handlePreview = (item) => {
    let getName = item.split("/");
    setPatientchatMsgs({
      ...patientChatMsgs,
      imgPre: true,
      img_url: item,
      fileName: getName[getName.length - 1],
    });
  };

  const handleSecureMsgClick = (val, item) => {
    if (patientChatMsgs.sessioVal === item.pid) {
      return;
    }
    setPatientchatMsgs({ ...patientChatMsgs, loading: true });

    localStorage.setItem("Prov_Id", Encrypt_Value(item.id, "westside"));
    let a = {
      img_url: item.img_url,
      ProviderName: item.PatName,
    };
    let obj = {
      session: item.PatName,
      patPic: item.img_url,
      headData: a,
      loading: true,
      selectedFile: "",
      sessioVal: item.pid,
    };
    setPatientchatMsgs(obj);

    dispatch(setPreviewDownload(""));
    if (patientChatMsgs.sessioVal !== item.pid) {
      setValue1("");
      setAudioURLs([]);
      setAudioURLs1([]);
      setOne(false);
      setTwo(true);
      setIsRecording(false);
      setIsRecordingPaused(false);
      setDelClick(true);
      audioContext.current = null;
      audioContext1.current = null;
      mediaRecorder.current = null;
      mediaRecorder1.current = null;
      audioChunks.current = [];
      audioChunks1.current = [];
      audioStream.current = null;
      audioStream1.current = null;
      setPatientchatMsgs((prev) => ({
        ...prev,
        selectedFile: "",
        fileName: "",
        preview: "",
        imgPre: "",
        imgUrl: "",
      }));
      if (hiddenFileInput.current !== null) {
        hiddenFileInput.current.value = null;
      }
    }
  };

  const handleClose = () => {
    setMsg([]);
    setPatientchatMsgs({
      session: "",
      headData: "",
      loading: true,
      selectedFile: "",
      sessioVal: "",
      patPic: "",
      fileName: "",
      preview: "",
      imgPre: "",
      ImgUrl: "",
    });
    props.close();
  };

  const handleContainerClick = (e) => {
    e.stopPropagation();
  };

  const getPatientMsgs = () => {
    dispatch(getSecureMessageList({ userId: user_id }));
  };

  const handleChat = (val) => {
    if (val.length !== 0) {
      setPatientchatMsgs({
        ...patientChatMsgs,
        session: val.PatName,
        sessioVal: val.pid,
        patPic: val.img_url,
      });
    }
  };

  const handleDownload = () => {
    let data = {
      file: patientChatMsgs.img_url,
    };
    let enc = Encrypt_Value(data, "westside");
    axiosInstance
      .put("/westside/PreviewDownloadmessenger", enc)
      .then((res) => {
        let Data = Decrypt_Value(res.data, "westside");
        setPreviewDownloads(Data);
      })
      .catch((e) => {
        console.log(e);
        setPreviewDownloads("");
      });
  };
  const filtInputAttach = () => hiddenFileInput.current.click();

  const handleChange = (event) => {
    const ext = event.target.files[0].type.split("/")[1];
    switch (ext) {
      case "jpg":
      case "jpeg":
      case "bmp":
      case "png":
      case "pdf":
        setPatientchatMsgs({
          ...patientChatMsgs,
          selectedFile: event.target.files[0],
        });
        break;
      default:
        setShowAlert(true);
        setPatientchatMsgs({ ...patientChatMsgs, selectedFile: undefined });
        setTimeout(() => {
          setShowAlert(false);
        }, 2000);
    }
  };

  // ------------------------------Functions End-------------------------- //
  // ------------------------------UseEffect Start-------------------------- //

  useEffect(() => {
    getPatientMsgs();
  }, []);

  useEffect(() => {
    if (patientChatMsgs.sessioVal !== "") {
      let body = {
        Provider_Id: user_id,
        Pid: patientChatMsgs.sessioVal,
      };
      let enc = Encrypt_Value(body, "westside_portal");
      axiosInstance
        .post("/westside/patient/getMessagelist", enc)
        .then((res) => {
          let DecodeVal = Decrypt_Value(res.data, "westside_portal");
          setMsg(DecodeVal);

          setPatientchatMsgs({ ...patientChatMsgs, loading: false });

          let readchats = [];
          readchats = DecodeVal.filter((item) => item.type !== "date");

          let article = {
            Provider_Id: user_id,
            Pid: patientChatMsgs.sessioVal,
            chats: readchats,
            Sender: "Patient",
          };
          let enc = Encrypt_Value(article, "westside");
          axiosInstance
            .put("/westside/patient/setreadmsgs", enc)
            .then((res) => {
              getPatientMsgs();
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [patientChatMsgs.sessioVal, Refresh]);

  useEffect(() => {
    if (!patientChatMsgs.selectedFile) {
      setPatientchatMsgs({ ...patientChatMsgs, previe: undefined });
      return;
    }

    var checkType = patientChatMsgs.selectedFile.type.split("/");

    if (checkType[0] === "image") {
      setViewType("image");
    } else {
      setViewType("doc");
    }

    const objectUrl = URL.createObjectURL(patientChatMsgs.selectedFile);
    setPatientchatMsgs({
      ...patientChatMsgs,

      fileName: patientChatMsgs.selectedFile.name,
      preview: objectUrl,
    });
    return () => URL.revokeObjectURL(objectUrl);
  }, [patientChatMsgs.selectedFile]);

  useEffect(() => {
    if (PreviewDownloads !== "") {
      var tag = document.createElement("a");
      tag.href = PreviewDownloads.file;
      tag.download = PreviewDownloads.filename;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    }
  }, [PreviewDownloads]);

  useEffect(() => {
    setMsgCount(NewMsgs);
  }, [NewMsgs]);

  // ------------------------------UseEffect End-------------------------- //
  return (
    <>
      <Modal
        show={props.show2}
        justifyContent="center"
        onClick={handleClose}
        width="100%"
        height="100%"
      >
        <ModalContainer
          position="relative"
          justifyContent="center"
          borderRadius="8px"
          width="970px"
          height="600px"
          onClick={handleContainerClick}
        >
          <ModalHeader padding="13px 11px 8px 20px">
            <ChatTabTitle>
              <PageTitle
                position="relative"
                fontFamily="'Open Sans',sans-serif"
                fontWeight="600"
                fontSize="18px"
                lineHight="normal"
                color="rgba(46, 46, 46, 0.50)"
                cursor="pointer"
                padding="0px"
                className="ref-manage-preview-active"
              >
                Patient chat
                {MsgCount !== 0 && (
                  <DataDiv
                    position="absolute"
                    left="calc(100% + 10px)"
                    minWidth="16px"
                    justifyContent="center"
                    display="flex"
                    aspectRatio="1/1"
                    MaxWidth="24px"
                    background="#2C7BE5"
                    br="50px"
                    mt="0px"
                  >
                    <Text
                      textAlign="center"
                      fontFamily="Inter"
                      fontWeight="500"
                      fontSize="12px"
                      color="#ffffff"
                      lineHeight="15px"
                      height="100%"
                      display="flex"
                    >
                      {MsgCount}
                    </Text>
                  </DataDiv>
                )}
              </PageTitle>
            </ChatTabTitle>
            <>
              <Button
                border="none"
                marginLeft="auto"
                background="white"
                cursor="pointer"
                onClick={handleClose}
              >
                <FaTimes
                  onClick={handleClose}
                  style={{ height: "18px", width: "18px", color: "#2E2E2E" }}
                />
              </Button>
            </>
          </ModalHeader>

          <MessageContiner
            position="relative"
            justifyContent="center"
            padding="16px 16px 15px 0px"
            height="551px"
          >
            <PatientChatLists
              list={secureMsgList}
              sessioVal={patientChatMsgs.sessioVal}
              getMsgs={getPatientMsgs}
              handleClick={handleSecureMsgClick}
            />
            {patientChatMsgs.sessioVal ||
            parseInt(patientChatMsgs.sessioVal) === 0 ? (
              <Div
                width="63%"
                height="100%"
                background="#FFF"
                position="relative"
                border="1px solid rgba(46, 46, 46, 0.25)"
                borderRadius="8px"
                overflow="hidden"
              >
                <HeadNav data={patientChatMsgs.headData} />
                <Chat
                  handleChat={handleChat}
                  data={msg}
                  patName={patientChatMsgs.session}
                  patpic={patientChatMsgs.patPic}
                  handlePreview={handlePreview}
                  selectedFile={patientChatMsgs.selectedFile}
                  setSelectedFile={(file) =>
                    setPatientchatMsgs({
                      ...patientChatMsgs,
                      selectedFile: file,
                    })
                  }
                  setViewType={setViewType}
                  profileimage={props.profileimage}
                  providerName={props.providerName}
                  loading={patientChatMsgs.loading}
                />
                {patientChatMsgs.selectedFile && (
                  <Row
                    position="absolute"
                    top="11%"
                    width="100%"
                    height="77%"
                    backgroundColor="rgba(244, 244, 244, 0.9)"
                    display="flex"
                    flexDirection="column"
                    padding="20px"
                    radius="4px"
                  >
                    <Row
                      dis="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Text fontSize="13px" lineHeight="18px" color="#91929e">
                        {patientChatMsgs.fileName}
                      </Text>
                      <Close onClick={selFileNull} />
                    </Row>
                    {viewType === "image" && (
                      <Row
                        position="relative"
                        dis="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                        width="100%"
                        overFlow="hidden"
                      >
                        <Image
                          src={patientChatMsgs.preview}
                          width="max-content"
                          height="max-content"
                          MaxHeight="220px"
                          MaxWidth="220px"
                          margin="auto"
                          objectFit="contain"
                        />
                      </Row>
                    )}
                    {viewType === "doc" && (
                      <Row
                        position="relative"
                        dis="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                        width="100%"
                        overFlow="hidden"
                      >
                        <Image
                          src={DocType}
                          width="max-content"
                          height="max-content"
                          MaxHeight="220px"
                          MaxWidth="220px"
                          margin="auto"
                        />
                      </Row>
                    )}
                  </Row>
                )}

                {patientChatMsgs.imgPre && (
                  <Row
                    position="absolute"
                    top="11%"
                    width="100%"
                    height="89.5%"
                    backgroundColor="rgba(244, 244, 244, 0.9)"
                    display="flex"
                    flexDirection="column"
                    padding="20px"
                    borderRadius="4px"
                    overFlow="hidden"
                  >
                    <Row
                      dis="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      marginBottom="10px"
                    >
                      <Row dis="flex">
                        <Text
                          fontSize="13px"
                          lineHeight="18px"
                          color="#91929e"
                          marginBottom="5px"
                        >
                          {patientChatMsgs.fileName}
                        </Text>
                        <DowloadBtn onClick={handleDownload} />
                      </Row>
                      <Row dis="flex">
                        <Close onClick={imgPreFls} />
                      </Row>
                    </Row>
                    {viewType === "image" && (
                      <Row
                        position="relative"
                        dis="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                        width="100%"
                        overFlow="hidden"
                      >
                        <Image
                          src={patientChatMsgs.img_url}
                          objectFit="contain"
                          width="max-content"
                          height="max-content"
                          MaxHeight="220px"
                          MaxWidth="220px"
                        />
                      </Row>
                    )}
                    {viewType === "doc" && (
                      <iframe
                        title="img"
                        src={patientChatMsgs.img_url}
                        width="100%"
                        height="100%"
                        frameBorder="0"
                      />
                    )}
                  </Row>
                )}
                {!patientChatMsgs.imgPre && (
                  <Row
                    dis="flex"
                    position="relative"
                    margin="0px 10px 16px 20px"
                    background="#ffffff"
                    border="1px solid #EAEAEA"
                    boxShadow="0px 1px 2px rgba(184, 200, 224, 0.222055)"
                    radius="8px"
                    marginLeft="20px"
                    display="flex"
                    maxHeight="fit-content"
                    padding="8px 12px 8px 17px"
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                  >
                    <SearchInputText
                      type="file"
                      accept=".pdf,.png,.jpg,.bmp,.jpeg"
                      ref={hiddenFileInput}
                      onChange={(e) => handleChange(e)}
                      className="d-none"
                    />

                    {!isRecording && !isRecordingPaused ? (
                      <>
                        <Attach onClick={filtInputAttach} />

                        <SearchInputText
                          placeholder="Type your message here …"
                          value={value1}
                          paddingBottom="2px"
                          width="100%"
                          onChange={(e) => valSetfunc(e)}
                          onKeyPress={(e) =>
                            e.key === "Enter" && handleOnSend()
                          }
                        />
                      </>
                    ) : (
                      <Div marginRight="20px">
                        <RecordingStatus
                          isRecording={isRecording}
                          isRecordingPaused={isRecordingPaused}
                          audioURLs1={audioURLs1}
                          audioURLs={audioURLs}
                          recordingKey={recordingKey}
                          one={one}
                        />
                      </Div>
                    )}
                    <Div display="flex" alignItems="center">
                      {!isRecording && !isRecordingPaused ? (
                        <Image
                          mr="20px"
                          width="16px"
                          height="20px"
                          color="#fdc748"
                          cursor="pointer"
                          src={Microphone_Start}
                          alt="mike"
                          onClick={() => {
                            startRecording1();
                            startRecording();
                          }}
                        />
                      ) : isRecording && !isRecordingPaused ? (
                        <Div display="flex" alignItems="center">
                          {" "}
                          <Image
                            mr="20px"
                            width="20px"
                            height="20px"
                            color="#fdc748"
                            cursor="pointer"
                            src={Microphone_Pause}
                            alt="mike"
                            onClick={() => {
                              pauseRecording();
                              pauseRecording1();
                            }}
                          />
                          <Image
                            mr="5px"
                            width="22px"
                            height="22px"
                            color="#fdc748"
                            cursor="pointer"
                            src={SendIcon}
                            onClick={handleOnSend}
                          />
                        </Div>
                      ) : (
                        !isRecording &&
                        isRecordingPaused && (
                          <Div display="flex" alignItems="center">
                            {!one && (
                              <Tooltip>
                                <Image
                                  mr="12px"
                                  width="16px"
                                  height="20px"
                                  color="#fdc748"
                                  cursor="pointer"
                                  src={Microphone_End}
                                  alt="mike"
                                  onClick={() => {
                                    resumeRecording();
                                    resumeRecording1();
                                  }}
                                />
                                <TooltipText className="TooltipText">
                                  Resume
                                </TooltipText>
                              </Tooltip>
                            )}
                            <Div display="flex" alignItems="center">
                              {!isRecording && isRecordingPaused && two ? (
                                <Tooltip>
                                  <Image
                                    width="24px"
                                    height="24px"
                                    color="#fdc748"
                                    cursor="pointer"
                                    src={audioPreviewButton}
                                    onClick={() => {
                                      stopRecording1();
                                      toggleImg();
                                    }}
                                  />
                                  <TooltipText className="TooltipText">
                                    Preview
                                  </TooltipText>
                                </Tooltip>
                              ) : (
                                !isRecording &&
                                isRecordingPaused &&
                                !two && (
                                  <FiTrash2
                                    onClick={toDelete}
                                    style={{
                                      cursor: "pointer",
                                      color: "red",
                                      height: "22px",
                                      width: "22px",

                                      marginRight: "10px",
                                    }}
                                  />
                                )
                              )}

                              <Tooltip>
                                <Image
                                  mr="5px"
                                  width="24px"
                                  height="24px"
                                  color="#fdc748"
                                  cursor="pointer"
                                  src={SendIcon}
                                  onClick={stopRecording}
                                  mt="1px"
                                  ml="10px"
                                />
                                <TooltipText className="TooltipText">
                                  send
                                </TooltipText>
                              </Tooltip>
                            </Div>
                          </Div>
                        )
                      )}
                    </Div>
                    {!isRecording && !isRecordingPaused && (
                      <Image
                        mr=" 5px"
                        ml=" auto"
                        width="24px"
                        height="24px"
                        color="#fdc748"
                        cursor="pointer"
                        src={SendIcon}
                        onClick={handleOnSend}
                      />
                    )}
                  </Row>
                )}
              </Div>
            ) : (
              <Div
                width="63%"
                background="#FFF"
                position="relative"
                height="100%"
                FlexCenter
                border="1px solid rgba(46, 46, 46, 0.25)"
                borderRadius="5px"
              >
                <Text
                  fontFamily="'Open Sans',sans-serif"
                  fontWeight="400"
                  fontSize="16px"
                  lineHeight="16px"
                  color="#000"
                  textAlign="start"
                  padding="14px 0px 0px 19px"
                >
                  Please select a chat
                </Text>
              </Div>
            )}
          </MessageContiner>
        </ModalContainer>
      </Modal>
      <Modal show={showAlert} className="alert-popup-message">
        <ModalDiv className="alert msg alert-danger" role="alert">
          <BsInfoCircle className="icons-bs" />
          File Not allowed
        </ModalDiv>
      </Modal>
    </>
  );
};

const RecordingStatus = ({
  isRecording,
  isRecordingPaused,
  audioURLs,
  recordingKey,
  one,
}) => {
  const [timer, setTimer] = useState(0);
  const [getUrls, SetUrls] = useState([]);
  useEffect(() => {
    let interval;

    if (isRecording && !isRecordingPaused) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    } else if (!isRecordingPaused) {
      clearInterval(interval);
      setTimer(0);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isRecording, isRecordingPaused]);

  useEffect(() => {
    SetUrls(audioURLs);
  }, [audioURLs]);

  return (
    <Div>
      {isRecording ? (
        <Div display="flex" alignItems="center">
          <Div>{timer}s</Div>
          <Div marginLeft="12px" className="voice-note-animation"></Div>
        </Div>
      ) : (
        !one && (
          <Div display="flex" alignItems="center">
            <Div>{timer}s</Div>
            <Div
              marginLeft="20px"
              className="voice-note-animation paused-animation"
            ></Div>
          </Div>
        )
      )}
      {isRecordingPaused && (
        <Div
          display="flex"
          alignItems="center"
          textAlign="center"
          justifyContent="center"
          paddingBottom="3px"
        >
          <Div marginLeft="6px">
            {getUrls && <AudioPreview key={recordingKey} audioBlob={getUrls} />}
          </Div>
        </Div>
      )}
    </Div>
  );
};
export default memo(Messenger);

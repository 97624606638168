import React, { useState, useEffect } from "react";

import { Input, Checkbox } from "antd";
import {
  Encrypt_Value,
  Decrypt_Value,
} from "../../../MiddleWare/EncryptDecrypt";
import axiosInstance from "../../../axios/axios";

import {
  Div1,
  Button1,
  P,
} from "../../setting/PatientPortalSettings/StyledComponents";
import { Avatar, Image } from "../../top_navbar/Notifications/styles";
import { AvatarLetterstyled } from "./style";
import { Data } from "../../top_navbar/Notifications/AppointmentRequest/styles";
import { Modal } from "react-bootstrap";
const AssignPatient = (props) => {
  const { Search } = Input;
  var user_id = Decrypt_Value(localStorage.getItem("user_id"), "westside");
  // var owner_id = Decrypt_Value(localStorage.getItem("owner_id"), "vozo");

  // var authorized =
  //   localStorage.getItem("authorized") &&
  //   Decrypt_Value(localStorage.getItem("authorized"), "vozo");
  // var main_pro =
  //   localStorage.getItem("main_pro") &&
  //   Decrypt_Value(localStorage.getItem("main_pro"), "vozo");
  // var storedNames =
  //   localStorage.getItem("setroles") &&
  //   Decrypt_Value(localStorage.getItem("setroles"), "vozo");

  var DBHost = Decrypt_Value(localStorage.getItem("DBHost"), "westside");
  let finalurl = "";
  let firstDot = window.location.hostname.split(".");
  if (firstDot[0] !== "localhost") {
    finalurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    finalurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }

  DBHost = "default";

  var Test_URL = finalurl + "/sites/" + DBHost + "/documentimg/";

  const [search, setSearch] = useState("");
  const [patNames, setPatNames] = useState([]);
  const [allPatient, setAllPatient] = useState(false);
  const [allPatientCount, setAllPatientCount] = useState("");
  const [allPatientList, setAllPatientList] = useState([]);
  const [btnDis, setbtnDis] = useState(false);
  const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const handlecheckbox = (row, event) => {
    let temp = patNames.map((r) =>
      r.pid === row.pid ? { ...r, checked: event.target.checked } : r
    );
    setPatNames(temp);
    let all;
    temp.map((r) => {
      if (r.checked === false) {
        setAllPatient(false);
        all = false;
      }
    });
    if (all !== false) {
      if (allPatientCount === temp.length) {
        setAllPatient(true);
      }
    }
  };

  const handleAllPatient = (e) => {
    setAllPatient(!allPatient);
    if (e.target.checked === true) {
      setPatNames(allPatientList);
    } else {
      let p = patNames.map((row) =>
        row.checked === true ? { ...row, checked: false } : row
      );
      setPatNames(p);
    }
  };

  const Assignresource = () => {
    let temp = [];

    patNames.map((row, i) => {
      if (row.checked === true) {
        temp.push(row.pid);
      }
    });
    setbtnDis(true);
    let data = {
      pid: temp,
      resourceId: props.data.id,
      uid: user_id,
    };
    let Data_1 = Encrypt_Value(data, "westside");
    axiosInstance
      .put("/westside/patient/patientEducationAllocate", Data_1)
      .then((res) => {
        const allPatientsSelected = patNames.every((row) => row.checked);
        setAllPatient(allPatientsSelected);
        props.handleModal("success");
        setbtnDis(false);
      })
      .catch((e) => {
        console.log(e);
        setbtnDis(false);
      });
  };

  const srchSetVal = (e) => setSearch(e.target.value);

  const hndleModalCancel = () => props.handleModal("cancel");

  useEffect(() => {
    let isCancelled = false;

    const handlechange = async () => {
      await timeout(1000);
      if (!isCancelled) {
        if (search !== "") {
          let Data_ =
            "fname=" +
            search +
            "&resourceId=" +
            props.data.id +
            "&userID=" +
            user_id;

          let Data_1 = Encrypt_Value(Data_, "westside");

          axiosInstance
            .get("/westside/patientSearch/education?search=" + Data_1)
            .then((response) => {
              let DecodeVal = Decrypt_Value(response.data, "westside");

              let t1 = [];
              patNames.map((row) => {
                if (row.checked === true) {
                  t1.push(row);
                }
              });

              t1.map((row) => {
                DecodeVal.map((r, i) => {
                  if (row.pid === r.pid) {
                    DecodeVal.splice(i, 1);
                  }
                });
              });

              setPatNames([...t1, ...DecodeVal]);
            })

            .catch((err) => {
              setPatNames(["No result"]);
            });
        } else {
          if (patNames.length > 1) {
            let t1 = [];
            patNames.map((row) => {
              if (row.checked === true) {
                t1.push(row);
              }
            });
            setPatNames(t1);
          }
        }
      }
    };

    handlechange();
    return () => {
      isCancelled = true;
    };
  }, [search]);

  useEffect(() => {
    setSearch("");
    setPatNames([]);
    let Data_frsh = "fname=" + search.toLowerCase() + "&uid=" + user_id;
    let Data_enc = Encrypt_Value(Data_frsh, "westside");

    axiosInstance
      .get("/westside/get/patient/list?search=" + Data_enc)
      .then((response) => {
        let DecodeVal = Decrypt_Value(response.data, "westside");
        setAllPatientCount(DecodeVal.length);
        DecodeVal.map((row) => {
          if (!row.checked) {
            row.checked = true;
          }
        });

        patNames.map((row) => {
          DecodeVal.map((r, i) => {
            if (row.pid === r.pid) {
              DecodeVal.splice(i, 1);
            }
          });
        });

        setAllPatientList([...patNames, ...DecodeVal]);
      })
      .catch((err) => {
        setAllPatientList([]);
      });

    let Data_ = "resourceId=" + props.data.id + "&userID=" + user_id;

    let Data_1 = Encrypt_Value(Data_, "westside");

    axiosInstance
      .get("/westside/patientSearch/educationassigned?search=" + Data_1)
      .then((response) => {
        let DecodeVal = Decrypt_Value(response.data, "westside");

        setPatNames(DecodeVal);
        if (DecodeVal.length !== 0) setAllPatient(DecodeVal[0].allChecked);
      })
      .catch((err) => {});
  }, [props.data, props.show]);

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => props.handleModal("cancel")}
        className="pat-assign-Modal popup_centre"
        centered
      >
        <Modal.Header
          closeButton
          style={{
            fontFamily: "Inter",
            fontWeight: "600",
            fontSize: "24px",
            lineHeight: "29.05px",
            color: "#000000",
            alignItems: "center",
            flexDirection: "row-reverse",
            padding: "20px",
            justifyContent: "left",
          }}
        >
          <Modal.Title
            style={{
              marginTop: "0px",
              marginLeft: "12px",
              fontFamily: "Inter",
              fontSize: "24px",
              LineHeight: "29px",
            }}
          >
            Assign to patient
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ padding: "0px" }}>
          <Div1 align="left">
            <Div1 padding="16px 16px 16px 20px">
              <P
                family="Inter"
                weight="600"
                size="16px"
                lheight="18.77px"
                color="#2C7BE5"
              >
                {props.data.title}
              </P>
            </Div1>
            <Div1
              backgroundColor="#f7f7f7"
              padding="12px 12px 12px 20px"
              width="499px"
              className="ant-search-pat-edu"
            >
              <Search
                style={{
                  borderRadius: "4px",
                  width: "300px",
                  borderRight: "none",
                }}
                placeholder="Search "
                value={search}
                onChange={(e) => srchSetVal(e)}
              />
            </Div1>

            <Div1
              borderBottom=" 1px solid #CBD5E0"
              display="flex"
              alignItems="center"
              padding="20px"
              className="check-pedu-inner"
            >
              <Div1 className="sled-checkbox-pat_edu" margin="0px 16px 0px 0px">
                <Checkbox
                  checked={allPatient}
                  onChange={(e) => handleAllPatient(e)}
                />
              </Div1>
              <Div1>
                <Avatar width="32px" height="32px" border="1px solid #ffffff">
                  <AvatarLetterstyled
                    top="5px"
                    left="3px"
                    fontSize="18px"
                    lineHeight="16px"
                    fontWeight="400"
                    fontFamily="Work Sans"
                  >
                    All
                  </AvatarLetterstyled>
                </Avatar>
              </Div1>
              <Div1
                color="#2C7BE5"
                family="Inter"
                weight="500"
                size="15px"
                lineheight="18.77px"
              >
                All Patient
              </Div1>
            </Div1>
            <Data height="250px">
              {patNames.map((row, i) => (
                <Div1
                  borderBottom=" 1px solid #CBD5E0"
                  display="flex"
                  alignItems="center"
                  padding="20px"
                  key={i}
                >
                  <Div1
                    className="sled-checkbox-pat_edu"
                    margin="0px 16px 0px 0px"
                  >
                    <Checkbox
                      checked={allPatient ? true : row.checked}
                      onChange={(e) => handlecheckbox(row, e)}
                    />
                  </Div1>
                  <Div1>
                    {row.img_url === null ? (
                      <Avatar
                        width="32px"
                        height="32px"
                        border="1px solid #ffffff"
                      >
                        <AvatarLetterstyled
                          top="4.8px"
                          left="9.5px"
                          fontSize="18px"
                          lineHeight="16px"
                          fontWeight="400"
                          fontFamily="Work Sans"
                        >
                          {row.fname.charAt(0).toUpperCase()}
                        </AvatarLetterstyled>
                      </Avatar>
                    ) : (
                      <Image
                        src={Test_URL + row.img_url}
                        borderRadius="40px"
                        height="32px"
                        width="32px"
                        mr="12px"
                      />
                    )}
                  </Div1>
                  <Div1
                    color="#2C7BE5"
                    family="Inter"
                    weight="500"
                    size="15px"
                    lineheight="18.77px"
                  >
                    {row.fname},{row.lname}
                  </Div1>
                </Div1>
              ))}
            </Data>
          </Div1>
        </Modal.Body>

        <Modal.Footer style={{ padding: "20px" }}>
          <Button1
            color="#414D55"
            padding="8px 19px 7px 19px"
            family="Inter"
            size="14px"
            lheight="16.94px"
            weight="500"
            backgroundColor="#ECEDEF"
            margin="0px 20px 0px 0px"
            className="close_active close_active2"
            onClick={hndleModalCancel}
          >
            Cancel
          </Button1>
          <Button1
            color="#FFFFFF"
            padding="8px 11px 7px 11px"
            family="Inter"
            margin="0px"
            size="14px"
            lheight="16.94px"
            weight="500"
            backgroundColor="#2C7BE5"
            className="save_active"
            disabled={btnDis}
            onClick={Assignresource}
          >
            Assign
          </Button1>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AssignPatient;

import React, { memo } from "react";
import ProductivityReportTable from "./ProductivityReportTable";
import SideNav from "../Sidenav";

function ProductivityReport() {
  return (
    <>
      <>
        <SideNav />
      </>
      <div className="right-block  nav_right w-100">
        <div
          id="demographics"
          className="appt-block-main-bill p-b-6 m-r-0 p-r-13"
        >
          <div className="appt-block-main schedule-comp1 all-for-marg-t-4">
            <ProductivityReportTable />
          </div>
        </div>
      </div>
    </>
  );
}

export default memo(ProductivityReport);

/* eslint-disable no-undef */
import styled from "styled-components";

import { BsXLg } from "react-icons/bs";
import {
  BiPlay,
  BiPause,
  BiFullscreen,
  BiExitFullscreen,
} from "react-icons/bi";

export const Image = styled.img`
  height: ${({ height }) => (height ? height : "28px")};
  width: ${({ width }) => (width ? width : "28px")};
  margin-right: ${({ margin }) => margin && "8px"};
  cursor: ${({ cursor }) => cursor && "pointer"};
`;

export const TitleLabel = styled.label`
  font-family: ${({ fontFamily }) =>
    fontFamily ? fontFamily : "Work Sans" + ",sans-serif"};
  font-weight: 600;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "16px")};
  line-height: ${({ fontHeight }) => (fontHeight ? fontHeight : "19px")};
  color: #333333;
  margin-left: ${({ margin }) => margin && "20px"};
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 4px;
`;

export const ContainerCenter = styled.div`
  position: fixed;
  top: 50%;
  right: 50%;
  transform: translate(50%, -48%);
  background: #ffffff;
  border: 1px solid #cbd5e0;
  box-shadow: 5px 5px 8px rgba(20, 46, 110, 0.15);
  width: 50%;
  height: 95%;
  border-radius: 8px;
`;

export const FlexTitle = styled.span`
  display: flex;
  padding: 22px 20px 20px 20px;
  border-bottom: ${({ border }) => border && "1px solid #cbd5e0"};
`;

export const FrameContainer = styled.div`
  margin-top: 6px;
  margin-bottom: 6px;

  height: calc(100vh - 180px);
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const IFrame = styled.iframe`
  width: 544px;
  border: none;
`;

export const VideoContaier = styled.div`
  display: flex;

  height: "max-content";
  width: "max-content";
`;

export const CloseIcon = styled.div`
  display: flex;
  padding: ${({ padding }) => padding && padding};
  flex-direction: row-reverse;
  position: absolute !important;
  top: -31px !important;
  right: 177px !important;
  background: #718096;
  border-radius: 50%;
  height: 22px;
  width: 22px;
`;

export const MainPlayerContaier = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  min-width: 100%;
  background-size: cover;
`;

export const PalyerContainer = styled.div`
  position: relative;
  border-radius: 16px;
  font-size: 0;
  overflow: hidden;
  height: 80%;
  width: 80%;
`;

export const PlayerVideo = styled.video`
  width: 100%;
`;

export const PlayerControl = styled.div`
  position: absolute;
  bottom: ${({ playIs }) => (playIs ? "20px" : "-35px")};
  width: 100%;
  transition: all 0.3s;
  padding: 0px 20px;
`;

export const TimeContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const TyperInput = styled.input`
  background: transparent;
  width: 70px;
  border: none;
  color: #ffffff;
  outline: none;
  font-family: Inter, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
`;

export const RangeSlider = styled.input.attrs({ type: "range" })`
  height: 4px;
  width: 100%;
  // -webkit-appearance: none;
  border-radius: 5px;
  outline: none;
  // margin-left: 12px;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.2s;
  background: rgba(255, 255, 255, 0.3);
  color: red;
  &:focus {
    outline: none;
  }

  &::-webkit-slider-thumb {
    // -webkit-appearance: none;
    outline: none;
    border-radius: 50%;
    cursor: pointer;
    width: 16px;
    height: 16px;
    background: #ffffff;
    box-shadow: 0px 0px 2px rgba(37, 33, 98, 0.2),
      0px 2px 10px rgba(37, 33, 98, 0.1);
  }
`;

export const Progress = styled.div`
  flex: 10;
  position: relative;
  display: flex;
  flex-basis: 100%;
  height: 5px;
  transition: height 0.3s;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
`;

export const ProgressFill = styled.div`
  width: 100%;
  background: #2c7be5;
  flex: 0;
  flex-basis: ${({ fill }) => (fill ? fill : "0%")};
`;

export const ImageClose = styled(BsXLg)`
  display: none;
  position: absolute;
  z-index: 1001;
  height: 20px;
  width: 20px;
  cursor: pointer;
  color: #ffffff;
  right: 30px;
  top: 20px;
`;

export const Play = styled(BiPlay)`
  position: absolute;
  cursor: pointer;
  height: 50px;
  width: 50px;
  top: 43%;
  left: 48%;
  & path {
    fill: #ffffff;
  }
`;

export const Pause = styled(BiPause)`
  // z-index: 1001;
  cursor: pointer;
  position: absolute;
  height: 50px;
  width: 50px;
  top: 43%;
  left: 48%;
  & path {
    fill: #ffffff;
  }
`;
export const Fullscreen = styled(BiFullscreen)`
  height: 16px;
  width: 16px;
  cursor: pointer;
  & path {
    fill: #ffffff;
  }
`;
export const ExitFullscreen = styled(BiExitFullscreen)`
  height: 16px;
  width: 16px;
  z-index: 1001;
  cursor: pointer;
  & path {
    fill: #ffffff;
  }
`;

export const PlayPause = styled.div`
  display: none;
`;

export const FlexCenter = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

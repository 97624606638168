import { call, put } from "redux-saga/effects";
import { Decrypt_Value, Encrypt_Value } from "../EncryptDecrypt";
import { setPatientImport } from "../../StateManagement/Reducers/PatientState";
import { PatientImportApi } from "../Api/PatientApi";

export function* PatientImport({ payload }) {
  const encrypted = Encrypt_Value(payload, "westside");
  try {
    const res = yield call(PatientImportApi, encrypted);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "westside");
      yield put(setPatientImport(Data));
    }
  } catch (e) {
    yield put(setPatientImport(""));
    console.log(e.message);
  }
}

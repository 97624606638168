import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { BsCheckCircle } from "react-icons/bs";
import axiosInstance from "../../../../axios/axios";
import AppReqCalendar from "../../../../assets/images/app_req_calendar.png";
import AppReqTelehealth from "../../../../assets/images/app_req_telehealth.png";
import AppReq from "../../../../assets/images/appointment.png";
import { Button, Row, Data } from "./styles";
import { useDispatch } from "react-redux";
import {
  RowHead,
  DataDiv,
  Text,
  Modal,
  ModalContainer,
  AlertModalDialog,
  Image,
} from "../styles";
// import { Data } from "../chats/Style";
// import { Row } from "../Message/styles";
import axios from "axios";
import {
  Encrypt_Value,
  Decrypt_Value,
} from "../../../../MiddleWare/EncryptDecrypt";
import { useSelector } from "react-redux";
import { changeRefreshRequest } from "../../../../StateManagement/Reducers/ScheduleState";

if (localStorage.getItem("token") === "undefined undefined") {
  localStorage.clear();
  localStorage.setItem("logout", true);
}

const AppointmentRequest = (props) => {
  const [ModalAnimat] = useState(true);
  const [ActionButton, setActionButton] = useState("");
  const [AppointmentData, setAppointmentData] = useState(props.appData);
  const [AppReqDataCount, setAppReqDataCount] = useState("");
  const [AlertColor, setAlertColor] = useState("");
  const [ModalMsg, setModalMsg] = useState("");
  const [ModalAlerShow, setModalAlerShow] = useState(false);
  const [Telehealthplatform, setTelehealthplatform] = useState("zoom");

  var userID =
    localStorage.getItem("user_id") &&
    Decrypt_Value(localStorage.getItem("user_id"), "westside");
  let GMeetURL = "";
  let firstDot = window.location.hostname.split(".");
  if (firstDot[0] !== "localhost") {
    GMeetURL = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    GMeetURL = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }
  const dispatch = useDispatch();

  const ref = useRef([]);

  let history = useHistory();

  const user_id =
    localStorage.getItem("user_id") &&
    Decrypt_Value(localStorage.getItem("user_id"), "westside");

  const ActionClick = (ind, id) => {
    var lastindex = props.appData.length - 1;

    if (ActionButton === ind) {
      setActionButton("");
      return;
    } else {
      setActionButton(ind);
      if (lastindex > 1) {
        if (lastindex === ind) {
          const element = document.getElementById(id);
          setTimeout(
            () =>
              element.scrollIntoView({
                behavior: "smooth",
                block: "end",
              }),
            500
          );
        }
      }
      return;
    }
  };

  const addToRefs = (el) => {
    if (el && !ref.current.includes(el)) {
      if (el.id !== undefined) {
        ref.current.push(el);
      }
    }
  };

  const setAcceptDecline = (val, id, type, cid) => {
    setActionButton("");
    const article = {
      value: val,
      id: id,
      type: type,
    };
    let Data_enc = Encrypt_Value(article, "westside");
    axiosInstance
      .put("/westside/appointment/notification/update?uid=" + user_id, Data_enc)
      .then((response) => {
        let DecodeVal = Decrypt_Value(response.data, "westside");
        if (val === "1") {
          //for PROVIDER
          axiosInstance
            .get("/westside/EnableCalEvent/Data?eid=" + id + "&uid=" + userID)
            .then((response) => {
              let decodingResponse = Decrypt_Value(response.data, "westside");

              const googlecalendar = decodingResponse.cal_en_data.filter(
                (setting) =>
                  setting.setting_label === "Enable_calendar_Integration"
              );
              const outlookcalendar = decodingResponse.cal_en_data.filter(
                (setting) => setting.setting_label === "outlook_hotmail"
              );
              const icalcalendar = decodingResponse.cal_en_data.filter(
                (setting) => setting.setting_label === "ical_apple"
              );
              var DurationMinutes = Math.round(decodingResponse.Duration);
              var Durationwithtext = DurationMinutes + " Min In-Person ";
              let article = {
                start_time: decodingResponse.start_time,
                end_time: decodingResponse.end_time,
                attendees: decodingResponse.attendees,
                pc_eid: decodingResponse.pc_eid,
                meeting_id: decodingResponse.meeting_id,
                forText: Durationwithtext,
                repeatType: "",
                fromWhere: "Provider",
              };
              let article1 = {
                fromWhere: "Provider",
                meeting_id: decodingResponse.outlook_id,
              };

              if (outlookcalendar.length > 0) {
                if (outlookcalendar[0].setting_value === "1") {
                  if (
                    decodingResponse.outlook_id !== null &&
                    decodingResponse.outlook_id !== ""
                  ) {
                    axios
                      .get(
                        GMeetURL + "/interface/customized/outlook/delete.php",
                        {
                          params: article1,
                        }
                      )
                      .then((res) => {})
                      .catch((err) => {
                        console.log("failed");
                      });

                    axios
                      .get(
                        GMeetURL + "/interface/customized/outlook/create.php",
                        {
                          params: article,
                        }
                      )
                      .then((res) => {})
                      .catch((err) => {
                        console.log("failed");
                      });
                  } else {
                    axios
                      .get(
                        GMeetURL + "/interface/customized/outlook/create.php",
                        {
                          params: article,
                        }
                      )
                      .then((res) => {})
                      .catch((err) => {
                        console.log("failed");
                      });
                  }
                }
              }

              if (icalcalendar.length > 0) {
                if (icalcalendar[0].setting_value === "1") {
                  if (
                    decodingResponse.ical_id !== null &&
                    decodingResponse.ical_id !== ""
                  ) {
                    let article2 = {
                      start_time: decodingResponse.start_time,
                      end_time: decodingResponse.end_time,
                      attendees: decodingResponse.attendees,
                      pc_eid: decodingResponse.pc_eid,
                      meeting_id: decodingResponse.ical_id,
                      forText: Durationwithtext,
                      fromWhere: "Provider",
                      repeatType: "",
                    };
                    axios
                      .get(
                        GMeetURL +
                          "/interface/customized/iCloud/php_icloud_calendar/example.php?type=update",
                        {
                          params: article2,
                        }
                      )
                      .then((res) => {})
                      .catch((err) => {
                        console.log("failed");
                      });
                  } else {
                    axios
                      .get(
                        GMeetURL +
                          "/interface/customized/iCloud/php_icloud_calendar/example.php?type=create",
                        {
                          params: article,
                        }
                      )
                      .then((res) => {})
                      .catch((err) => {
                        console.log("failed");
                      });
                  }
                }
              }
              if (googlecalendar.length > 0) {
                if (googlecalendar[0].setting_value === "1") {
                  if (
                    decodingResponse.meeting_id !== null &&
                    decodingResponse.meeting_id !== ""
                  ) {
                    axios
                      .get(
                        GMeetURL +
                          "/interface/customized/g-meet/index.php?action=update",
                        { params: article }
                      )
                      .then((res) => {})
                      .catch((err) => {
                        console.log("failed");
                      });
                  } else {
                    axios
                      .get(
                        GMeetURL +
                          "/interface/customized/g-meet/index.php?action=create",
                        { params: article }
                      )
                      .then((res) => {})
                      .catch((err) => {
                        console.log("failed");
                      });
                  }
                }
              }
            })
            .catch((err) => {
              console.error(err.message);
            });
          //for PROVIDER

          //for PATIENT

          axiosInstance
            .get("/westside/EnableCalEvent/Data/patient?eid=" + id)
            .then((response) => {
              let decodingResponse = Decrypt_Value(response.data, "westside");

              var DurationMinutes = Math.round(decodingResponse.Duration);
              var Durationwithtext = DurationMinutes + " Min In-Person ";

              const events = decodingResponse.create_event
                ? decodingResponse.create_event.split(",")
                : "";
              let article = {
                start_time: decodingResponse.start_time,
                end_time: decodingResponse.end_time,
                attendees: decodingResponse.attendees,
                pc_eid: id,
                repeatType: "",
                fromWhere: "Patient",
                meeting_id: decodingResponse.meeting_id,
                forText: Durationwithtext,
                onDate: "",
              };
              let article2 = {
                start_time: decodingResponse.start_time,
                end_time: decodingResponse.end_time,
                attendees: decodingResponse.attendees,
                pc_eid: id,
                meeting_id: decodingResponse.ical_id,
                repeatType: "",
                fromWhere: "Patient",
                forText: Durationwithtext,
                onDate: "",
              };

              if (events.includes("Goo")) {
                if (
                  decodingResponse.meeting_id !== null &&
                  decodingResponse.meeting_id !== ""
                ) {
                  axios
                    .get(
                      GMeetURL +
                        "/interface/customized/g-meet/index.php?action=update",
                      { params: article }
                    )
                    .then((res) => {})
                    .catch((err) => {
                      console.log("failed");
                    });
                } else {
                  axios
                    .get(
                      GMeetURL +
                        "/interface/customized/g-meet/index.php?action=create",
                      { params: article }
                    )
                    .then((res) => {})
                    .catch((err) => {
                      console.log("failed");
                    });
                }
              }

              if (events.includes("Out")) {
                if (
                  decodingResponse.outlook_id !== null &&
                  decodingResponse.outlook_id !== ""
                ) {
                  let article1 = {
                    fromWhere: "Patient",
                    meeting_id: decodingResponse.outlook_id,
                  };
                  axios
                    .get(
                      GMeetURL + "/interface/customized/outlook/delete.php",
                      {
                        params: article1,
                      }
                    )
                    .then((res) => {})
                    .catch((err) => {
                      console.log("failed");
                    });
                }
                axios
                  .get(GMeetURL + "/interface/customized/outlook/create.php", {
                    params: article,
                  })
                  .then((res) => {})
                  .catch((err) => {
                    console.log("failed");
                  });
              }

              if (events.includes("Ical")) {
                if (
                  decodingResponse.ical_id !== null &&
                  decodingResponse.ical_id !== ""
                ) {
                  axios
                    .get(
                      GMeetURL +
                        "/interface/customized/iCloud/php_icloud_calendar/example.php?type=update",
                      {
                        params: article2,
                      }
                    )
                    .then((res) => {})
                    .catch((err) => {
                      console.log("failed");
                    });
                } else {
                  axios
                    .get(
                      GMeetURL +
                        "/interface/customized/iCloud/php_icloud_calendar/example.php?type=create",
                      {
                        params: article,
                      }
                    )
                    .then((res) => {})
                    .catch((err) => {
                      console.log("failed");
                    });
                }
              }
            });
          //for PATIENT
          if (cid === "16") {
            if (Telehealthplatform === "GMeet") {
              axiosInstance
                .get("/westside/getGMeet/data?eid=" + id + "&from_where=portal")
                .then((response) => {
                  var res = Decrypt_Value(response.data, "westside");

                  let article = {
                    start_time: res["start_time"],
                    end_time: res["end_time"],
                    attendees: res["attendees"],
                    start: res["pc_startTime"],
                    end: res["pc_endTime"],
                    eventdate: res["pc_eventDate"],
                    eid: res["pc_eid"],
                    aid: res["pc_aid"],
                    pid: res["pc_pid"],
                    fac_id: res["pc_facility"],
                  };

                  axios
                    .get(
                      GMeetURL +
                        "/interface/customized/g-meet/index.php?action=create",
                      { params: article }
                    )
                    .then((res) => {
                      axiosInstance
                        .get(
                          "/westside/appointment/teleaccept?id=" +
                            id +
                            "meet_type=" +
                            Telehealthplatform
                        )
                        .then((response) => {
                          setAlertColor("green");
                          setModalAlerShow(true);
                          setModalMsg(
                            "Telehealth Appointment Accepted Successfully"
                          );
                          setTimeout(() => setModalAlerShow(false), 2000);
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    })

                    .catch((err) => {
                      console.log("failed");
                    });
                })
                .catch((err) => {
                  console.error(err);
                });
            } else {
              axiosInstance
                .get("/westside/appointment/teleaccept?id=" + id)
                .then((response) => {
                  setAlertColor("green");
                  setModalAlerShow(true);
                  setModalMsg("Telehealth Appointment Accepted Successfully");
                  setTimeout(() => setModalAlerShow(false), 2000);
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          } else {
            setAlertColor("green");
            setModalAlerShow(true);
            setModalMsg("Appointment Accepted Successfully");
            setTimeout(() => setModalAlerShow(false), 2000);
            axiosInstance
              .get("/westside/appointment/accept?id=" + id)
              .then((response) => {})
              .catch((err) => {
                console.log(err);
              });
          }
        }
        if (val === "2") {
          // for provider
          axiosInstance
            .get(
              "/westside/EnableCalEvent/Data?meet_id=true&eid=" +
                id +
                "&uid=" +
                userID
            )
            .then((response) => {
              let decodingResponse = Decrypt_Value(response.data, "westside");

              if (
                decodingResponse.meeting_id !== null &&
                decodingResponse.meeting_id !== ""
              ) {
                let article = {
                  fromWhere: "Provider",
                  meeting_id: decodingResponse.meeting_id,
                };

                axios
                  .get(
                    GMeetURL +
                      "/interface/customized/g-meet/index.php?action=delete",
                    { params: article }
                  )
                  .then((res) => {})
                  .catch((err) => {
                    console.log("failed");
                  });
              }
              if (
                decodingResponse.ical_id !== null &&
                decodingResponse.ical_id !== ""
              ) {
                let article = {
                  fromWhere: "Provider",
                  meeting_id: decodingResponse.ical_id,
                };

                axios
                  .get(
                    GMeetURL +
                      "/interface/customized/iCloud/php_icloud_calendar/example.php?type=delete",
                    { params: article }
                  )
                  .then((res) => {})
                  .catch((err) => {
                    console.log("failed");
                  });
              }
              if (
                decodingResponse.outlook_id !== null &&
                decodingResponse.outlook_id !== ""
              ) {
                let article1 = {
                  fromWhere: "Provider",
                  meeting_id: decodingResponse.outlook_id,
                };
                axios
                  .get(GMeetURL + "/interface/customized/outlook/delete.php", {
                    params: article1,
                  })
                  .then((res) => {})
                  .catch((err) => {
                    console.log("failed");
                  });
              }
            });

          // for provider

          //for patient
          axiosInstance
            .get("/westside/EnableCalEvent/Data/patient?eid=" + id)
            .then((response) => {
              let decodingResponse = Decrypt_Value(response.data, "westside");

              if (
                decodingResponse.meeting_id !== null &&
                decodingResponse.meeting_id !== ""
              ) {
                let article = {
                  fromWhere: "Patient",
                  meeting_id: decodingResponse.meeting_id,
                };

                axios
                  .get(
                    GMeetURL +
                      "/interface/customized/g-meet/index.php?action=delete",
                    { params: article }
                  )
                  .then((res) => {})
                  .catch((err) => {
                    console.log("failed");
                  });
              }

              if (
                decodingResponse.outlook_id !== null &&
                decodingResponse.outlook_id !== ""
              ) {
                let article1 = {
                  fromWhere: "Patient",
                  meeting_id: decodingResponse.outlook_id,
                };
                axios
                  .get(GMeetURL + "/interface/customized/outlook/delete.php", {
                    params: article1,
                  })
                  .then((res) => {})
                  .catch((err) => {
                    console.log("failed");
                  });
              }

              if (
                decodingResponse.ical_id !== null &&
                decodingResponse.ical_id !== ""
              ) {
                let article = {
                  fromWhere: "Patient",
                  meeting_id: decodingResponse.ical_id,
                };

                axios
                  .get(
                    GMeetURL +
                      "/interface/customized/iCloud/php_icloud_calendar/example.php?type=delete",
                    { params: article }
                  )
                  .then((res) => {})
                  .catch((err) => {
                    console.log("failed");
                  });
              }
            });
          // for patient
          setAlertColor("red");
          setModalAlerShow(true);
          setModalMsg("Appointment Declined Successfully");
          setTimeout(() => setModalAlerShow(false), 2000);
          axiosInstance
            .get("/westside/appointment/decline?id=" + id)
            .then((response) => {})
            .catch((err) => {
              console.log(err);
            });
        }
        axiosInstance
          .get("/westside/appointment/notification?uid=" + user_id)
          .then((response) => {
            let DecodeVal = Decrypt_Value(response.data, "westside");
            dispatch(changeRefreshRequest(true));
            setAppointmentData(DecodeVal);
            props.success(DecodeVal);
            props.successreload(DecodeVal);
          })
          .catch((err) => {
            console.log(err);
          });

        props.calRefHandle();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    var active = 0;
    active = AppointmentData.filter(
      (item) => item.pc_eventDate === moment().format("YYYY-MM-DD")
    );

    setAppReqDataCount(active.length);
  }, [AppointmentData]);

  useEffect(() => {
    if (props.show && props.refid !== "") {
      for (var i = 0; i < ref.current.length; i++) {
        if (ref.current[i].id == props.refid) {
          ActionClick(i, props.refid);
          break;
        }
      }
    } else {
      setActionButton("");
      let a = [];
      ref.current.push(a);
    }
  }, [props.refid, props.show]);

  useEffect(() => {
    axiosInstance
      .get("/westside/telehealth/getPlatform")
      .then((response) => {
        let decodingResponse = Decrypt_Value(response.data, "westside");
        setTelehealthplatform(decodingResponse);
      })
      .catch((err) => {
        setTelehealthplatform("zoom");
      });
  }, []);
  return (
    <>
      <Row
        boxShadow="5px 5px 8px rgba(20, 46, 110, 0.15)"
        radius="8px"
        border=" 1px solid #cbd5e0"
        margin="8px 8px 22px 8px "
      >
        <Row borderBottom="1px solid #cbd5e0" padding="12px 16px 12px 16px">
          <RowHead>
            Today
            <DataDiv
              ml="8px"
              minWidth="16px"
              justifyContent="center"
              display="flex"
              aspectRatio="1/1"
              MaxWidth="24px"
              background="#2C7BE5"
              br="50px"
            >
              <Text
                textAlign="center"
                fontFamily="Inter"
                fontWeight="500"
                fontSize="12px"
                color="#ffffff"
                lineHeight="15px"
                height="100%"
                display="flex"
              >
                {AppReqDataCount}
              </Text>
            </DataDiv>
          </RowHead>
        </Row>
        <Data height="336px" width="100%">
          {AppointmentData.map((row, ind) => (
            <DataDiv
              id={row.pc_eid}
              padding="12px 12px 12px 16px"
              bb="1px solid #cbd5e0"
              cursor="pointer"
              onClick={(e) => ActionClick(ind, row.pc_eid)}
              ref={addToRefs}
              key={ind}
            >
              <DataDiv display="flex" mb="12px">
                <Image
                  height="24px"
                  width="24px"
                  mr="12px"
                  src={AppReqCalendar}
                ></Image>
                <Text
                  display="flex"
                  fontFamily="Inter"
                  fontWeight="400"
                  fontSize="16px"
                  color=" #263446"
                  mr="8px"
                  lineHeight="19px"
                >
                  {row.patient_name}
                </Text>
                <Text
                  display="flex"
                  fontFamily="Inter"
                  fontWeight="400"
                  fontSize="14px"
                  color="#718096"
                  lineHeight="17px"
                >
                  (age {row.age})
                </Text>
              </DataDiv>
              <DataDiv display="flex" ml="36px" mb="12px">
                <Text
                  display="flex"
                  fontFamily="Inter"
                  fontWeight="500"
                  fontSize="15px"
                  color="#2C7BE5"
                  lineHeight="18px"
                  mr="5px"
                >
                  {moment(row.pc_eventDate).format("ddd,MMM,DD,YYYY")} .
                  {moment(row.pc_startTime, "h:mm A").format("LT")}
                </Text>
                <Text
                  display="flex"
                  fontFamily="Inter"
                  fontWeight="400"
                  fontSize="14px"
                  color="#718096"
                  lineHeight="17px"
                >
                  ({row.pc_duration} Min)
                </Text>
              </DataDiv>
              <DataDiv display="flex" ml="36px">
                {props.profileimage ? (
                  <>
                    <Image
                      src={props.profileimage}
                      borderRadius="40px"
                      height="24px"
                      width="24px"
                      mr="8px"
                    ></Image>
                  </>
                ) : (
                  <DataDiv
                    textAlign="center"
                    br="40px"
                    height="24px"
                    width="24px"
                    mr="8px"
                    background="#A8AEBE"
                  >
                    <Text
                      fontFamily="Inter"
                      fontWeight="500"
                      fontSize="14px"
                      color="#ffffff"
                      lineHeight="16px"
                      mt="4px !important"
                    >
                      {" "}
                      {row.prov_name_split}
                    </Text>
                  </DataDiv>
                )}
                <Text
                  display="flex"
                  fontFamily="Inter"
                  fontWeight="400"
                  fontSize="14px"
                  color="#263446"
                  lineHeight="20px"
                  mr="20px"
                >
                  {row.user_name}
                </Text>

                <Image
                  objectFit="none"
                  src={row.pc_catid === "16" ? AppReqTelehealth : AppReq}
                  height="24px"
                  mr="8px"
                ></Image>
                <Text
                  display="flex"
                  fontFamily="Inter"
                  fontWeight="500"
                  fontSize="14px"
                  color="#000000"
                  lineHeight="20px"
                >
                  {row.pc_catid === "16" ? "Telehealth" : "Appointment"}
                </Text>
              </DataDiv>

              {parseInt(ActionButton) === ind && (
                <DataDiv display="flex" ml="3px" mt="20px">
                  <Text
                    display="flex"
                    fontFamily="Inter"
                    fontWeight="400"
                    fontSize="11px"
                    color="#718096"
                    lineHeight="13px"
                    mr="8px"
                  >
                    {moment(row.pc_time).fromNow() + " "}
                    From Patient portal
                  </Text>
                  <Button
                    height="32px"
                    width="82px"
                    border="1px solid #CBD5E0"
                    br="4px"
                    mr="12px"
                    background="none"
                    color="#718096"
                    onClick={() =>
                      setAcceptDecline(
                        "2",
                        row.pc_eid,
                        row.pc_title,
                        row.pc_catid
                      )
                    }
                  >
                    Decline
                  </Button>
                  <Button
                    border="1px solid #2c7be5"
                    height="32px"
                    width="82px"
                    br="4px"
                    bc="#2C7BE5"
                    color="#FFFFFF"
                    onClick={() =>
                      setAcceptDecline(
                        "1",
                        row.pc_eid,
                        row.pc_title,
                        row.pc_catid
                      )
                    }
                  >
                    Accept
                  </Button>
                </DataDiv>
              )}
            </DataDiv>
          ))}
        </Data>
      </Row>
      <Modal show={ModalAlerShow}>
        <ModalContainer justifyContent="center">
          <AlertModalDialog color={AlertColor} animation={ModalAnimat}>
            <BsCheckCircle />
            {ModalMsg}
          </AlertModalDialog>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default AppointmentRequest;

import React, { useState, useEffect, memo } from "react";
import AddProblem from "../popups/AddProblem";
import axiosInstance from "../../axios/axios";
import Emp_data_pic from "../../assets/images/Emp_data_pic.png";
import { Button, Modal } from "react-bootstrap";
import { BsPencilSquare } from "react-icons/bs";
import { IoCloseSharp, RiDeleteBin5Line } from "react-icons/all";
import { useParams } from "react-router-dom";
import { BsCheckCircle } from "react-icons/bs";
import { Image } from "react-bootstrap";
import Loader from "../popups/Loading";
import {
  Table,
  TableHeadCell,
  TableBody,
  TableBodyCell,
  TableHead,
} from "../setting/PatientPortalSettings/StyledComponents";
import { Tablerow } from "../patient/StyledComponent";
import moment from "moment";
var CryptoJS = require("crypto-js");

const Encode_Decode_JSON = {
  stringify: function (DecodingVal) {
    var Data_Val = {
      Encode: DecodingVal.ciphertext.toString(CryptoJS.enc.Base64),
    };
    if (DecodingVal.iv) Data_Val.Decentral = DecodingVal.iv.toString();
    if (DecodingVal.salt) Data_Val.EndCode = DecodingVal.salt.toString();
    return JSON.stringify(Data_Val);
  },
  parse: function (StrVal) {
    var Data_Val = JSON.parse(StrVal);
    var DecodingVal = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(Data_Val.Encode),
    });
    if (Data_Val.Decentral)
      DecodingVal.Decentral = CryptoJS.enc.Hex.parse(Data_Val.Decentral);
    if (Data_Val.EndCode)
      DecodingVal.salt = CryptoJS.enc.Hex.parse(Data_Val.EndCode);

    return DecodingVal;
  },
};

const Decrypt_Value = (data, key) => {
  return JSON.parse(
    CryptoJS.AES.decrypt(data, key, {
      format: Encode_Decode_JSON,
    }).toString(CryptoJS.enc.Utf8)
  );
};

const Encrypt_Value = (Val, key) => {
  return CryptoJS.AES.encrypt(JSON.stringify(Val), key, {
    format: Encode_Decode_JSON,
  }).toString();
};

const ProblemsTable = () => {
  //------------------------------------------State and Variables Start------------------------------------------//

  var params = useParams();

  const [show, setShow] = useState(false);
  const [ModalAlerShow, setModalAlerShow] = useState(false);
  const [ModalMsg, setModalMsg] = useState("");
  const [mainrows, setmainfutureId] = useState([]);
  const [showLoading, setShowLoading] = useState("block");
  const [load_flag, setload_flag] = useState(0);
  const [id, setid] = useState(0);
  const [spinner, setspinner] = useState(true);
  const [rows, setfutureId] = useState([]);
  const [singleRow, setSingleRow] = useState([]);
  const [disable, setDisable] = useState(true);
  const [formAlertModal, setFormAlertModal] = useState(false);

  //------------------------------------------State and Variables End--------------------------------------

  //------------------------------------------Function Start--------------------------------------
  const handleShow = () => {
    setShow(true);
    setid(0);
    setDisable(true);
  };

  const handleShowEdit = (id, form_id) => {
    if (form_id === 17 || form_id === "17") {
      setFormAlertModal(true);
      setTimeout(() => {
        setFormAlertModal(false);
      }, 2000);
      return;
    }
    setid(id);
    setDisable(false);
    axiosInstance
      .get(
        "/westside/patient/medical_problem/Edit?pid=" + params.pid + "&id=" + id
      )
      .then((response) => {
        let decodingResponse = Decrypt_Value(response.data, "westside");
        setSingleRow(decodingResponse);
        setShow(true);
        refreshData();
      })
      .catch(() => setSingleRow([]));
  };

  const upDelete = (id, form_id) => {
    if (form_id === 17 || form_id === "17") {
      setFormAlertModal(true);
      setTimeout(() => {
        setFormAlertModal(false);
      }, 2000);
      return;
    }
    if (window.confirm("Are you sure you want to Delete?")) {
      axiosInstance
        .delete(
          "/westside/patient/medical_problem/Delete?pid=" +
            params.pid +
            "&id=" +
            id
        )
        .then(() => {
          setModalMsg("Problems deleted successfully");
          setModalAlerShow(true);
          setTimeout(() => setModalAlerShow(false), 1500);
          refreshData();
        });
    }
  };

  const clientClose = (showClient) => {
    setShow(showClient);
    if (id === 0) {
      setModalMsg("Problems added successfully");
      setModalAlerShow(true);
      setTimeout(() => setModalAlerShow(false), 1500);
    } else {
      setModalMsg("Problems updated successfully");
      setModalAlerShow(true);
      setTimeout(() => setModalAlerShow(false), 1500);
    }
  };

  const refreshData = () => {
    const tenPokemon = [];
    let currentData = "limit=40&offset=" + 0;
    let encryptedData = Encrypt_Value(currentData, "westside");
    axiosInstance
      .get(
        "/westside/patient/medical_problem?search=" +
          encryptedData +
          "&pid=" +
          params.pid
      )
      .then((response) => {
        let decodingResponse = Decrypt_Value(response.data, "westside");
        console.log(decodingResponse, "decodingResponse");
        decodingResponse.forEach((p) => tenPokemon.push(p));
        setmainfutureId(tenPokemon);
        let items = decodingResponse.slice(0, 15);
        setfutureId(items);
        setspinner(false);
        if (decodingResponse.length > 15) {
          setShowLoading("block");
        }
      })
      .catch(() => {
        setfutureId([]);
        setspinner(false);
      });
  };

  const firstloadTenPokemon = () => {
    const tenPokemon = [];
    let currentData = "limit=40&offset=" + mainrows.length;
    let encryptedData = Encrypt_Value(currentData, "westside");
    axiosInstance
      .get(
        "/westside/patient/medical_problem?search=" +
          encryptedData +
          "&pid=" +
          params.pid
      )
      .then((response) => {
        let decodingResponse = Decrypt_Value(response.data, "westside");
        console.log(decodingResponse, "decodingResponse1");
        decodingResponse.forEach((p) => tenPokemon.push(p));
        setmainfutureId((pokemon) => [...pokemon, ...tenPokemon]);
        let items = decodingResponse.slice(rows.length, 15);
        setfutureId((mitems) => [...mitems, ...items]);
        setspinner(false);
        if (decodingResponse.length > 15) {
          setShowLoading("block");
        } else {
          setShowLoading("none");
        }
      })
      .catch(() => {
        setfutureId([]);
        setspinner(false);
        setShowLoading("none");
      });
  };

  const loadTenPokemon = (e) => {
    setShowLoading("block");
    var data = mainrows.length;
    var final = mainrows;
    var rowscount = rows.length;
    var res = [];
    for (var i in final) {
      res.push(final[i]);
    }
    if (data === rowscount) {
      setShowLoading("none");
    }
    const tenPokemon1 = [];
    let currentData = "limit=40&offset=" + mainrows.length;
    let encryptedData = Encrypt_Value(currentData, "westside");
    if (rowscount >= data - 30 && load_flag === 0) {
      setload_flag(1);

      axiosInstance
        .get(
          "/westside/patient/medical_problem?search=" +
            encryptedData +
            "&pid=" +
            params.pid
        )
        .then((response) => {
          let decodingResponse = Decrypt_Value(response.data, "westside");
          decodingResponse.forEach((p) => tenPokemon1.push(p));
          setmainfutureId((pokemon) => [...pokemon, ...tenPokemon1]);
          setspinner(false);
        })
        .catch(() => {
          let tenPokemon1 = [];
          setmainfutureId((pokemon) => [...pokemon, ...tenPokemon1]);
        });
      const items = mainrows.slice(rowscount, 10);
      setfutureId((mitems) => [...mitems, ...items]);
    }
    if (rows.length <= mainrows.length) {
      var items = [];
      let s = parseInt(rowscount) + 10;
      for (i = rowscount; i <= s; i++) {
        if (res[i] !== undefined) {
          items.push(res[i]);
        }
      }
      setfutureId((mitems) => [...mitems, ...items]);
    }
  };

  const Status = (St) => {
    console.log(St, "sttt");
    if (St === "0") {
      return "Inactive";
    } else if (St === "1" || St === 1) {
      return "Active";
    } else {
      return "Resolved";
    }
  };

  const StatusDotClass = (StDotClass) => {
    if (StDotClass === "0") {
      return "dot_inactive_problems m-t--8";
    } else if (StDotClass === "1" || StDotClass === 1) {
      return "dot_active_problems m-t--8";
    } else {
      return "dot_resolved_problems m-t--9";
    }
  };

  const StatusClass = (StClass) => {
    if (StClass === "0") {
      return "for_dot_inact_bg  m-t-3";
    } else if (StClass === "1" || StClass === 1) {
      return "for_dot_act_bg  m-t-3";
    } else {
      return "for_dot_resolved_bg m-t-3";
    }
  };
  const StatusClassDiv = (StClass) => {
    if (StClass === "0") {
      return "inactive-outlined act_inact-outlined_alt p-t-5";
    } else if (StClass === "1" || StClass === 1) {
      return "active-outlined act_inact-outlined_alt p-t-5";
    } else {
      return "resolved-outlined act_inact-outlined_alt p-t-5";
    }
  };

  const modClose = () => setShow(false);

  const modClose1 = () => setModalAlerShow(false);

  //------------------------------------------Function End-----------------------------------------------//

  //------------------------------------------UseEffect Start-------------------------------------------//

  useEffect(() => {
    firstloadTenPokemon();
  }, []);

  //------------------------------------------UseEffect End--------------------------------------------//

  return (
    <div className="pat_top_title">
      {spinner ? (
        <Loader />
      ) : (
        <>
          <div className="tab_title_main medication-title">
            <div className="tab_title_name">Problems </div>
          </div>
          <div className="problemsBottom  tab_title_main medication-border page">
            <div id="problemsTableDiv" className="modif1 MGroup">
              <div className="problemsTableHeadSmall medication-small-heading ">
                Problems List
              </div>
              <div className="prob-head-right">
                <Button
                  variant="primary"
                  onClick={handleShow}
                  className="save_active create-appt add-med-btn edit-note"
                >
                  Add Problem
                </Button>
              </div>
            </div>
            <div className="pat_table_top medication-table-outer">
              <Table minWidth="650px">
                <TableHead
                  backgroundColor="#edf2f8"
                  height="56px"
                  display="table-header-group"
                  className="Tab_content_Table_Header patient-tables-border-top"
                >
                  <Tablerow
                    border="none"
                    fontWeight="400"
                    color="#005fbe"
                    className="schedule-head-row tr-no"
                  >
                    <TableHeadCell>Problem</TableHeadCell>
                    <TableHeadCell align="left">ICD Version</TableHeadCell>
                    <TableHeadCell align="left">ICD Dx Code</TableHeadCell>
                    <TableHeadCell align="left">Date Diagnosed</TableHeadCell>
                    <TableHeadCell align="left">Status</TableHeadCell>
                    <TableHeadCell align="left">Modify Date</TableHeadCell>
                    <TableHeadCell align="left">Snomed</TableHeadCell>
                    <TableHeadCell paddingRight="16px !important" align="left">
                      Action
                    </TableHeadCell>
                  </Tablerow>
                </TableHead>
                <TableBody display="table-row-group" className="Boreder_none">
                  {rows.length !== 0 ? (
                    <>
                      {rows.map((row, index) => (
                        <Tablerow
                          color="#636d73"
                          fontFamily=" Roboto, Helvetica,Arial, sans-serif "
                          borderBottom="1px solid #cbd5e0"
                          lineHeight="22.001px"
                          height="56px"
                          padding="6px 0 6px 18px"
                          key={index}
                          className="table-data-row schedule-body-row"
                        >
                          <TableBodyCell
                            scope="row"
                            lineHeight="20.02px"
                            width="25%"
                          >
                            <div className="schedule-pro-name problemTitle f-w-500">
                              {row.diagnosis
                                ? row.diagnosis.split("-")[2]
                                : null}
                            </div>
                          </TableBodyCell>
                          <TableBodyCell
                            align="left"
                            lineHeight="20.02px"
                            width="10%"
                          >
                            {row.diagnosis ? row.diagnosis.split(":")[0] : null}
                          </TableBodyCell>
                          <TableBodyCell
                            align="left"
                            lineHeight="20.02px"
                            width="10%"
                          >
                            {row.diagnosis
                              ? row.diagnosis
                                  .split(": ")[1]
                                  .substr(
                                    0,
                                    row.diagnosis.split(": ")[1].indexOf("--")
                                  )
                              : null}
                          </TableBodyCell>
                          <TableBodyCell
                            align="left"
                            lineHeight="20.02px"
                            width="10%"
                          >
                            {row.begdate
                              ? moment(row.begdate).format("MM-DD-YYYY")
                              : "-"}
                          </TableBodyCell>
                          <TableBodyCell
                            align="left"
                            className="eob_save "
                            lineHeight="20.02px"
                            width="10% !important"
                          >
                            <div className={StatusClassDiv(row.activity)}>
                              <div className={StatusClass(row.activity)}>
                                <span
                                  className={StatusDotClass(row.activity)}
                                ></span>
                              </div>
                              {Status(row.activity)}
                            </div>
                          </TableBodyCell>
                          <TableBodyCell
                            align="left"
                            lineHeight="20.02px"
                            width="10%"
                          >
                            {row.modifydate &&
                              moment(row.modifydate).format(
                                "MM-DD-YYYY hh:mm A"
                              )}
                          </TableBodyCell>
                          <TableBodyCell
                            align="left"
                            lineHeight="20.02px"
                            width="10%"
                          >
                            {row.snoomed_code ? row.snoomed_code : "-"}
                          </TableBodyCell>
                          <TableBodyCell
                            // paddingRight="16px !important"
                            // className="prb_pen"
                            align="left"
                            lineHeight="20.02px"
                            width="13.28%"
                          >
                            <Button
                              variant="light"
                              className="m-r-10"
                              onClick={() =>
                                handleShowEdit(row.id, row.form_id)
                              }
                            >
                              <BsPencilSquare />
                            </Button>
                            <Button
                              variant="light"
                              className="m-l-10"
                              onClick={() => upDelete(row.id, row.form_id)}
                            >
                              <RiDeleteBin5Line />
                            </Button>
                          </TableBodyCell>
                        </Tablerow>
                      ))}
                    </>
                  ) : (
                    <>
                      <Tablerow className="bot_border">
                        <TableBodyCell className="lab_img" colSpan={8}>
                          <div className="img_top">
                            <Image
                              className="img_emp_data"
                              src={Emp_data_pic}
                            ></Image>
                          </div>
                        </TableBodyCell>
                      </Tablerow>
                    </>
                  )}
                </TableBody>
              </Table>

              <Modal
                className="client-popup problem-pat-add patient-pop popup_centre"
                centered
                show={show}
                onHide={modClose}
              >
                <AddProblem
                  clientShow={clientClose}
                  close={modClose}
                  dataFetch={refreshData}
                  id={id}
                  row={singleRow}
                  data={disable}
                />
              </Modal>

              <Modal
                className="alert-popup-message"
                show={ModalAlerShow}
                onHide={modClose1}
              >
                <div className="alert msg alert-success" role="alert">
                  <BsCheckCircle className="icons-bs" />
                  {ModalMsg}
                </div>
              </Modal>

              <Modal
                className="alert-popup-message"
                show={formAlertModal}
                onHide={modClose1}
              >
                <div className="alert msg alert-danger" role="alert">
                  <IoCloseSharp className="icons-bs up-d-mod-mb" />
                  Cannot edit problems added from diagnosis code in note taking.
                </div>
              </Modal>
            </div>
          </div>

          {rows.length !== 0 && (
            <div
              className="problemsLoading loading-more appDash-loading"
              style={{ display: showLoading }}
            >
              <Button
                id="load-data"
                style={{ display: showLoading }}
                variant="primary"
                className="loading-more-div"
                onClick={loadTenPokemon}
              >
                Load more...
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};
export default memo(ProblemsTable);

import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  memo,
} from "react";
import { Image } from "react-bootstrap";
import { useParams } from "react-router-dom";
import axiosInstance from "../../axios/axios";
import { Decrypt_Value } from "../../MiddleWare/EncryptDecrypt";

const DemographicsPatientDetail = (props, ref) => {
  //------------------------------------------State and Variables Start------------------------------------------//

  const [rows, setfutureId] = useState([]);
  const [demoini, setdemoini] = useState(props.demostat);
  const params = useParams();

  let finalurl = "";
  let firstDot = window.location.hostname.split(".");
  if (firstDot[0] !== "localhost") {
    finalurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    finalurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }

  let DBHost = "default";

  var testurl = finalurl + "/sites/" + DBHost + "/documentimg/";

  //------------------------------------------State and Variables End------------------------------------------//

  //------------------------------------------Functions Start------------------------------------------//

  useImperativeHandle(ref, () => ({
    demographicdetailchange() {
      setdemoini(!demoini);
    },
  }));

  //------------------------------------------Functions End------------------------------------------//

  //------------------------------------------useEffect Start------------------------------------------//

  useEffect(() => {
    axiosInstance
      .get("/westside/get/single_patient?pid=" + params.pid)
      .then((response) => {
        let DecodingVal = Decrypt_Value(response.data, "westside");
        setfutureId([DecodingVal]);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [demoini]);

  //------------------------------------------useEffect End------------------------------------------//

  return (
    <>
      {rows.map((row, index) => (
        <div key={index} className="d-flex patient-pro-data-demo">
          {row.img_url !== null ? (
            <Image
              height="80px"
              width="80px"
              src={testurl + row.img_url}
              alt="pic"
              loading="lazy"
              style={{ borderRadius: "8px" }}
            />
          ) : (
            <div
              className="patname_first patname_dashboard"
              style={{
                width: 80,
                height: 80,
                borderRadius: "8px",
                background: "#A8AEBE",
              }}
            >
              {row.fname.charAt(0).toUpperCase()}
            </div>
          )}
          <div className="pat-data-left">
            <div className="text-left pat-name pad-bot-8">
              {row.fname}, {row.lname}
              {row.age !== "" && row.age !== null && row.age !== undefined ? (
                <span> ({row.age}) Yrs </span>
              ) : (
                <></>
              )}
              <span>{row.sex}</span>
            </div>
            <div className="text-left pad-bot-8 pat-details">
              Patient ID <span className="other-details">{row.pid}</span>
            </div>
          </div>
          <div className="pat-demo-data-left">
            <div className="text-left pad-bot-8 pat-details m-t-33 d-flex">
              <div>Phone</div>
              <span className="other-details">{row.phone_cell}</span>
            </div>
            <div className="text-left pad-bot-8 pat-details d-flex">
              <div>Email ID </div>
              <div className="other-details">{row.email}</div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default memo(forwardRef(DemographicsPatientDetail));

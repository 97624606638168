import React from "react";
import { SubNavItem, ListItem, UL, Div } from "./styles";

const ReportNav = () => {
  return (
    <Div width="100%" paddingLeft="0.86em">
      <UL
        textAlign="left"
        width="100%"
        listStyleType="none"
        padding="0"
        margin="0"
        position="relative"
      >
        <ListItem padding="8px" margin="8px 0 0 0">
          <SubNavItem to="/report/patientReport" exact>
            Patient Report
          </SubNavItem>
        </ListItem>
        <ListItem padding="8px" margin="0">
          <SubNavItem to="/report/appointments" exact id="rpt_nav-lnk_apmt">
            Appointment Report
          </SubNavItem>
        </ListItem>
        <ListItem padding="8px" margin="0">
          <SubNavItem to="/report/user" exact id="rpt_nav-lnk_usrRpt">
            User Report
          </SubNavItem>
        </ListItem>
        <ListItem padding="8px" margin="0">
          <SubNavItem
            to="/report/ProductivityReport"
            exact
            id="rpt_nav-lnk_producRpt"
          >
            Productivity Report
          </SubNavItem>
        </ListItem>
        <ListItem padding="8px" margin="0">
          <SubNavItem
            to="/report/card_transactions"
            exact
            id="rpt_nav-lnk_usrRpt"
          >
            Card Transactions
          </SubNavItem>
        </ListItem>
      </UL>
    </Div>
  );
};

export default ReportNav;

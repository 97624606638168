import React, { memo, useEffect, useState, useRef, useCallback } from "react";

import {
  ExitFullscreen,
  Fullscreen,
  ImageClose,
  MainPlayerContaier,
  PalyerContainer,
  Pause,
  Play,
  PlayerControl,
  PlayerVideo,
  PlayPause,
  RangeSlider,
  TimeContainer,
  TyperInput,
  FlexCenter,
} from "./style";

const VideoView = ({ url, myState, setMyState }) => {
  const ref = useRef();
  const FullscreenRef = useRef();
  const [durration, setDuration] = useState({ minute: 0, second: 0 });
  const [currenttime, setCurrentTime] = useState({ minute: 0, second: 0 });
  const [progress, setProgress] = useState(0);
  const [isMouseDown, setisMouseDown] = useState(false);
  const [icon, setIcon] = useState(true);
  const [fullviewIS, setSetFullViewIS] = useState(false);

  const togglePlay = () => {
    if (ref.current.paused) {
      ref.current.play();
      setIcon(false);
    } else {
      ref.current.pause();
      setIcon(true);
      let control = document.getElementById("control").style;
      control.bottom = "25px";
    }
  };

  const handleProgress = () => {
    const percent = (ref.current.currentTime / ref.current.duration) * 100;
    setCurrentTime({
      minute: parseInt(ref.current.currentTime / 60),
      second: parseInt(ref.current.currentTime % 60),
    });
    setDuration({
      minute: parseInt(ref.current.duration / 60),
      second: parseInt(ref.current.duration % 60),
    });
    setProgress(`${percent}`);
  };

  const startMouseDown = () => {
    setisMouseDown(true);
  };

  const endMouseDown = () => {
    setisMouseDown(false);
  };

  const scrub = (e) => {
    const scrubTime =
      (e.nativeEvent.offsetX / ref.current.clientWidth) * ref.current.duration;
    if (!isNaN(scrubTime)) {
      ref.current.currentTime = scrubTime;
    }
    let control = document.getElementById("control").style;
    control.bottom = "25px";
  };

  const handleFullScreen = () => {
    setSetFullViewIS(true);
    FullscreenRef.current.requestFullscreen();
  };

  const handleExitFullScreen = () => {
    document.exitFullscreen();

    setSetFullViewIS(false);
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      document.getElementById("control").style.bottom = "25px";
      if (document.getElementById("playpause")) {
        document.getElementById("playpause").style.display = "block";
      }
      document.getElementById("close_id").style.display = "block";
      document.getElementById("video").style.cursor = "auto";
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 1000);
    };
  };

  const handleChange = () => {
    if (document.getElementById("control")) {
      document.getElementById("control").style.bottom = "-35px";
    }
    if (document.getElementById("playpause")) {
      document.getElementById("playpause").style.display = "none";
    }
    if (document.getElementById("close_id")) {
      document.getElementById("close_id").style.display = "none";
    }
    if (document.getElementById("video")) {
      document.getElementById("video").style.cursor = "none";
    }
  };

  const optimizedFn = useCallback(debounce(handleChange), []);
  useEffect(() => {
    if (ref?.current?.duration) {
      setDuration({
        minute: parseInt(ref.current.duration / 60),

        second: parseInt(ref.current.duration % 60),
      });
    }
    return () => {
      setIcon(true);
      setProgress(0);
      setCurrentTime({ minute: 0, second: 0 });
      setDuration({ minute: 0, second: 0 });
      setSetFullViewIS(false);
    };
  }, [ref?.current?.duration]);

  return (
    myState.showVideo && (
      <>
        <MainPlayerContaier>
          <PalyerContainer onMouseMove={optimizedFn} ref={FullscreenRef}>
            <ImageClose
              id="close_id"
              onClick={() => setMyState({ ...myState, show: false })}
            />

            {icon === true ? (
              <Play onClick={togglePlay} />
            ) : (
              <PlayPause id="playpause">
                {icon === false && <Pause onClick={togglePlay} />}
              </PlayPause>
            )}

            <PlayerVideo
              id="video"
              ref={ref}
              src={url}
              onClick={togglePlay}
              onTimeUpdate={handleProgress}
              onLoadedMetadata={handleProgress}
            />
            <PlayerControl id="control" playIs={icon}>
              <TimeContainer>
                <TyperInput
                  value={
                    String(currenttime.minute).padStart(2, "0") +
                    " : " +
                    String(currenttime.second).padStart(2, "0")
                  }
                  readOnly
                />
                <FlexCenter>
                  <TyperInput
                    value={
                      String(durration.minute).padStart(2, "0") +
                      " : " +
                      String(durration.second).padStart(2, "0")
                    }
                    readOnly
                  />

                  {fullviewIS ? (
                    <ExitFullscreen onClick={() => handleExitFullScreen()} />
                  ) : (
                    <Fullscreen onClick={() => handleFullScreen()} />
                  )}
                </FlexCenter>
              </TimeContainer>
              <RangeSlider
                onMouseDown={startMouseDown}
                onMouseUp={endMouseDown}
                onMouseLeave={endMouseDown}
                onMouseMove={(e) => isMouseDown && scrub(e)}
                onChange={scrub}
                value={progress}
              />
            </PlayerControl>
          </PalyerContainer>
        </MainPlayerContaier>
      </>
    )
  );
};

export default memo(VideoView);

import React, { memo, useState } from "react";
import { Div, Text } from "../../../../StyledComponents";
import {
  Image,
  Span,
} from "../../../setting/PatientPortalSettings/StyledComponents";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import docuIcon from "../../../../assets/images/document_notify_icon.png";
import demoIcon from "../../../../assets/images/demo_notify_icon.png";
import reportIcon from "../../../../assets/images/report_notify_icon.png";

import { changeActiveNotificationLoad } from "../../../../StateManagement/Reducers/TopNavState";

const Notification = ({ dropChange, count }) => {
  const navigate = useHistory();
  const dispatch = useDispatch();

  const NotificationList = [
    {
      icon: docuIcon,
      title: "Documents Uploaded",
      count: "doc_count",
      descp: "Review the documents uploaded by patients in patient portal",
    },
    {
      icon: demoIcon,
      title: "Patient Demographics",
      count: "demo_count",
      descp:
        "Patients requested to change their demographics in patient portal",
    },
    {
      icon: reportIcon,
      title: "Consent Form - Signed by Patients",
      count: "consent_count",
      descp: "Consent form which was sent for review, reviewed & signed",
    },
  ];

  const redirect = (name) => {
    navigate.push("/patient/activityTable");

    dispatch(changeActiveNotificationLoad());
    dropChange();
  };

  return (
    <>
      {NotificationList.map((x, i) => (
        <Div
          key={i}
          display="flex"
          alignItem="center"
          padding="12px 16px"
          backcolor="rgba(64, 123, 255, 0.1)"
          borderBottom="1px solid rgba(46, 46, 46, 0.25)"
          margin={i === 3 && "0 0 40px"}
          onClick={() => redirect(x.count)}
          cursor="pointer"
        >
          <Image height="40px" width="40px" src={x.icon} alt="icon"></Image>
          <Div paddingLeft="12px" backColor="none">
            <Text
              color="#2E2E2E"
              featureSettings=" 'clig' off, 'liga' off"
              fontFamily="Open Sans"
              fontSize="14px"
              display="flex"
              alignItem="center"
              fontStyle="normal"
              fontWeight="600"
              padding="0 0 4px"
              margin="0"
            >
              {x.title}
              {Object.entries(count).length > 0 && count[x.count] !== null && (
                <Span
                  textAlign="center"
                  fontFamily="Inter"
                  fontWeight="500"
                  fontSize="12px"
                  color="#ffffff"
                  lineHeight="15px"
                  margin="0 0 0 6px"
                  width="16px"
                  height="16px"
                  borderRadius="50%"
                  background="#407BFF"
                  size="10px"
                  flexCenterAll
                >
                  {count[x.count]}
                </Span>
              )}
            </Text>
            <Text
              color="rgba(46, 46, 46, 0.50)"
              fontFamily="Open Sans"
              fontSize="12px"
            >
              {x.descp}
            </Text>
          </Div>
        </Div>
      ))}
    </>
  );
};

export default memo(Notification);

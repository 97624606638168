import React, { useState, useEffect, memo } from "react";
import { Input, Checkbox, Select } from "antd";
import { Image, Button, Modal, Form, Col } from "react-bootstrap";
import { AiOutlineDownload } from "react-icons/ai";
import { BsXCircle } from "react-icons/bs";
import { BsFileEarmarkText, BsGrid, BsListUl } from "react-icons/bs";
import { BsCheckCircle } from "react-icons/bs";
import { Avatar as Avatar1 } from "antd";
import { MdClose } from "react-icons/md";
import {
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from "../setting/PatientPortalSettings/StyledComponents";
import axiosInstance from "../../axios/axios";
import Emp_data_pic from "../../assets/images/Emp_data_pic.png";
import userpic1 from "../../assets/images/285645_user_icon.png";
import pdftitle from "../../assets/images/pdf.svg";
import filetitle from "../../assets/images/file.svg";
import imagetitle from "../../assets/images/image.svg";
import PlayerIcon from "../../assets/images/Group_2552.png";
import video1 from "../../assets/videos/Patient Education.mp4";
import Loader from "../popups/Loading";
import { FiShare2 } from "react-icons/fi";
import { FiSearch } from "react-icons/fi";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Encrypt_Value, Decrypt_Value } from "../../MiddleWare/EncryptDecrypt";
import AssignPatient from "./AssignToPatient";
import DocumentView from "../../Modules/PatientEducationModal/DocumentView";
import VideoView from "../../Modules/PatientEducationModal/VideoView";
import {
  Div1,
  Button1,
  P,
} from "../setting/PatientPortalSettings/StyledComponents";

import { Outer, Inner } from "../../StyledComponents";
import {
  Images,
  Inputstyled,
  AvatarLetterstyled,
} from "./AssignToPatient/style";
import { Avatar, AvatarLetter } from "../top_navbar/Notifications/styles";
import VideoIcon from "../../assets/images/VideoResourceIcon.png";
import { Dropdown, DropdownItems } from "../billing/CardTransactions/styles";
import { DataScroll } from "./AssignToPatient/style";
import CustomSuffixIcon from "../popups/CustomSuffixIcon";

const PatientEducationTable = () => {
  // ------------------------------ State and Variables Start--------------------------------------------------- //

  var DBHost = Decrypt_Value(localStorage.getItem("DBHost"), "westside");
  let finalurl = "";
  let firstDot = window.location.hostname.split(".");
  if (firstDot[0] !== "localhost") {
    finalurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    finalurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }

  DBHost = "default";

  const testurl_user = finalurl + "/sites/" + DBHost + "/documentimg/";
  const { Option } = Select;

  const testurl = finalurl + "/sites/" + DBHost + "/Education_Files/";
  const [state, setState] = useState({ showHelp: false });
  const [show, setShow] = useState(false);

  const [geteducation_, setgeteducation_] = useState([]);
  const [education, geteducation] = useState([]);
  const [btndisabled, setbtndisabled] = useState(false);
  const [countlimitfordemo, setcountlimitfordemo] = useState(false);

  const [privatecheck, setprivatecheck] = useState(true);
  const [privategroupcheck, setprivategroupcheck] = useState(true);
  const [SearchVal, setSearchVal] = useState("");
  const [countdemofiles, setcountdemofiles] = useState("0");
  const [title, settitle] = useState("");
  const [upfile, setupfile] = useState("");
  const [privacy, setprivacy] = useState("Private");
  const [description, setdescription] = useState("");
  const [tags, settags] = useState("");
  const [upfilename, setupfilename] = useState("");

  const [ter, setTer] = useState(false);
  const [der, setDer] = useState(false);
  const [tger, setTger] = useState(false);
  const [fer, setFer] = useState(false);
  const [ModalAlerShow, setModalAlertShow] = useState(false);
  const [spinner, setspinner] = useState(true);
  const [showLoading, setShowLoading] = useState("none");
  const [mainrows, setmainfutureId] = useState([]);
  const [load_flag, setload_flag] = useState(0);
  const [webSearch, setWebSearch] = useState("Medline");
  const [patsearch, setPatSearch] = useState("");
  const [disable, setDisable] = useState(false);

  const mystyle_video = { top: "73px", position: "fixed", zIndex: "1001" };
  const { Search } = Input;

  const len = education.length;

  var user_id = Decrypt_Value(localStorage.getItem("user_id"), "westside");
  const [shareShow, setShare] = useState(false);
  const [Classname, setClassName] = useState("");
  const [Classname1, setClassName1] = useState("");
  const [toggle, setToggle] = useState(true);
  const [toggle1, setToggle1] = useState(false);
  const [thumbnailFile, setThumbnailfile] = useState("");
  const [thumbnailFileName, setThumbnailfilename] = useState("");
  const [thumbnailErr, setThumbnailErr] = useState(false);

  const [dis, setDis] = useState(false);
  const [search, setSearch] = useState("");
  const [allPatient, setAllPatient] = useState(false);
  const [patNames, setPatNames] = useState([]);
  const [patients, setPatients] = useState([]);
  const [patientsId, setPatientsId] = useState([]);
  const [fileErrMsg, setFileErrMsg] = useState("");
  const [thumbErrMsg, setThumbErrMsg] = useState("");
  const [input1, setInput1] = useState(false);
  const [data, setData] = useState([]);
  const [ModalMsg, setModalMsg] = useState();
  const [allPatientList, setAllPatientList] = useState([]);
  const [allPatientCount, setAllPatientCount] = useState("");
  const [homeworkState, setHomeworkState] = useState({
    showVideo: false,
    showDoc: false,
    fileurl: "",
    formTitle: "",
  });
  // ------------------------------ State and Variables End--------------------------------------------------- //

  // ------------------------------ Functions----start---------------------------------------------------//

  const handleClose = () => {
    setShow(false);
    setFer(false);
    setThumbnailErr(false);
    setDis(false);
    setupfile("");
    setThumbnailfile("");
    setPatNames("");
    setSearch("");
    setPatients("");
    settitle("");
    setupfile("");
    setThumbnailfile("");
    setprivacy("Private");
    setdescription("");
    settags("");
    setInput1(false);
    setToggle(true);
    setToggle1(false);
    setAllPatient(false);
    setDisable(false);
    setupfilename("");
    setTer(false);
    setDer(false);
    setTger(false);
  };
  const handleShow = () => setShow(true);

  const handleChange = (e) => {
    setDis(true);
    setSearch(e.target.value);
  };

  const closeDocModal = () => {
    setHomeworkState({ ...homeworkState, showDoc: false });
  };

  const closeVideoModal = () => {
    setHomeworkState({ ...homeworkState, showVideo: false });
  };

  const handleAllCheck = (e) => {
    setAllPatient(!allPatient);
    if (e.target.checked === true) {
      let c = [];
      if (allPatientList.length != 0) {
        allPatientList.map((row) => {
          let obj = row;
          obj.checked = true;
          c.push(obj);
        });
        setPatNames(c);
        let a = [];
        let b = [];
        allPatientList.map((r) => {
          b.push(r.pid);
          a.push(r.fname);
        });
        setPatientsId(b);
        setPatients(a);
      }
    } else {
      setPatients([]);
      setPatientsId([]);
      let p = patNames.map((row) =>
        row.checked === true ? { ...row, checked: false } : row
      );
      console.log(p, "o");
      setPatNames(p);
    }
  };

  const handleSelect = (row, e) => {
    let tmp = patients;
    let tmp1 = patientsId;

    if (e.target.checked === true) {
      let a = patientsId;

      if (allPatientCount === a.length) {
        setAllPatient(true);
      }
      if (patients[0] !== "") {
        setPatients([...patients, row.fname]);
        setPatientsId([...patientsId, row.pid]);
      } else {
        setPatients(row.fname);
        setPatientsId(row.pid);
      }
    } else {
      tmp1.map((r, i) => {
        if (r === row.pid) {
          tmp.splice(i, 1);
          tmp1.splice(i, 1);
        }
      });
      setAllPatient(false);
      setPatients(tmp);
      setPatientsId(tmp1);
    }
    let temp = patNames.map((r) =>
      r.pid === row.pid ? { ...r, checked: e.target.checked } : r
    );
    let all = false;
    for (let i = 0; i < temp.length; i++) {
      if (temp[i].checked === true) {
        all = true;
      } else if (temp[i].checked === false) {
        all = false;
        break;
      }
      if (all === false) {
        break;
      }
    }
    if (all !== false) {
      if (allPatientCount === temp.length) {
        setAllPatient(true);
      }
    }
    setPatNames(temp);
  };

  const handleModal = (a) => {
    setShare(false);
    if (a === "success") {
      setModalAlertShow(true);
      setModalMsg("Home work Assigned  successfully");
      setTimeout(() => setModalAlertShow(false), 1500);
    }
  };

  const Toggle = (e) => {
    setToggle(!toggle);
    setToggle1(!toggle1);
    setFer(false);
    setThumbnailErr(false);
    setTer(false);
    setDer(false);
    setTger(false);
    if (upfilename !== "" && upfile !== "") {
      fileformatcheck(upfilename, upfile);
    }
  };
  const OnSubmit = async (e) => {
    setDisable(true);
    e.preventDefault();
    var err = 0;
    if (title === "") {
      setTer(true);
      err = 2;
    }
    if (upfile === "") {
      setFer(true);
      setFileErrMsg("please select file");
      err = 2;
    }
    if (thumbnailFile === "") {
      setThumbnailErr(true);
      setThumbErrMsg("please select thumbnail");
      err = 2;
    }
    if (description === "") {
      setDer(true);
      err = 2;
    }
    if (tags === "") {
      setTger(true);
      err = 2;
    }
    if (fer) {
      err = 2;
    }
    if (thumbnailErr) {
      err = 2;
    }
    if (err > 0) {
      setDisable(false);
      return false;
    }
    let temp = [];

    if (patNames.length > 0) {
      patNames.map((row, i) => {
        if (row.checked === true) {
          temp.push(row.pid);
        }
      });
    }

    let filetype = toggle === true ? "Worksheet" : "Video";

    if (len != "15") {
      const dataArray = new FormData();
      dataArray.append("title", title);
      dataArray.append("uploadfile", upfile);
      dataArray.append("thumbfile", thumbnailFile);
      dataArray.append("privacy", privacy);
      dataArray.append("desp", description);
      dataArray.append("tags", tags);
      dataArray.append("uid", user_id);
      if (temp.length > 0) {
        dataArray.append("pid", temp);
      }
      dataArray.append("fileType", filetype);
      dataArray.append("share", "share");
      axiosInstance.post("/westside/patient/education", dataArray).then(() => {
        handleClose();
        refresh();
        setModalAlertShow(true);
        setModalMsg("Resource uploaded successfully");
        setTimeout(() => setModalAlertShow(false), 1500);
      });
    } else {
      alert("You reached max document upload..!");
    }
  };

  const secondsearch = () => {
    const tenPokemon = [];
    const article = {
      Private: privatecheck,
      PRGroup: privategroupcheck,
      search: SearchVal,
      userID: user_id,
      // owner_id: owner_id,
      limit: 40,
      offset: 0,
    };
    let encryptedData = Encrypt_Value(article, "westside");
    axiosInstance
      .put("/westside/patient/geteducationdata_1", encryptedData)
      .then((response) => {
        let decodingResponse = Decrypt_Value(response.data, "westside");
        decodingResponse.forEach((p) => tenPokemon.push(p));
        setmainfutureId(tenPokemon);
        let items = decodingResponse.slice(0, 15);
        if (decodingResponse.length > 15) {
          setShowLoading("block");
        } else {
          setShowLoading("none");
        }
        setgeteducation_(items);
        setspinner(false);
      })
      .catch(() => {
        setgeteducation_([]);
        setShowLoading("none");
        setspinner(false);
      });
  };

  const handleShow1 = (row) => {
    if (row.fileType === "Video") {
      setHomeworkState({
        ...homeworkState,
        url: testurl + row.upload_file,
        docTitle: row.title,
        showVideo: true,
      });
    } else {
      setHomeworkState({
        ...homeworkState,
        url: testurl + row.upload_file,
        docTitle: row.title,
        showDoc: true,
      });
    }
  };

  const filetypeicon = (filename) => {
    var extentions = filename.split(".").pop();

    if (
      extentions === "jfif" ||
      extentions === "jpg" ||
      extentions === "bmp" ||
      extentions === "png"
    ) {
      return (
        <img
          src={imagetitle}
          alt=""
          loading="lazy"
          width="24px"
          height="24px"
        />
      );
    } else if (
      extentions === "doc" ||
      extentions === "docx" ||
      extentions === "txt"
    ) {
      return (
        <img src={filetitle} alt="" loading="lazy" width="24px" height="24px" />
      );
    } else if (extentions === "pdf") {
      return (
        <img src={pdftitle} alt="" loading="lazy" width="24px" height="24px" />
      );
    } else {
      return (
        <Images
          src={VideoIcon}
          alt=""
          loading="lazy"
          width="24px"
          height="24px"
        />
      );
    }
  };

  const checkfiletype = (file, a) => {
    var fileName = file.split("\\").pop();
    let index = fileName.lastIndexOf(".");
    let format = fileName.slice(index + 1, fileName.length);
    const formats = ["mp4", "mov", "avi", "webm", "flv", "mkv"];
    if (formats.includes(format)) {
      if (a === "1") {
        return "Video";
      } else {
        return "Play";
      }
    } else {
      if (a === "1") {
        return "Worksheet";
      } else {
        return "View";
      }
    }
  };

  const fileformatcheck = (item, files) => {
    let index = item.lastIndexOf(".");
    let format = item.slice(index + 1, item.length);

    if (upfilename !== "") {
      let fileSize = parseInt(files.size / 1024 / 1024);

      if (!toggle) {
        const formats = ["pdf", "doc", "docx", "txt"];
        if (formats.includes(format)) {
          setFer(false);
        } else {
          setFer(true);
          setFileErrMsg("please upload valid file");
        }
      } else {
        const formats = ["mp4", "mov", "avi", "webm", "flv", "mkv"];
        if (formats.includes(format)) {
          if (fileSize > 10) {
            setFer(true);
            setFileErrMsg("please upload  file less than 10mb");
          } else {
            setFer(false);
          }
        } else {
          setFer(true);
          setFileErrMsg("please upload valid video format");
        }
      }
      return format;
    } else {
      return format;
    }
  };

  const onChange = (e) => {
    var fileName = e.target.value.split("\\").pop();
    let file = e.target.files[0];
    let fileSize = parseInt(file.size / 1024 / 1024);

    if (e.target.name !== "patientdocument_thumbnail") {
      setupfile(e.target.files[0]);
      let format = fileformatcheck(fileName, file);
      setupfilename(fileName);
      setFer(false);

      if (toggle) {
        const formats = ["pdf", "doc", "docx", "txt"];
        if (formats.includes(format)) {
          if (fileSize > 10) {
            setFer(true);
            setFileErrMsg("please upload  file less than 10mb");
          } else {
            setFer(false);
          }
        } else {
          setFer(true);
          setFileErrMsg("please upload valid file");
        }
      } else {
        const formats = ["mp4", "mov", "avi", "webm", "flv", "mkv"];
        if (formats.includes(format)) {
          if (fileSize > 10) {
            setFer(true);
            setFileErrMsg("please upload  file less than 10mb");
          } else {
            setFer(false);
          }
        } else {
          setFer(true);
          setFileErrMsg("please upload valid video format");
        }
      }
    } else if (e.target.name === "patientdocument_thumbnail") {
      let index = fileName.lastIndexOf(".");
      let format = fileName.slice(index + 1, fileName.length);

      setThumbnailfile(e.target.files[0]);
      setThumbnailfilename(fileName);
      setThumbnailErr(false);
      const formats = ["png", "jpeg", "jpg", "bmp", "gif", "jfif"];
      if (formats.includes(format)) {
        if (fileSize > 10) {
          setThumbnailErr(true);
          setThumbErrMsg("please upload  file less than 10mb");
        } else {
          setThumbnailErr(false);
        }
      } else {
        setThumbnailErr(true);
        setThumbErrMsg("unsupported thumbnail format");
      }
    }
  };

  const setOptimizeName = (fileName) => {
    var extension = fileName.split(".").pop();
    if (fileName.length > 10) {
      return fileName.slice(0, 8) + "..." + extension;
    } else {
      return fileName;
    }
  };

  const privatecheckfn = () => {
    setprivatecheck(!privatecheck);
  };

  const privategroupcheckfn = () => {
    setprivategroupcheck(!privategroupcheck);
  };

  const refresh = () => {
    const tenPokemon = [];
    const article = {
      Private: privatecheck,
      PRGroup: privategroupcheck,
      userID: user_id,
      // owner_id: owner_id,
      limit: 40,
      offset: 0,
    };
    let encryptedData = Encrypt_Value(article, "westside");
    axiosInstance
      .put("/westside/patient/geteducationdata_1", encryptedData)
      .then((response) => {
        let decodingResponse = Decrypt_Value(response.data, "westside");
        decodingResponse.forEach((p) => tenPokemon.push(p));
        setmainfutureId(tenPokemon);
        let items = decodingResponse.slice(0, 15);
        setgeteducation_(items);
        setspinner(false);
        if (decodingResponse.length > 15) {
          setShowLoading("block");
        } else {
          setShowLoading("none");
        }
      })
      .catch(() => {
        setgeteducation_([]);
        setspinner(false);
        setShowLoading("none");
      });

    axiosInstance
      .get("/westside/patient/geteducationdata?uid=" + user_id)
      .then((response) => {
        let decodingResponse = Decrypt_Value(response.data, "westside");
        geteducation(decodingResponse);
        setspinner(false);
        if (decodingResponse[0].account_type === "0") {
          setcountlimitfordemo(true);
          setcountdemofiles(decodingResponse.length);
          if (decodingResponse.length > 15) {
            setbtndisabled(true);
          }
        }
      })
      .catch(() => {
        geteducation([]);
        setspinner(false);
      });
  };

  const firstloadTenPokemon = () => {
    const tenPokemon = [];
    const article = {
      Private: privatecheck,
      PRGroup: privategroupcheck,
      userID: user_id,
      // owner_id: owner_id,
      limit: 40,
      offset: 0,
    };
    let encryptedData = Encrypt_Value(article, "westside");
    axiosInstance
      .put("/westside/patient/geteducationdata_1", encryptedData)
      .then((response) => {
        let decodingResponse = Decrypt_Value(response.data, "westside");
        decodingResponse.forEach((p) => tenPokemon.push(p));
        setmainfutureId(tenPokemon);
        let items = decodingResponse.slice(0, 15);
        setgeteducation_(items);
        setspinner(false);
        if (decodingResponse.length > 15) {
          setShowLoading("block");
        } else {
          setShowLoading("none");
        }
      })
      .catch(() => {
        setgeteducation_([]);
        setspinner(false);
        setShowLoading("none");
      });
    axiosInstance
      .get("/westside/patient/geteducationdata?uid=" + user_id)
      .then((response) => {
        let decodingResponse = Decrypt_Value(response.data, "westside");
        geteducation(decodingResponse);
        setspinner(false);
        if (decodingResponse[0].account_type === "0") {
          setcountlimitfordemo(true);
          setcountdemofiles(decodingResponse.length);
          if (decodingResponse.length > 15) {
            setbtndisabled(true);
          }
        }
      })
      .catch(() => {
        geteducation([]);
        setspinner(false);
      });
  };

  const loadTenPokemon = (e) => {
    setShowLoading("block");
    var data = mainrows.length;
    var final = mainrows;
    var rowscount = geteducation_.length;
    var res = [];
    for (var i in final) {
      res.push(final[i]);
    }
    if (data == rowscount) {
      setShowLoading("none");
    }
    const tenPokemon1 = [];

    if (rowscount >= data - 30 && load_flag == 0) {
      setload_flag(1);
      var data = mainrows.length;
      const article = {
        Private: privatecheck,
        PRGroup: privategroupcheck,
        userID: user_id,
        // owner_id: owner_id,
        limit: 40,
        offset: data,
      };
      let encryptedData = Encrypt_Value(article, "westside");
      axiosInstance
        .put("/westside/patient/geteducationdata_1", encryptedData)
        .then((response) => {
          let decodingResponse = Decrypt_Value(response.data, "westside");
          decodingResponse.forEach((p) => tenPokemon1.push(p));
          setmainfutureId((pokemon) => [...pokemon, ...tenPokemon1]);
          setload_flag(0);
          setspinner(false);
        })
        .catch(() => {
          let tenPokemon1 = [];
          setmainfutureId((pokemon) => [...pokemon, ...tenPokemon1]);
        });
    }
    if (geteducation_.length <= mainrows.length) {
      var items = [];
      let s = parseInt(rowscount) + 10;
      for (i = rowscount; i <= s; i++) {
        if (res[i] !== undefined) {
          items.push(res[i]);
        }
      }
      setgeteducation_((mitems) => [...mitems, ...items]);
    }
  };

  const filereset = (a) => {
    if (a === "1") {
      document.getElementById("documentfileup").value = "";
      setupfile("");
      setFer(false);
      setupfilename("");
    } else if (a === "2") {
      setThumbnailfile("");
      setThumbnailfilename("");
      setThumbnailErr(false);
    }
  };

  const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const shwHelpTrue = () => setState({ showHelp: true });

  const shwHelpFls = () => setState({ showHelp: false });

  const setSrchVal = (e) => setSearchVal(e.target.value);

  const setShareVal = (row) => {
    setShare(true);
    setData(row);
  };

  const titleChngVal = (e) => {
    settitle(e.target.value);
    setTer(false);
  };

  const prvcySetVal = (e) => setprivacy(e.target.value);

  const descSetVal = (e) => {
    setdescription(e.target.value);
    setDer(false);
  };

  const tagSetVal = (e) => {
    settags(e.target.value);
    setTger(false);
  };
  const inp1TruSet = () => setInput1(true);

  const inp1FlsSet = () => {
    setInput1(false);
    setDis(false);
    setSearch("");
  };

  const handleClick3 = (e) => {
    if (e.target.name === undefined) {
      setInput1(false);
      setDis(false);
    }
  };

  // ------------------------------ functions----end---------------------------------------------------- //

  // ------------------------------ useEffect----start--------------------------------------------------- //

  useEffect(() => {
    let isCancelled = false;

    const handlechange1 = async () => {
      await timeout(1000);
      if (!isCancelled) {
        if (search !== "") {
          let Data_ =
            "fname=" + search + "&limit=40&offset=0" + "&userID=" + user_id;

          let Data_1 = Encrypt_Value(Data_, "westside");

          axiosInstance
            .get("/westside/patientSearch/education?search=" + Data_1)
            .then((response) => {
              let DecodeVal = Decrypt_Value(response.data, "westside");
              let t1 = [];
              patNames.forEach((row) => {
                if (row.checked === true) {
                  t1.push(row);
                }
              });
              console.log(t1, "row 1");
              t1.forEach((row) => {
                DecodeVal.forEach((r, i) => {
                  console.log(r, "row pid");
                  if (row.pid === r.pid) {
                    DecodeVal.splice(i, 1);
                  }
                });
              });

              setPatNames([...t1, ...DecodeVal]);
            })

            .catch((err) => {
              setPatNames(["No result"]);
            });
        } else {
          if (patNames.length > 1) {
            let t1 = [];
            patNames.map((row) => {
              if (row.checked === true) {
                t1.push(row);
              }
            });
            setPatNames(t1);
          }
        }
      }
    };

    handlechange1();
    return () => {
      isCancelled = true;
    };
  }, [search]);
  console.log(patNames, "patNames");
  useEffect(() => {
    secondsearch();
  }, [privatecheck, privategroupcheck]);

  useEffect(() => {
    setClassName("select-pat-edu");
    firstloadTenPokemon();
    let Data_frsh =
      "fname=" +
      search.toLowerCase() +
      "&limit=40&offset=0" +
      "&uid=" +
      user_id;
    let Data_enc = Encrypt_Value(Data_frsh, "westside");

    axiosInstance
      .get("/westside/get/patient/list?search=" + Data_enc)
      .then((response) => {
        let DecodeVal = Decrypt_Value(response.data, "westside");
        setAllPatientCount(DecodeVal.length);
        console.log(DecodeVal, "DecodeVal");
        setAllPatientList(DecodeVal);
      })
      .catch((err) => {
        setAllPatientList([]);
      });
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClick3);

    return () => {
      document.removeEventListener("click", handleClick3);
    };
  }, []);

  // ------------------------------ useEffect----end---------------------------------------------------

  return (
    <div className="lib_from_ pat_edu_il_cs_head">
      {spinner ? (
        <Loader />
      ) : (
        <>
          <div className="tab_title_main pat_ed_f_d">
            <div className="vid-play-box" style={mystyle_video}>
              <div className="vid-line1"></div>
              <div className="vid-line2"></div>
              <div className="vid-play-icon">
                <img
                  src={PlayerIcon}
                  height="28px"
                  width="31px"
                  style={{ cursor: "pointer" }}
                  onClick={shwHelpTrue}
                  alt="..."
                />
              </div>
              <div className="vid-line3"></div>
              <div className="vid-play-text" onClick={shwHelpTrue}>
                Watch a quick tutorial about Patient Education
              </div>
            </div>
            <div className="tab_title_name pat_ed_tit_nam">
              Patient Education Resources
            </div>
            <div className="pat_ed_up_fl_sze">
              {countlimitfordemo && (
                <span className="tab_text_before_btn">
                  You uploaded {countdemofiles} of 15 allowed.
                </span>
              )}
            </div>
            <div className="add-button-template">
              <div className="pat_ed_up_nw_rsrce">
                <Button
                  disabled={btndisabled}
                  variant="primary"
                  onClick={handleShow}
                  className="save_active m-l-20 lib_prev_ patuploadbuttom"
                >
                  Upload New Resource
                </Button>
              </div>
            </div>
          </div>

          <div className=" patEduTableRadius Tabs_Tabpane for-pat-edu-box-bor bot">
            <div className="pat_ed_box_head_tit">
              <div className="for-pat-edu-box-tit">Education Resource</div>
            </div>

            <>
              <hr className="mr-0"></hr>
              <div className="pat_ed_m_t_-10">
                <div className="filter_result_text filter-result-text ralign-pedu">
                  Filter Results
                </div>
                <div className="d-flex check_ pat_ed_ff_pr_jc">
                  <div className="all_check_box_">
                    <Checkbox
                      id="privatecheck"
                      checked={privatecheck}
                      onChange={privatecheckfn}
                    />
                    <span className="check_label_">Private</span>
                    <Checkbox
                      id="privategroupcheck"
                      checked={privategroupcheck}
                      onChange={privategroupcheckfn}
                    />
                    <span className="check_label_">Practice Group</span>
                  </div>
                  <div className="d-flex">
                    <div className="title_d_n_t_01">
                      Title / Description / Notes / Tag:
                    </div>
                    <div className="pat_ed_m_r_16">
                      <div className=" ins-top-btn lib_search_2 for-ant-patedu-web">
                        <Search
                          className="second_search_lib search_height pat_ed_m_t_4"
                          placeholder="Search "
                          onChange={(e) => setSrchVal(e)}
                        />
                        <Button
                          className="save_active pat_ed_ml_w_mt_p"
                          variant="primary"
                          onClick={secondsearch}
                        >
                          Search
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
            <hr className="mr-0 pat_edu_m_t_20"></hr>
            <Div1 display="flex" padding="16px 16px 0 16px" flexWrap="Wrap">
              {geteducation_.length !== 0 ? (
                <>
                  {geteducation_.map((row, i) => {
                    return (
                      <Div1
                        display="block"
                        border="1px solid #EBEAED"
                        radius="8px"
                        margin="0px 16px 16px 0px"
                        width="350px"
                        key={i}
                      >
                        <Div1
                          display="flex"
                          al="center"
                          justifyContent="center"
                          height="174px"
                        >
                          <Images
                            src={testurl + row.thumb_nail_file}
                            btopleft="8px"
                            btopright="8px"
                            maxWidth="100%"
                            width="100%"
                            objectFit="fill"
                            maxHeight="174px"
                          ></Images>
                        </Div1>

                        <Div1
                          display="flex"
                          justifyContent="space-between"
                          padding="12px 12px 8px 12px"
                        >
                          <P
                            family="Inter"
                            weight="600"
                            size="16px"
                            lheight="18.77px"
                            color="#333333"
                            marginBottom="0px"
                          >
                            {row.title}
                          </P>
                          <Button1
                            color=" #00A5FF"
                            backgroundColor="#00a5ff1a"
                            padding="4px 12px"
                          >
                            {checkfiletype(row.upload_file, "1")}
                          </Button1>
                        </Div1>
                        <Div1 padding="0px 12px 0px 12px">
                          <P
                            family="Inter"
                            weight="400"
                            size="14px"
                            lheight="16.94px"
                            color="#545F82"
                            textAlign="left"
                            display="flex"
                            width="336px"
                            height="50px"
                            marginBottom="0px"
                            overflow="hidden"
                          >
                            {row.desp}
                          </P>
                        </Div1>
                        <Div1 display="flex" padding="12px">
                          {row.img_url === null ? (
                            <Avatar
                              width="32px"
                              height="32px"
                              border="1px solid #ffffff"
                            >
                              <AvatarLetter top="6px" left="0px">
                                {row.username.charAt(0).toUpperCase()}
                              </AvatarLetter>
                            </Avatar>
                          ) : (
                            <Images
                              src={testurl_user + row.img_url}
                              borderRadius="40px"
                              height="32px"
                              width="32px"
                              margin="0px 12px 0px 0px"
                              objectFit="fill"
                            />
                          )}
                          <P
                            family="Inter"
                            weight="500"
                            size="16px"
                            lheight="18px"
                            color="#333333"
                            marginBottom="0px"
                          >
                            {row.username}
                          </P>
                        </Div1>

                        <Div1
                          margin="0px 16px 0px 16px"
                          display="flex"
                          justifyContent="space-between"
                          padding="12px 0px"
                          borderTop="1px solid #EBEAED"
                        >
                          <Button1
                            color=" #2c7be5"
                            padding="8px 33px"
                            family="Work Sans"
                            weight="500"
                            size="14px"
                            lheight="16.94px"
                            backgroundColor="#ffff"
                            border="1px solid #2c7be5"
                            className="close_active"
                            onClick={() => handleShow1(row)}
                          >
                            {checkfiletype(row.upload_file, "2")}
                          </Button1>
                          <Button1
                            color="#fff"
                            padding="8px 18px"
                            family="Work Sans"
                            weight="500"
                            size="14px"
                            lheight="16.94px"
                            backgroundColor="#2c7be5"
                            border="1px solid #2c7be5"
                            className="save_active"
                            onClick={() => setShareVal(row)}
                          >
                            Assigned to a Patient
                          </Button1>
                        </Div1>
                      </Div1>
                    );
                  })}
                </>
              ) : (
                <>
                  <Div1 width="100%">
                    <Images className="empty_data_pic_alt" src={Emp_data_pic} />
                  </Div1>
                </>
              )}
            </Div1>
            <AssignPatient
              handleModal={handleModal}
              show={shareShow}
              data={data}
            />

            <Modal
              className="add_new_edu_resource modal-width"
              centered
              show={show}
              onHide={handleClose}
            >
              <Modal.Header closeButton>
                <Modal.Title style={{ marginTop: "6px", marginBottom: "6px" }}>
                  Add New Education Resource
                </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ padding: "20px" }}>
                <Form onSubmit={OnSubmit}>
                  <Form.Row className="pat_ed_fr_mb in_f_row">
                    <Form.Label className="pat_ed_fl_mr_mt text_label ">
                      Type
                    </Form.Label>
                    <Div1 display="flex" al="center">
                      <Outer
                        selected={toggle}
                        name="card"
                        onClick={(e) => Toggle(e)}
                      >
                        <Inner />
                      </Outer>
                      <P
                        family="Inter"
                        weight="400"
                        size="14px"
                        lheight="16px"
                        color={toggle ? "#2C7BE5" : "#718096"}
                        marginLeft="8px"
                        marginBottom="0px"
                        marrginRight="27px"
                      >
                        Add WorkSheet
                      </P>
                      <Outer
                        selected={toggle1}
                        name="card"
                        onClick={(e) => Toggle(e)}
                        marginLeft="27px"
                      >
                        <Inner />
                      </Outer>
                      <P
                        family="Inter"
                        weight="400"
                        size="14px"
                        lheight="16px"
                        color={toggle1 ? "#2C7BE5" : "#718096"}
                        marginLeft="8px"
                        marginBottom="0px"
                      >
                        Add Video
                      </P>
                    </Div1>
                  </Form.Row>

                  <Form.Row className="in_f_row">
                    <Form.Group
                      as={Col}
                      // controlId=""
                      style={{ marginBottom: ter === true && "6px" }}
                    >
                      <Form.Label className="text_label">Title</Form.Label>
                      <Form.Control
                        className="uploadTitleInput"
                        style={
                          ter
                            ? { border: "1px solid red", borderRadius: "5px" }
                            : {}
                        }
                        name="title"
                        value={title}
                        onChange={(e) => titleChngVal(e)}
                      />
                    </Form.Group>
                    {ter ? (
                      <p className="errormessage pat_ed_err_msg_mltb">
                        Enter a valid title
                      </p>
                    ) : (
                      ""
                    )}
                  </Form.Row>
                  <Form.Row className="in_f_row">
                    <Form.Group
                      as={Col}
                      // controlId=""
                      style={{ marginBottom: fer === true ? "0px" : "7px" }}
                    >
                      <Form.Label className=" text_label_1">
                        Upload File
                      </Form.Label>

                      <label className="edu_upload_">
                        <Div1 className="edu-upload-drag-container">
                          <p className="edu_upload_title">
                            Select file to upload
                          </p>

                          <p className="edu_upload_hint">
                            {toggle
                              ? "     PDF,DOC,DOCX or TXT files Smaller than 10mb"
                              : " MP4,MKV,AVI,MOV,FLV OR WEBM files Smaller than 10mb"}{" "}
                          </p>
                          <Button
                            className="close_active close_active2 edu-choose-btn"
                            variant="outline-light"
                            style={
                              fer
                                ? {
                                    border: "1px solid red",
                                    borderRadius: "5px",
                                  }
                                : {}
                            }
                          >
                            Choose File
                          </Button>
                          <Form.Control
                            type="file"
                            name="patientdocument"
                            id="documentfileup"
                            className="FileUploadedu"
                            onChange={onChange}
                          />
                        </Div1>
                        {upfile ? (
                          <Div1 className="progress_bar pat_ed_up_file_div">
                            <span className="patient_edu_filebar">
                              <BsFileEarmarkText style={{ fontSize: 24 }} />
                            </span>
                            <span className="pat_ed_icon_span_w">
                              {setOptimizeName(upfilename)}
                            </span>
                            <BsXCircle
                              onClick={() => filereset("1")}
                              className="can-file"
                              style={{ marginTop: "0px" }}
                            />
                          </Div1>
                        ) : (
                          ""
                        )}
                      </label>
                    </Form.Group>
                    {fer ? (
                      <p className="pat_ed_err_msg_mltb errormessage">
                        {fileErrMsg}
                      </p>
                    ) : (
                      ""
                    )}
                  </Form.Row>
                  <Form.Row className="in_f_row">
                    <Form.Group
                      as={Col}
                      // controlId=""
                      style={{
                        marginBottom: thumbnailErr === true ? "0px" : "7px",
                      }}
                    >
                      <Form.Label className=" text_label_1">
                        Add Thumbnail
                      </Form.Label>

                      <label className="edu_upload_">
                        <Div1 className="edu-upload-drag-container">
                          <p className="edu_upload_title">
                            Select file to upload
                          </p>

                          <p className="edu_upload_hint">
                            JPG,PNG,BMP,JFIF or JPEG Smaller than 10mb
                          </p>
                          <Button
                            className="close_active close_active2 edu-choose-btn"
                            variant="outline-light"
                            style={
                              thumbnailErr
                                ? {
                                    border: "1px solid red",
                                    borderRadius: "5px",
                                  }
                                : {}
                            }
                          >
                            Choose File
                          </Button>
                          <Form.Control
                            type="file"
                            name="patientdocument_thumbnail"
                            id="documentthumnailup"
                            className="FileUploadedu"
                            onChange={onChange}
                          />
                        </Div1>
                        {thumbnailFile ? (
                          <Div1 className="pat_ed_up_file_div progress_bar">
                            <span className="patient_edu_filebar">
                              <BsFileEarmarkText style={{ fontSize: 24 }} />
                            </span>
                            <span className="pat_ed_icon_span_w">
                              {setOptimizeName(thumbnailFileName)}
                            </span>
                            <BsXCircle
                              onClick={() => filereset("2")}
                              className="can-file"
                              style={{ marginTop: "0px" }}
                            />
                          </Div1>
                        ) : (
                          ""
                        )}
                      </label>
                    </Form.Group>
                    {thumbnailErr ? (
                      <p className=" pat_ed_err_msg_mltb errormessage">
                        {thumbErrMsg}
                      </p>
                    ) : (
                      ""
                    )}
                  </Form.Row>

                  <Form.Row className="in_f_row">
                    <Form.Group
                      as={Col}
                      // controlId=""
                    >
                      <Form.Label className="text_label_2">Privacy</Form.Label>
                      <Form.Control
                        as="select"
                        name="privacy"
                        className="uploadPrivacyInput"
                        value={privacy}
                        onChange={(e) => prvcySetVal(e)}
                      >
                        <option value="Private">Private</option>

                        <option value="Practice Group">Practice Group</option>
                      </Form.Control>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row className="in_f_row">
                    <Form.Group
                      as={Col}
                      // controlId=""
                      style={{ marginBottom: der === true && "6px" }}
                    >
                      <Form.Label className="text_label_3">
                        Description
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        className="uploadTitleDescription"
                        style={
                          der
                            ? {
                                border: "1px solid red",
                                borderRadius: "5px",
                                height: 63,
                                resize: "none",
                              }
                            : { height: 63, resize: "none" }
                        }
                        name="description"
                        value={description}
                        onChange={(e) => descSetVal(e)}
                      />
                    </Form.Group>
                    {der ? (
                      <p className=" pat_ed_err_msg_mltb errormessage">
                        Enter a valid description
                      </p>
                    ) : (
                      ""
                    )}
                  </Form.Row>

                  <Form.Row className="in_f_row">
                    <Form.Group
                      as={Col}
                      // controlId=""
                      style={{ marginBottom: tger === true && "6px" }}
                    >
                      <Form.Label className="text_label_4">Tags</Form.Label>
                      <Form.Control
                        as="textarea"
                        className="uploadTagsInput"
                        style={
                          tger
                            ? {
                                border: "1px solid red",
                                borderRadius: "5px",
                                height: 63,
                                resize: "none",
                              }
                            : { height: 63, resize: "none" }
                        }
                        name="tags"
                        value={tags}
                        onChange={(e) => tagSetVal(e)}
                      />
                    </Form.Group>
                    {tger ? (
                      <p className="pat_ed_err_msg_mltb errormessage">
                        Enter a valid tag
                      </p>
                    ) : (
                      ""
                    )}
                  </Form.Row>
                  <Form.Row className="in_f_row">
                    <Form.Group
                      as={Col}
                      // controlId=""
                      id="parent"
                    >
                      <Form.Label className="text_label_4 pat_ed_fl_mr_a_p">
                        Assign Patient
                      </Form.Label>

                      <Div1
                        display="flex"
                        minheight="32px"
                        width="248px"
                        border="1px solid #DBDBDB"
                        backgroundColor="#ffff"
                        radius="4px"
                        padding="4px 4px 4px 12px"
                        onClick={inp1TruSet}
                      >
                        <FiSearch />
                        <Inputstyled
                          className="input-test"
                          type="text"
                          height="22px"
                          width="200px"
                          placeholder="Search"
                          border="none"
                          backgroundColor="#ffff"
                          display="flex"
                          value={allPatient === true ? "All Patient" : patients}
                          readOnly
                        />
                      </Div1>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row className="in_f_row">
                    <Form.Group
                      as={Col}
                      // controlId=""
                    >
                      <Div1
                        name="search"
                        style={
                          input1
                            ? { display: "flex", marginLeft: "111px" }
                            : { display: "none", marginLeft: "111px" }
                        }
                        minheight="32px"
                        width="248px"
                        border="1px solid #DBDBDB"
                        backgroundColor="#ffff"
                        radius="4px"
                        padding="4px"
                        justifyContent="space-between"
                      >
                        <Inputstyled
                          type="text"
                          name="search"
                          height="22px"
                          width="200px"
                          placeholder="Type here..."
                          border="none"
                          backgroundColor="#ffff"
                          value={search}
                          onChange={(e) => handleChange(e)}
                          autoComplete="off"
                        ></Inputstyled>

                        <AiOutlineCloseCircle
                          style={{ color: "red" }}
                          onClick={inp1FlsSet}
                        />
                      </Div1>
                      <Dropdown
                        name="search"
                        selected={dis}
                        active={search}
                        flexDirection="column"
                        width="69%"
                        position="absolute"
                        left="110px"
                        border="1px solid #DBDBDB"
                        family="Inter"
                        size="14px"
                        lineheight="19px"
                        weight="400"
                        top="-192px"
                        height="189px"
                        autoComplete="off"
                      >
                        <DataScroll maxHeight="291px" width="100%">
                          {patNames.length !== 0 &&
                          patNames[0] !== "No result" ? (
                            <>
                              <DropdownItems
                                name="search"
                                dis="flex"
                                className="checkbox-top"
                                width="100%"
                                family="Inter"
                                size="14px"
                                lineheight="19px"
                                weight="400"
                                minheight="34px"
                                maxheight="max-content"
                                backgroundColor="#ffff"
                                padding="4px"
                                cursor="pointer"
                              >
                                <Checkbox
                                  id="privatecheck"
                                  checked={allPatient}
                                  style={{ marginTop: "0px" }}
                                  onClick={handleAllCheck}
                                />
                                <Avatar
                                  width="20px"
                                  height="20px"
                                  // border="1px solid #ffffff"
                                >
                                  <AvatarLetterstyled
                                    top="-2px"
                                    left="3px"
                                    fontSize="10px"
                                    fontFamily="Work Sans"
                                    lineHeight="16px"
                                    fontWeight="400"
                                  >
                                    All
                                  </AvatarLetterstyled>
                                </Avatar>
                                All Patient
                              </DropdownItems>
                              {patNames.map((r, i) => {
                                return (
                                  <DropdownItems
                                    className="pat_ed_br_ddi checkbox-top"
                                    name="search"
                                    dis="flex"
                                    key={i}
                                    width="100%"
                                    family="Inter"
                                    size="14px"
                                    lineheight="19px"
                                    weight="400"
                                    minheight="34px"
                                    maxheight="300px"
                                    backgroundColor="#ffff"
                                    padding="4px"
                                    cursor="pointer"
                                  >
                                    <Checkbox
                                      style={{ marginTop: "0px" }}
                                      checked={allPatient ? true : r.checked}
                                      onChange={(e) => handleSelect(r, e)}
                                    />
                                    {r.img_url === null ? (
                                      <Avatar width="20px" height="20px">
                                        <AvatarLetterstyled
                                          top="-1px"
                                          left="7px"
                                          fontSize="10px"
                                          fontFamily="Work Sans"
                                          lineHeight="16px"
                                          fontWeight="400"
                                        >
                                          {r.fname.charAt(0).toUpperCase()}
                                        </AvatarLetterstyled>
                                      </Avatar>
                                    ) : (
                                      <Images
                                        src={testurl_user + r.img_url}
                                        borderRadius="40px"
                                        width="20px"
                                        height="20px"
                                        margin="0px 12px 0px 0px"
                                        objectFit="fill"
                                      />
                                    )}
                                    {r.fname + "  " + r.lname}
                                  </DropdownItems>
                                );
                              })}
                            </>
                          ) : (
                            <DropdownItems
                              width="100%"
                              height="max-content"
                              backgroundColor="#ffff"
                              padding="4px"
                              family="Inter"
                              size="14px"
                              lineheight="19px"
                              weight="400"
                            >
                              No result Found
                            </DropdownItems>
                          )}
                        </DataScroll>
                      </Dropdown>
                    </Form.Group>
                  </Form.Row>

                  <Modal.Footer className="uploadButtonFooter">
                    <Button
                      className="close_active close_active2 pat_ed_up_btn_foot close-btn"
                      variant="light"
                      onClick={handleClose}
                    >
                      Close
                    </Button>

                    <Button
                      disabled={disable}
                      className="save_active save-btn"
                      type="submit"
                      variant="primary"
                    >
                      Save
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal.Body>
            </Modal>

            <Modal
              className="doc-edu-mdl-new popup_centre mar MGroup flex-center "
              centered
              show={homeworkState.showDoc}
              onHide={() =>
                setHomeworkState({ ...homeworkState, showDoc: false })
              }
            >
              <DocumentView
                url={homeworkState.url}
                docTitle={homeworkState.docTitle}
                setMyState={closeDocModal}
                myState={homeworkState}
              />
            </Modal>

            <Modal
              className="resourceView reso-video popup_centre"
              centered
              show={homeworkState.showVideo}
              onHide={() =>
                setHomeworkState({ ...homeworkState, showVideo: false })
              }
            >
              <VideoView
                url={homeworkState.url}
                docTitle={homeworkState.docTitle}
                setMyState={closeVideoModal}
                myState={homeworkState}
              />
            </Modal>
            <Modal
              className="alert-popup-message"
              show={ModalAlerShow}
              onHide={() => setModalAlertShow(false)}
            >
              <div className="alert msg alert-success" role="alert">
                <BsCheckCircle className="icons-bs" />
                {ModalMsg}
              </div>
            </Modal>
          </div>
          {geteducation_.length === 0 ? (
            <></>
          ) : (
            <div
              className="loading-more patEduLoadMore"
              style={{
                display: showLoading,
                paddingTop: 15,
                paddingBottom: 10,
              }}
            >
              <Button
                style={{ display: showLoading }}
                variant="primary"
                className="patEduPadding loading-more-div"
                onClick={loadTenPokemon}
              >
                Load more...
              </Button>
            </div>
          )}
        </>
      )}

      <Modal
        show={state.showHelp}
        onHide={shwHelpFls}
        className="help-modal-video"
        centered
      >
        <div className="closeButtonModal" onClick={shwHelpFls}>
          <MdClose />
        </div>
        <Modal.Body>
          <video controls autoPlay className="vdo_alt">
            <source src={video1} type="video/mp4"></source>
          </video>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default memo(PatientEducationTable);

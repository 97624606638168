import { call, put } from "redux-saga/effects";
import moment from "moment";
import {
  setMessageList,
  setNewMsgCount,
  setPreviewDownload,
  setSecureMessageList,
  setSecureSearch,
} from "../../StateManagement/Reducers/MessageState";
import {
  PreviewDownloadApiMessage,
  SecureMessageListApi,
  SecureMessageSearchApi,
  UnreadCountUpdateApi,
} from "../Api/MessageApi";
import { Decrypt_Value, Encrypt_Value } from "../EncryptDecrypt";

export function* PreviewDownloadApi({ payload }) {
  const data = payload;
  let credential = Encrypt_Value(data, "westside");
  try {
    const res = yield call(PreviewDownloadApiMessage, credential);
    if (res.status === 200) {
      const decrptedResponse = Decrypt_Value(res.data, "westside");
      yield put(setPreviewDownload(decrptedResponse));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* UnreadCountUpdate({ payload }) {
  const data = payload;
  let credential = Encrypt_Value(data, "westside");
  try {
    const res = yield call(UnreadCountUpdateApi, credential);
  } catch (e) {
    console.log(e.message);
  }
}

export function* SecureMessageList({ payload }) {
  const payload2 = payload["userId"];

  try {
    const res = yield call(SecureMessageListApi, payload2);
    if (res.status === 200) {
      let Decrypted = Decrypt_Value(res.data, "westside");

      yield put(setSecureMessageList(Decrypted));
      if (Decrypted.length > 0) {
        var active = 0;
        active = Decrypted.filter(
          (item) =>
            moment(item.date).format("YYYY-MM-DD") ===
              moment().format("YYYY-MM-DD") &&
            item.Sender !== "Provider" &&
            parseInt(item.unread) !== 0
        );

        yield put(setNewMsgCount(active.length));
      }
    }
  } catch (e) {
    yield put(setMessageList([]));
    console.log(e.message);
  }
}

export function* SecureMessagePatientSearch({ payload }) {
  const data = payload.search;
  let credential = Encrypt_Value(data, "westside");
  yield put(setSecureSearch(""));
  try {
    const res = yield call(SecureMessageSearchApi, credential);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "westside");
      yield put(setSecureSearch(Data));
    }
  } catch (e) {
    yield put(setSecureSearch([]));
    console.log(e.message);
  }
}

import axiosInstance from "../../axios/axios";

export const PreviewDownloadApiMessage = async (credential) =>
  await axiosInstance.put(`/westside/PreviewDownloadmessenger`, credential);

export const UnreadCountUpdateApi = async (credential) =>
  await axiosInstance.put(`/westside/unreadCountUpdate`, credential);

export const SecureMessageListApi = async (credential) =>
  await axiosInstance.get(
    `/westside/appointment/secure/Message?uid=` + credential
  );

export const SecureMessageSearchApi = async (credential) =>
  await axiosInstance.get(`/westside/patientsearchportal?search=` + credential);

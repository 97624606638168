import styled from "styled-components";
import { Div } from "../../../billing/CardTransactions/styles";
import { DataDiv } from "../styles";

export const Data = styled(DataDiv)`
  width: ${(p) => p.width && p.width};
  max-height: 418px;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Row = styled(Div)`
  border-bottom: ${(p) => p.borderBottom && p.borderBottom};
  cursor: ${(p) => p.cursor && p.cursor};
  max-height: ${(p) => p.maxHeight && p.maxHeight};
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  top: ${(p) => p.top && p.top};
  box-shadow: ${(p) => p.boxShadow && p.boxShadow};
  max-width: ${(p) => p.maxWidth && p.maxWidth};
`;

export const Button = styled.button`
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  background-color: ${(p) => p.bc && p.bc};
  background: ${(p) => p.background && p.background};
  border: ${(p) => p.border && p.border};
  border-radius: ${(p) => p.br && p.br};
  margin-right: ${(p) => p.mr && p.mr};
  color: ${(p) => p.color && p.color};

  &:hover {
    color: ${(p) => p.background && "#ffffff"};
    color: ${(p) => p.bc && p.bc};
    background-color: ${(p) => p.color && p.color};
  }
`;

import styled from "styled-components";
import { TableRow } from "../setting/PatientPortalSettings/StyledComponents";

export const NoteButton = styled.button`
  border: none;
  background: ${({ active }) => (active ? "#2C7BE5" : "#ededed")};
  border-radius: 2px;
  padding: 4px 8px;
  color: ${({ active }) => (active ? "#FFFFFF" : "#718096")};
  font-family: "Inter";
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin: 0px 2px;
  height: 26px;
`;

export const NoteLabel = styled.label`
  font-family: "Inter";
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #2d3748;
  margin-right: 32px;
  margin-top: 4px;
`;

export const FlexDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${({ justify }) => justify && justify};
  align-items: center;
  margin-bottom: ${({ marginButtom }) => marginButtom && "20px"};
`;
export const Tablerow = styled(TableRow)`
  border: ${({ border }) => border && border};
  text-align: ${({ textAlign }) => textAlign && textAlign};
  background-color: ${(p) => p.background && p.background};
  border-bottom: ${(p) => p.borderBottom && p.borderBottom};

  display: ${({ display }) => (display ? display : "table-row")};
  & > th,
  & > td {
    color: ${({ color }) => color && color};
    font-weight: ${({ fontWeight }) => fontWeight && fontWeight};
    font-family: ${({ fontFamily }) => fontFamily && fontFamily};
    line-height: ${(p) => (p.lineHeight ? p.lineHeight : p.lineHeight)};
    font-size: ${({ fontSize }) => fontSize && fontSize};
    padding: ${({ padding }) => padding && padding};
    padding-top: ${(p) => p.paddingTop && p.paddingTop};
    padding-bottom: ${(p) => p.paddingBottom && p.paddingBottom};
    border-bottom: ${(p) => p.borderBottom && p.borderBottom};
    background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  }
  & > td.brdr_none_styled {
    border-bottom: none;
  }
  overflow-x: ${(p) => p.overflowX && p.overflowX};
  &.table_last_child tr:last-child {
    border-bottom: "none !important";
  }
  &:last-child {
    border-bottom: ${(p) => p.lastchild && "none"};
  }
`;

export const Tablerow1 = styled(TableRow)`
  border: ${({ border }) => border && border};
  display: ${({ display }) => (display ? display : "table-row")};
  & > th {
    color: ${({ color }) => color && color};
    font-weight: ${({ fontWeight }) => fontWeight && fontWeight};
    font-family: ${({ fontFamily }) => fontFamily && fontFamily};

    padding: ${({ padding }) => padding && padding};
    border-bottom: ${(p) => p.borderBottom && p.borderBottom};
  }
  & > td:first-child {
    padding: "15px 0 15px 20px";
    color: "#2e2e2e !important";
    font-weight: "800" !important;
  }
`;
